.dx-scheduler-header .dx-toolbar {
  background-color: #fff;
}

.dx-buttongroup-wrapper .dx-button-has-icon {
  border: none;
  outline: none;
}

.dx-buttongroup-item.dx-scheduler-navigator-next.dx-state-focused {
  background-color: var(--color-grayscale-50);
}

.dx-buttongroup-item.dx-scheduler-navigator-previous.dx-state-focused {
  background-color: var(--color-grayscale-50);
}

.dx-scheduler-header .dx-toolbar .dx-scheduler-navigator .dx-scheduler-navigator-caption {
  background-color: rgba(216, 242, 241, 0.5);
  border: 1px solid rgba(216, 242, 241, 0.5);
  border-radius: 5px;
  color: #3dbfB8;
  font-weight: var(--font-weight-bold);

  @media (max-width: 549px) {
    background-color: #fff;
    border: none;
  }
}

.dx-button-mode-contained .dx-icon {
  color: #3dbfB8;
}

.dx-button-has-icon .dx-icon {
  font-size: 16px;
}

.dx-button-mode-contained {
  border: none;
  color: #3dbfB8;
  font-weight: var(--font-weight-bold);
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected.dx-state-hover {
  background-color: #c9eee7;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected {
  background-color: #d8f2f1;
}

.dx-scheduler-appointment-collector.dx-button.dx-button-default {
  color: #fff !important;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected {
  background-color: rgba(216, 242, 241, 0.5) !important;
}

.dx-button-has-text .dx-button-content {
  align-items: center;
  display: flex;
  justify-content: center;
}
