@use '~assets/sass/settings/variables/layout' as layout;

.mat-form-field {
  --mat-required-indicator: var(--color-system-error);
  --mat-form-field-height: 56px;
  --mat-field-border-color-outline-gap: inherit;
  --mat-textarea-bottom-space: 7px;
  --mat-textarea-min-height: 18px;
  --mat-form-field-label-min-width: 75px;
  --mat-form-field-pagination-accent-color: var(--color-grayscale-500);
  --material-icons-color: var(--color-grayscale-400);
}

.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    align-items: center;
    display: flex;
    height: var(--mat-form-field-height);
    padding: 0;

    @media #{layout.$mq-below-narrow} {
      width: auto;
    }
  }
}

.mat-form-field-appearance-outline .mat-form-field-infix:has(.cdk-textarea-autosize) {
  padding: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix:has(textarea) {
  height: auto !important;
  min-height: var(--mat-form-field-height);
  padding: .4375em 0;
}

.mat-form-field-appearance-outline .mat-form-field-infix textarea {
  height: var(--mat-textarea-min-height);
  position: relative;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}

textarea.mat-input-element {
  margin: 0 !important;
  min-height: 26px !important;
}

.mat-input-element::placeholder,
.mat-input-element::-webkit-input-placeholder {
  color: var(--color-text-weak)
}

.mat-form-field-label-wrapper {
  min-width: var(--mat-form-field-label-min-width);
}

.mat-form-field-label {
  color: var(--color-text-normal);

  & > * {
    pointer-events: auto;
  }

  & > mat-label {
    background-color: var(--color-background-normal);
    column-gap: 0.25em;
    margin-left: -3px;
    padding-left: 5px;
    padding-right: 3px;

    &:empty {
      background-color: transparent;
    }

    @media (max-width: 360px) {
      font-size: 12px;
    }
  }
}

.c-bui-chips-content__has-mat-form-field--custom-label .mat-form-field-label {
  top: 37px;
  left: 10px;
}

.mat-form-field-required-marker {
  background-color: var(--color-background-normal);
  padding-right: 3px;
}

.mat-form-field:has(.ng-invalid):not(.mat-focused) {
  .mat-form-field-required-marker {
    color: var(--color-system-error);
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border-top-color: var(--mat-field-border-color-outline-gap) !important;
  border-width: 1px !important;
}

.material-light-theme .mat-select-arrow {
  color: var(--color-icon-weak) !important;
}

.material-light-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: #fff;
}

.mat-form-field .mat-input-element:not(:disabled) {
  caret-color: var(--color-text-normal) !important;
}

.mat-form-field:not(.mat-focused) {
  .mat-form-field-suffix {
    .mat-icon,
    .mat-icon-button,
    .mat-icon-button .mat-icon {
      color: var(--color-icon-weak);
    }
  }
}

.mat-form-field.mat-focused {
  .mat-form-field-suffix {
    .mat-icon,
    .mat-icon-button,
    .mat-icon-button .mat-icon {
      color: var(--color-icon-weak);
    }
  }
}

.mat-form-field-suffix .mat-icon-button {

  .mat-icon,
  .mat-datepicker-toggle-default-icon {
    height: 20px !important;
    width: 20px !important;
  }
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-datepicker-toggle .mat-button-disabled {
  opacity: 0.38;
  pointer-events: none;
}

.c-formatting-dialog {
  .mat-icon {
    font-size: 18px !important;
  }
}

// Move all suffix items to the right.
.mat-form-field-suffix {
  translate: 10px !important;
}

.mat-form-field-suffix:has(:only-child:is(.c-bui-single-picker-content__arrow)) {
  translate: none !important;
}

.c-summaries-dialog__items-wrapper .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.c-system-parameters .mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-form-field:not(.mat-form-field-disabled):has(.ng-invalid) {
  .mat-form-field-flex {
    &:has(.mat-form-field-infix>input[type=text]:required) {
      &::before {
        background-color: var(--mat-required-indicator);
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        bottom: 1px;
        content: '';
        height: 48px;
        left: 0.7px;
        opacity: .3;
        position: absolute;
        top: 6px;
        width: 2px;
      }
    }
  }
}

.time-container .mat-form-field:not(.mat-form-field-disabled) {
  .mat-form-field-flex {
    &:has(.mat-form-field-infix>input[type=text]:required) {
      &::before {
        background-color: unset;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        bottom: 0;
        content: '';
        left: 0;
        opacity: .4;
        position: absolute;
        top: 0;
        width: 0;
      }
    }
  }
}

.mat-form-field:has(.ng-invalid) {
  .mat-form-field-flex {
    &:has(.mat-form-field-infix>input[type=password]:required) {
      &::before {
        background-color: var(--mat-required-indicator);
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        bottom: 1px;
        content: '';
        height: 48px;
        left: 0.7px;
        opacity: .3;
        position: absolute;
        top: 6px;
        width: 2px;
      }
    }
  }
}

.mat-form-field:has(.ng-invalid) {
  .mat-form-field-flex {
    &:has(.mat-form-field-infix .c-bui-chips-content input[type=text]:required) {
      &::before {
        background-color: var(--mat-required-indicator);
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        bottom: 23px;
        content: '';
        height: 43px;
        left: 0.7px;
        opacity: .3;
        position: absolute;
        top: 10px;
        width: 2px;
      }
    }
  }
}

.mat-form-field:has(.ng-invalid) {
  .mat-form-field-flex {
    &:has(.mat-form-field-infix .mat-select-required:not(.mat-select-disabled)) {
      &::before {
        background-color: var(--mat-required-indicator);
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        bottom: 1px;
        content: '';
        height: 48px;
        left: 0.7px;
        opacity: .3;
        position: absolute;
        top: 6px;
        width: 2px;
      }
    }
  }
}

.c-bui-phone-field.mat-field-required:has(.ng-invalid) {
  .mat-form-field-flex {
    &::before {
      background-color: var(--mat-required-indicator);
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      bottom: 1px;
      content: '';
      height: 48px;
      left: 0.7px;
      opacity: .3;
      position: absolute;
      top: 6px;
      width: 2px;
    }
  }
}

.mat-field-required:has(.ng-invalid) {
  .mat-form-field-flex {
    &::before {
      background-color: var(--mat-required-indicator);
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      bottom: 1px;
      content: '';
      height: 48px;
      left: 0.7px;
      opacity: .3;
      position: absolute;
      top: 6px;
      width: 2px;
    }
  }
}

.mat-form-field:has(.ng-invalid) {
  .mat-form-field-flex {
    &:has(.mat-form-field-infix>textarea:required) {
      &::before {
        background-color: var(--mat-required-indicator);
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        bottom: 1px;
        content: '';
        height: 48px;
        left: 0.7px;
        opacity: .3;
        position: absolute;
        top: 6px;
        width: 2px;
      }
    }
  }
}


/*  REQUIRED SUBSCRIPT  */


.mat-form-field-subscript-wrapper {
  font-size: 60%;
  margin-top: 1px;
  top: calc(100% - 1.7916666667em);
}


.mat-datepicker-popup {
  .time-container {
    .mat-form-field {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          &::before {
            content: none;
          }
        }
      }
    }
  }
}

.mat-datepicker-popup:has(.time-container) {

  .actions {
    .mat-button-base {
      position: relative;

      .mat-icon {
        opacity: 0;
      }

      &::after {
        content: "OK";
        position: absolute;
        top: 0;
        color: var(--color-link-normal);
        font-weight: var(--font-weight-normal);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

/*  Split view pagination page select active colors override  */

.mat-paginator {
  .mat-primary {
    .mat-selected:not(.mat-option-disabled) {
      color: unset !important;
    }
  }
}

.mat-paginator {
  .mat-focused {
    .mat-select-arrow {
      color: var(--mat-form-field-pagination-accent-color) !important;
    }

    .mat-form-field-ripple {
      background-color: var(--mat-form-field-pagination-accent-color) !important;
    }
  }
}

.mat-tooltip {
  margin-top: 0 !important;
}


/*  Login checkbox  */

.mat-checkbox-frame {
  border-width: 1px !important;
}

.c-login-content__form-footer {

  .mat-checkbox-label {
    font-size: 1rem !important;
  }
}

/*  License Textarea  */


.c-license-key {
  --c-license-key-textarea-top-space: var(--space-xs);

  .mat-form-field-wrapper {
    margin-bottom: 0;
    padding-bottom: 0;

    .mat-input-element {
      margin-bottom: 0;
      padding-block-start: var(--c-license-key-textarea-top-space);
    }
  }
}

/*  Approval  */

.c-approval__filters {

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-radio-button + .mat-radio-button {
    --mat-button-left-margin: 0;
    margin-left: var(--mat-button-left-margin);
  }

  .c-input-number__input {
    &::placeholder {

    }
  }

}


/*  Save  Object  */

.save-object__db-name {
  .mat-form-field-prefix + .mat-form-field-infix {
    .mat-form-field-label-wrapper {
      left: -25px !important;
    }
  }
}

/*  Default Object Detail  */

.c-default-object-detail {
  .c-bui-single-picker-content__arrow {
    translate: 3px -2px !important;

    @media (max-width: 360px) {
      translate: 3px 0 !important;
    }
  }
}

.material-light-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: var(--color-cyan-500);
}

.material-light-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-accent .mat-form-field-outline-thick {
  color: rgba(var(--color-system-error-500-rgb), 1);
}

.mat-form-field.mat-focused:not(.mat-form-field-invalid) .mat-form-field-suffix .mat-icon-button {
  color: var(--color-cyan-500);
}

.material-light-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: var(--color-cyan-500);
}

.material-light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent {
  color: rgba(var(--color-system-error-500-rgb), 1);
}

.material-light-theme .mat-focused .mat-form-field-required-marker {
  color: var(--color-cyan-500);
}

.material-light-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: var(--color-icon-weak);
}

.material-light-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--color-cyan-500);
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end {
  border-width: 1px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start {
  border-width: 1px !important;
}

.mat-form-field.mat-focused:not(.mat-form-field-invalid) .mat-form-field-label {
  color: var(--color-cyan-500) !important;
}

.material-light-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: var(--color-cyan-500);
}

.material-light-theme .mat-datepicker-content .mat-button-focus-overlay {
  background-color: transparent;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: var(--color-cyan-500) !important;
}

@media (max-width: 460px) {

  .mat-form-field-appearance-outline .mat-form-field-infix .mat-input-element {
    font-size: 13px;
  }

  .material-light-theme .mat-select-disabled .mat-select-value {
    font-size: 13px;
  }

  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    height: auto;
  }

  .mat-select-trigger {
    justify-content: space-between;
  }

  .mat-select-value {
    max-width: 150px;
  }
}

.c-layout-content-main__body .mat-tab-labels {
  @media (max-width: 380px) {
    gap: 10px;
  }
}

.c-bui-chips-content__has-mat-form-field--custom-label .mat-form-field-infix {
  border: 0;
  padding-bottom: 0;
  width: auto;
}
