@use "settings/variables/layout";

.mat-select-panel-override {
  @media (layout.$mq-above-narrow) {
    min-width: calc(100% + 100px) !important;
  }
}

.mat-select-panel {
  max-height: 240px !important;
}
