@use "settings/variables/typography" as *;

// Color.
.u-text-color-inherit {
  color: inherit;
}

.u-text-color-inverted {
  --u-text-color-inverted-color: var(--color-text-inverted-normal);
  color: var(--u-text-color-inverted-color);
}

.u-text-color-weak {
  --u-text-color-weak-color: var(--color-text-weak-normal);
  color: var(--u-text-color-weak-color);
}

.u-text-color-primary {
  --u-text-color-primary-color: var(--color-primary);
  color: var(--u-text-color-primary-color);
}

.u-text-color-error {
  --u-text-color-error-color: var(--color-system-error);
  color: var(--u-text-color-error-color) !important;
}

[disabled].u-text-color-error {
  --u-text-color-error-color: rgba(var(--color-system-error-500-rgb), 0.38);
}

.u-text-link {
  color: var(--color-link-normal);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.u-text-color-success {
  --u-text-color-success-color: var(--color-system-success);
  color: var(--u-text-color-success-color);
}

[disabled].u-text-color-success {
  --u-text-color-success-color: rgba(var(--color-system-success-500-rgb), 0.38);
}

// Font.
.u-text-bold {
  font-weight: var(--font-weight-bold);
}

.u-text-select-none {
  user-select: none;
}

.u-text-monospace {
  --u-text-monospace-font-family: var(--font-family-monospace);
  font-family: var(--u-text-monospace-font-family);
}

// Layout.
.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

// Text size.
@each $size, $value in $font-sizes {
  .u-text-size-#{$size} {
    --u-text-size-font-size: var(--font-size-#{$size});
    font-size: var(--u-text-size-font-size);
  }
}

// Text decoration.
.u-text-decoration-none {
  text-decoration: none;
}

.u-text-selection-none {
  &,
  &::before,
  &::after {
    user-select: none;
  }
}

// Keep pre line breaks.
.u-text-white-space-pre-line {
  white-space: pre-line;
}


// Text ellipsis
.u-text-ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
