.cdk-overlay-pane:not(:has(.mat-menu-panel)) {
  padding: 1rem;
}

.cdk-overlay-pane {
  .mat-menu-panel {
    min-height: unset !important;
  }
}

.cdk-overlay-connected-position-bounding-box:has(.c-master-data-records-filters__overlay-panel) {
  left: var(--space-m) !important;
  right: var(--space-m) !important;
  width: auto !important;
}

.cdk-overlay-connected-position-bounding-box:has(.c-report-detail__overlay-panel) {
  left: var(--space-m) !important;
  right: var(--space-m) !important;
  width: auto !important;
}

.c-master-data-records-filters__overlay-backdrop + .cdk-overlay-connected-position-bounding-box {
  left: var(--space-m) !important;
  right: var(--space-m) !important;
  width: auto !important;
}

.cdk-overlay-pane:has(.c-bui-global-search-dropdown) {
  background-color: var(--color-white);
  padding: 10px 0;
}

.cdk-overlay-pane:has(.dropdownList-wrapper) {
  padding: 0;
}
