.dx-datebox-wrapper-calendar.dx-datebox-wrapper-calendar {
  .dx-overlay-content {
    border-radius: 10px;
  }

  .dx-popup-content {
    padding: 0 !important;
  }

  .dx-calendar-cell.dx-calendar-contoured-date:not(.dx-calendar-empty-cell) {
    box-shadow: inset 0 0 0 1px var(--color-accent);
  }

  .dx-calendar-cell.dx-calendar-today:not(.dx-calendar-selected-date) {
    color: var(--color-accent);
  }

  .dx-calendar-cell.dx-calendar-today {
    font-weight: var(--font-weight-bold) !important;
  }

  .dx-calendar-navigator-previous-month.dx-button,
  .dx-calendar-navigator-previous-view.dx-button,
  .dx-calendar-navigator-next-month.dx-button,
  .dx-calendar-navigator-next-view.dx-button {
    border-radius: 0;
  }

  .dx-calendar-navigator-previous-month.dx-button,
  .dx-calendar-navigator-previous-view.dx-button,
  .dx-widget.dx-button.dx-calendar-caption-button {
    border-right: none;
  }
  .dx-calendar-navigator-next-month.dx-button,
  .dx-calendar-navigator-next-view.dx-button,
  .dx-widget.dx-button.dx-calendar-caption-button {
    border-left: none;
  }

  .dx-texteditor.dx-state-focused.dx-editor-outlined,
  .dx-texteditor.dx-state-focused.dx-editor-underlined {
    border-color: var(--color-accent) !important;
  }

  .dx-timeview-hourarrow {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%2248%22%20viewBox%3D%220%200%206%2048%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%226%22%20height%3D%2244%22%20rx%3D%223%22%20fill%3D%22%233dbfb8%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  }
  .dx-timeview-minutearrow {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2210%22%20height%3D%2266%22%20viewBox%3D%220%200%2010%2066%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%223%22%20width%3D%224%22%20height%3D%2264%22%20rx%3D%222%22%20fill%3D%22%233dbfb8%22%2F%3E%0A%3Ccircle%20cx%3D%225%22%20cy%3D%2261%22%20r%3D%223.5%22%20fill%3D%22%23fff%22%20stroke%3D%22%233dbfb8%22%20stroke-width%3D%223%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  }

  .dx-timeview-format12.dx-selectbox {
    height: 36px;
  }

  .dx-toolbar.dx-popup-bottom .dx-toolbar-items-container {
    .dx-toolbar-item:not(:last-child) {
      padding-right: 20px;
    }

    .dx-button-mode-contained {
      background-color: transparent;
      border-color: transparent;
      color: var(--color-text-weak);
    }

    .dx-button {
      border-radius: initial;
      border-width: 0;
      border-style: none;
      min-width: initial !important;
    }

    .dx-popup-done {
      color: var(--color-link-normal);
    }

    .dx-button-has-text .dx-button-content {
      padding: 0;
    }
  }
}
