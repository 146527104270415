.c-template-container:has(.c-simple-master-data__wrapper, .c-report-detail-content__wrapper) {
  height: 100%;
}

.c-template-container:has(.c-simple-master-data__wrapper):not(.c-report-detail-content__wrapper) {
  padding-bottom: 0 !important;
}

// TODO
// This code should be moved to the SimpleMasterData component.
.c-template-container .c-simple-master-data__wrapper {
  //height: 100%;
}

// TODO
// Try to turn off the padding-bottom by using property on the TemplateContainer in the ReportDetailContent.
.c-report-detail-content .c-template-container {
  padding-bottom: 0 !important;
}

// TODO
// This code should be moved to the BeyondTable component or in the datagrid.scss file in the overrides folder.
.c-template-container .c-simple-master-data__wrapper .c-beyond-table .dx-datagrid-borders > .dx-datagrid-headers {
  border-radius: 5px !important;
}

app-template-container:only-child {
  .c-template-container{
    &:has(beyond-table) {
      height: 100%;
    }
  }
}

#masovno-povezivanje-faktura-iavansnih-racuna {
  height: 100%;
  .c-template-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    height: 100%;

    app-layout-content-data-wrapper {
      height: 100% !important;
      overflow-x: auto;
    }

    app-items-table {
      overflow-x: auto !important;

      .c-layout-content-data-wrapper__body {
        border: 1px solid var(--color-border-weak);
      }

      &:nth-of-type(1) {
        margin-bottom: 0;
      }

      &:nth-of-type(2) {
        margin-top: 0;
      }
    }
  }
}

