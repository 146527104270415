@font-face {
  font-family: "Material Icons";
  src: url("~assets/font/materialicons/materialicons-regular.eot");
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("~assets/font/materialicons/materialicons-regular.eot?#iefix") format("embedded-opentype"), url("~assets/font/materialicons/materialicons-regular.woff2") format("woff2"), url("~assets/font/materialicons/materialicons-regular.woff") format("woff"), url("~assets/font/materialicons/materialicons-regular.ttf") format("truetype"), url("~assets/font/materialicons/materialicons-regular.svg#MaterialIcons") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../font/poppins/poppins-400.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../font/poppins/poppins-400italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../font/poppins/poppins-700.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../font/poppins/poppins-700italic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../font/poppins/poppins-500.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../font/poppins/poppins-500italic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../font/poppins/poppins-300.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../font/roboto/roboto-400.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../font/roboto/roboto-400italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../font/roboto/roboto-500.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../font/roboto/roboto-500italic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../font/roboto/roboto-700.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../font/roboto/roboto-700italic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../font/roboto/roboto-300.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}