html {
  --base-typography-link-color: var(--color-link-normal);
  color: var(--color-text-normal, #000);
}

body {
  color: var(--color-text-normal, #000);
}

input:not(:disabled) {
  caret-color: var(--color-text-normal) !important;
  color: var(--color-text-normal) !important;
}

a {
  color: var(--base-typography-link-color);
}

::selection {
  background-color: var(--color-background-normal-inverted) !important;
  color: var(--color-text-normal-inverted) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: currentColor;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent;
}

[contenteditable]:focus-visible {
  border-radius: 3px;
  box-shadow: 0 0 0 1px var(--color-border-normal) inset;
  display: inherit;
  outline: none;
}
