.pivot-grid__field-chooser-filters-link-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  order: 1;
}

.pivot-grid__field-chooser-clear-filters-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  width: fit-content;
}

.pivot-grid__field-chooser-clear-filters-link-second {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  width: fit-content;
}

.pivot-grid__new-table-row {
  background-color: lightslategrey;
  display: block;
  height: 0;
  width: 97vw;
}

.pivot-grid-data-wrapper {
  display: block;
  overflow: auto;
  width: 100%;
}

.pivot-grid__button-cells {
  position: absolute;
  bottom: 12px;
}

.pivot-grid__right-button-cell {
  right: -11px;
  padding: 0;
}

.pivot-grid__left-button-cell {
  left: -12px;
  padding: 0;
}
