.dx-datagrid .dx-datagrid-headers {
  border: 1px solid transparent !important;
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table .dx-datagrid-filter-row {
  background-color: var(--color-white);
  height: 28px !important;
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table .dx-datagrid-filter-row > td {
  vertical-align: top;
}

.dx-datagrid-header-panel .dx-toolbar {
  margin-bottom: 2px !important;
}

.dx-datagrid .dx-datagrid-rowsview {
  border: none;
}

.dx-datagrid-filter-row > td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.dx-datagrid-filter-row .dx-menu {
  margin-top: 0 !important;
  margin-left: 0 !important;
}

.dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
  padding: {
    bottom: 5px;
    left: 5px;
    right: 5px;
    top: 4px;
  }
}

.dx-datagrid .dx-column-lines > td {
  border-right: 1px solid var(--color-white);
}

.dx-datebox-datetime .dx-dropdowneditor-icon {
  color: var(--color-text-weak);
}

.dx-datebox-datetime .dx-dropdowneditor-icon::before {
  content: "\f026" !important;
}

.dx-datagrid .dx-datagrid-rowsview .dx-row {
  border-bottom: 1px solid var(--color-border-normal);
  border-right: 1px solid var(--color-white) !important;
  color: var(--color-text-strong);
  font-weight: var(--font-weight-normal);
  height: 31px !important;
}

.dx-datagrid .dx-row dx-data-row dx-column-lines dx-row-focused dx-cell-focus-disabled dx-selection {
  background-color: cyan !important;
}

.dx-datagrid .dx-column-indicators .dx-sort.dx-sort {
  color: var(--color-white);
  font-weight: var(--font-weight-bold);
  font-size: 11px;
}

.dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter {
  color: var(--color-white);
  font-size: 10px;
}

.dx-datagrid .dx-datagrid-rowsview .dx-row > td {
  border-left: 1px solid var(--color-white);
  border-right: 1px solid var(--color-white);
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td {
  background-color: var(--color-background-weak);
  color: var(--color-text-strong);
  padding-block: 0;
}

.dx-datagrid .dx-datagrid-headers .dx-header-row > td {
  border-bottom: 0;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row {
  height: 32px !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td:has(.dx-datagrid-text-content) {
  vertical-align: middle;
}

.dx-datagrid-borders .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table-fixed {
  color: var(--color-text-weak);
  font-weight: var(--font-weight-bold);
}

.dx-datagrid-borders .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table-fixed .dx-row:first-child {
  background-color: var(--color-primary-300);
  color: var(--color-white);
}

.dx-datagrid-borders .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table .dx-row > td:first-child {
  border-right: 2px solid var(--color-white) !important;
}

.dx-datagrid-borders .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table .dx-datagrid-filter-row > td:first-child {
  background-color: var(--color-white);
}

.dx-datagrid-borders .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table .dx-datagrid-filter-row .dx-command-expand {
  background-color: var(--color-white);
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor-container {
  height: 28px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td:first-of-type {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td:last-of-type {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.dx-datagrid-group-panel .dx-group-panel-item {
  background-color: var(--color-background-weak);
  border: 1px solid var(--color-background-weak);
  color: var(--color-primary);
  font-weight: var(--font-weight-bold);

  .dx-header-filter, .dx-sort {
    color: var(--color-text-weaker-medium) !important;
  }
}
.dx-datagrid-group-panel .dx-group-panel-message {
  padding: 7px 7px 7px 0;
}

.dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
  background-color: var(--color-system-success);
  border: 1px solid var(--color-system-success);
}

.dx-checkbox-checked .dx-checkbox-icon::before {
  color: var(--color-white);
}

.dx-datagrid-rowsview .dx-selection.dx-data-row:not(.dx-row-lines) > td {
  background-color: var(--color-system-success-50);
  color: var(--color-text-strong);
  font-weight: var(--font-weight-normal);
}

.dx-datagrid-rowsview .dx-row-focused.dx-group-row .dx-datagrid-summary-item {
  color: var(--color-text-strong);
  font-weight: var(--font-weight-bold);
}

.dx-checkbox-icon {
  border-radius: 3px;
  border: 1px solid var(--color-text-weak);
}

.dx-datagrid-group-opened::before {
  color: var(--color-text-weak);
}

.dx-datagrid-summary-item {
  color: var(--color-text-strong);
  font-weight: var(--font-weight-bold);
}

.dx-datagrid .dx-datagrid-rowsview .dx-row > td {
  padding-block: 0;
  vertical-align: middle;
}

.dx-datagrid-rowsview .dx-row-focused.dx-group-row {
  background-color: var(--color-background-weak);
}

.dx-datagrid-rowsview .dx-row-focused.dx-group-row .dx-datagrid-group-closed {
  color: var(--color-text-weak);
}

.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  border-radius: 0 !important;
}

.dx-datagrid-total-footer > .dx-datagrid-content {
  padding: 0;
}

.dx-datagrid-total-footer > .dx-datagrid-content .dx-datagrid-table .dx-row {
  height: 25px !important;
}

.dx-datagrid-total-footer > .dx-datagrid-content .dx-datagrid-table .dx-row > td {
  padding-block: 0;
  vertical-align: inherit;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td {
  border-bottom: none;
  border-top: none;
}

.dx-datagrid .dx-dropdowneditor-icon {
  color: var(--color-grayscale-300);
}

.dx-datagrid .dx-datagrid-rowsview .dx-row > td.dx-command-select {
  border-radius: 0 !important;
}

.dx-datagrid .dx-datagrid-rowsview .dx-row > td.dx-command-select:not(:empty) {
  --dx-datagrid-table-fixed-cell-background-color: #fafafa;

  background-color: var(--dx-datagrid-table-fixed-cell-background-color) !important;
}

.dx-datagrid-headers {
  .dx-header-row {
    .dx-datagrid-action {
      .dx-datagrid-text-content {
        float: left !important;
      }

      .dx-column-indicators {
        float: right!important;
      }
    }
  }
}

