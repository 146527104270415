@charset "UTF-8";
/** Generated by the DevExpress ThemeBuilder (dart)
* Version: 20.2.3
* http://js.devexpress.com/ThemeBuilder/
*/

.dx-datagrid {
  --dx-datagrid-background-color: var(--color-table-body-row-background);
  --dx-datagrid-table-fixed-cell-background-color: #FAFAFA;
  --dx-datagrid-table-cell-focused-background-color: #E7EDF1;
  --dx-datagrid-table-cell-hover-background-color: var(--color-background-weak);
  --dx-datagrid-border-color: var(--color-border-weak);
  --dx-datagrid-border-transparent: var(--color-transparent);
  --dx-datagrid-font-family: var(--font-family-base);
  --dx-datagrid-font-weight: var(--font-weight-normal);
  --dx-datagrid-link-color: var(--color-link-normal);
  --dx-datagrid-text-color-inverted: var(--color-text-normal-inverted);
  --dx-datagrid-text-color: var(--color-text-normal);

  --dx-datagrid-table-head-background-color: var(--color-primary-300);
  --dx-datagrid-table-head-cell-padding-x: 15px;
  --dx-datagrid-table-head-cell-padding-y: 20px;
  --dx-datagrid-table-head-font-weight: var(--font-weight-bold);
  --dx-datagrid-table-head-text-color: var(--color-white);

  --dx-datagrid-table-body-cell-padding-x: 15px;
  --dx-datagrid-table-body-cell-padding-y: 10px;
  --dx-datagrid-table-body-row-selected: var(--color-table-body-row-background-selected);

  --dx-datagrid-pager-link-background-color: var(--dx-datagrid-background-color);
  --dx-datagrid-pager-link-color: var(--dx-datagrid-text-color);
  --dx-datagrid-pager-text-background-color: var(--dx-datagrid-background-color);
  --dx-datagrid-pager-text-color: var(--dx-datagrid-link-color);
}

// Custom selector for highlighted row.
.dx-datagrid__row--highlighted {
  --dx-datagrid-row-background-color-highlighted: var(--color-accent-50);

  & > td {
    background-color: var(--dx-datagrid-row-background-color-highlighted) !important;
  }
}

.dx-datagrid-important-margin {
  margin-right: 5px!important;
}
.dx-datagrid-table {
  background-color: transparent;
}
.dx-datagrid .dx-datagrid-content-fixed {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
  overflow: hidden;
}
.dx-datagrid .dx-datagrid-content-fixed .dx-datagrid-table {
  position: relative;
}
.dx-datagrid .dx-datagrid-content-fixed .dx-datagrid-table td {
  pointer-events: auto;
}
.dx-datagrid .dx-datagrid-content-fixed .dx-datagrid-table .dx-row td.dx-pointer-events-none {
  visibility: hidden;
  background-color: transparent;
  pointer-events: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.dx-datagrid .dx-datagrid-content-fixed .dx-datagrid-table .dx-header-row td.dx-pointer-events-none {
  border-bottom-color: transparent;
}
.dx-datagrid .dx-datagrid-content-fixed .dx-datagrid-table.dx-datagrid-table-fixed .dx-row td.dx-pointer-events-none {
  width: auto;
}
.dx-datagrid .dx-datagrid-headers .dx-datagrid-content-fixed .dx-datagrid-table.dx-datagrid-table-fixed .dx-row > td.dx-pointer-events-none + td {
  background-color: var(--dx-datagrid-table-head-background-color);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-right-width: 0;
  border-left: 1px solid var(--dx-datagrid-border-color);
}
.dx-datagrid.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-top: 0;
}
.dx-datagrid.dx-datagrid-borders > .dx-datagrid-pager {
  margin-top: 1px;
}
.dx-datagrid.dx-datagrid-borders > .dx-datagrid-header-panel {
  border-bottom: 0;
}
.dx-datagrid.dx-datagrid-borders > .dx-datagrid-rowsview.dx-last-row-border tbody:last-child > .dx-data-row:nth-last-child(2) > td {
  border-bottom-width: 0;
}
.dx-datagrid .dx-menu-subitem ul li {
  padding-top: 0;
}
.dx-datagrid .dx-menu-subitem ul li:first-child {
  padding-top: 1px;
}
.dx-datagrid .dx-menu-subitem .dx-menu-item {
  padding: 7px 30px 7px 5px;
}
.dx-datagrid .dx-menu-subitem .dx-menu-item .dx-menu-image {
  background-position-x: left;
}
.dx-datagrid .dx-link {
  text-decoration: underline;
  cursor: pointer;
}
.dx-datagrid .dx-column-indicators {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}
.dx-datagrid .dx-column-indicators.dx-visibility-hidden {
  visibility: hidden;
}
.dx-datagrid .dx-column-indicators .dx-sort-index-icon {
  padding-right: 3px;
  margin-left: -3px;
  width: 15px;
}
.dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter,
.dx-datagrid .dx-column-indicators .dx-header-filter.dx-sort,
.dx-datagrid .dx-column-indicators .dx-sort.dx-header-filter,
.dx-datagrid .dx-column-indicators .dx-sort.dx-sort {
  display: inline-block;
}
.dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter::after,
.dx-datagrid .dx-column-indicators .dx-sort.dx-header-filter::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: -7px;
}

.dx-datagrid .dx-row > td,
.dx-datagrid .dx-row > tr > td {
  padding: var(--dx-datagrid-table-body-cell-padding-y) 0;
}
.dx-datagrid .dx-error-row {
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}
.dx-datagrid .dx-error-row .dx-error-message {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.dx-datagrid .dx-error-row .dx-error-message a {
  color: inherit;
}
.dx-datagrid .dx-datagrid-rowsview .dx-data-row .dx-command-drag {
  cursor: move;
  vertical-align: middle;
  padding: 0;
}
.dx-sortable-dragging .dx-datagrid-rowsview .dx-data-row .dx-command-drag {
  cursor: pointer;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.dx-sortable-without-handle .dx-data-row {
  cursor: pointer;
}
.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-title {
  border-bottom: none;
  font-size: 16px;
}
.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-title .dx-toolbar-label {
  font-size: 16px;
}
.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-content {
  padding: 0 20px 20px;
}
.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-content .dx-column-chooser-item {
  opacity: .5;
  -webkit-box-shadow: 0 1px 3px -1px rgba(0,0,0,.2);
  box-shadow: 0 1px 3px -1px rgba(0,0,0,.2);
}
.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-content .dx-column-chooser-item.dx-datagrid-drag-action {
  opacity: 1;
  cursor: pointer;
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-drag .dx-treeview-node-container:first-child > .dx-treeview-node-is-leaf {
  padding: 0;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-select .dx-datagrid-column-chooser-plain .dx-treeview-node {
  padding-left: 0;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-select .dx-datagrid-column-chooser-plain .dx-treeview-node .dx-checkbox {
  left: 4px;
}
.dx-datagrid-nowrap,
.dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content {
  white-space: nowrap;
}
.dx-datagrid-drag-header {
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  z-index: 10000;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.dx-datagrid-drag-header.dx-drag-command-cell {
  padding: 0;
}
.dx-datagrid-columns-separator {
  position: absolute;
  z-index: 3;
  width: 3px;
}
.dx-datagrid-columns-separator-transparent {
  border-left: 0;
  border-right: 0;
}
.dx-datagrid-tracker {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  cursor: col-resize;
}
.dx-datagrid-table-content {
  position: absolute;
  top: 0;
}
.dx-datagrid-focus-overlay {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  visibility: hidden;
}
.dx-datagrid-action,
.dx-datagrid-drag-action {
  cursor: pointer;
}
.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-modified):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row) > td:not(.dx-focused) .dx-link {
  color: inherit;
}
.dx-datagrid-content {
  position: relative;
}
.dx-datagrid-content .dx-overlay-wrapper.dx-invalid-message.dx-datagrid-invalid-message {
  visibility: visible;
}
.dx-datagrid-content .dx-overlay-wrapper.dx-invalid-message.dx-datagrid-invalid-message .dx-overlay-content,
.dx-datagrid-text-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dx-datagrid-table-fixed {
  table-layout: fixed;
  width: 100%;
}
input.dx-hidden {
  display: inline-block!important;
  width: 0!important;
}
.dx-hidden-cell {
  visibility: hidden;
}
.dx-row > td,
.dx-row > tr > td {
  border: none;
}
.dx-datagrid-content .dx-datagrid-table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  max-width: 10px;
}
.dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed {
  max-width: none;
}
.dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed .dx-column-indicators .dx-sort.dx-sort-none {
  display: none;
}
.dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed .dx-column-indicators .dx-sort.dx-sort-index {
  width: 26px;
}
.dx-datagrid-content .dx-datagrid-table .dx-datagrid-best-fit .dx-column-indicators {
  float: none!important;
}
.dx-datagrid-content .dx-datagrid-table .dx-datagrid-best-fit .dx-column-indicators > span {
  width: 14px;
}
.dx-datagrid-content .dx-datagrid-table .dx-datagrid-best-fit .dx-column-indicators .dx-sort.dx-sort-none {
  display: inline-block;
}
.dx-datagrid-content .dx-datagrid-table .dx-datagrid-best-fit .dx-header-row .dx-datagrid-text-content {
  max-width: none;
}
.dx-datagrid-content .dx-datagrid-table .dx-datagrid-best-fit .dx-text-content-alignment-left {
  margin-right: 3px;
}
.dx-datagrid-content .dx-datagrid-table .dx-datagrid-best-fit .dx-text-content-alignment-right {
  margin-left: 3px;
}
.dx-datagrid-content .dx-datagrid-table:not(.dx-datagrid-table-fixed) .dx-column-indicators {
  float: none!important;
}
.dx-datagrid-content .dx-datagrid-table:not(.dx-datagrid-table-fixed) .dx-column-indicators > span {
  width: 14px;
}
.dx-datagrid-content .dx-datagrid-table:not(.dx-datagrid-table-fixed) .dx-text-content-alignment-left {
  margin-right: 3px;
}
.dx-datagrid-content .dx-datagrid-table:not(.dx-datagrid-table-fixed) .dx-text-content-alignment-right {
  margin-left: 3px;
}
.dx-datagrid-content .dx-datagrid-table:not(.dx-datagrid-table-fixed) .dx-master-detail-cell {
  max-width: 0;
}
.dx-datagrid-content .dx-datagrid-table [class*=column] + [class*=column]:last-child {
  float: none;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > td:first-child,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td:first-child {
  border-left: 0;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-group-space,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td.dx-datagrid-group-space {
  border-right: none;
  vertical-align: middle;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-group-space + td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td.dx-datagrid-group-space + td {
  border-left: none;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-container {
  overflow: hidden;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-datagrid-invalid .dx-invalid-message.dx-overlay {
  position: static;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell {
  max-width: 0;
  padding: 0;
  vertical-align: middle;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell:not(.dx-command-select) {
  overflow: visible;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor,
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor-container {
  border: 0;
  margin: 0;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
  padding: 0;
  width: 70px;
  min-width: 70px;
  max-width: 70px;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit {
  text-overflow: clip;
  width: 100px;
  min-width: 100px;
  white-space: nowrap;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit.dx-command-edit-with-icons {
  width: 80px;
  min-width: 80px;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit.dx-command-edit-with-icons .dx-link {
  text-decoration: none;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit .dx-link {
  display: inline-block;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-expand {
  padding: 0;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-adaptive {
  padding: 0;
  vertical-align: middle;
}
.dx-datagrid-content .dx-datagrid-table .dx-data-row .dx-command-edit-with-icons {
  line-height: 1;
  white-space: nowrap;
}
.dx-datagrid-content .dx-datagrid-table .dx-filter-range-content {
  padding: 7px 7px 7px 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > .dx-last-data-cell {
  border-right: none;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > .dx-command-adaptive-hidden {
  border-left: none;
}
.dx-cell-modified,
.dx-datagrid-invalid,
.dx-highlight-outline {
  position: relative;
  padding: 7px;
}
.dx-cell-modified::after,
.dx-datagrid-invalid::after,
.dx-highlight-outline::after {
  content: "";
  position: absolute;
  border: 2px solid var(--dx-datagrid-border-transparent);
  top: 0;
  left: 1px;
  bottom: 0;
  right: 0;
  pointer-events: none;
}
.dx-cell-modified.dx-hidden,
.dx-datagrid-invalid.dx-hidden,
.dx-highlight-outline.dx-hidden {
  display: block!important;
}
.dx-cell-modified.dx-hidden::after,
.dx-datagrid-invalid.dx-hidden::after,
.dx-highlight-outline.dx-hidden::after {
  display: none;
}
.dx-editor-cell .dx-texteditor-input {
  margin: 0;
}
.dx-editor-cell .dx-highlight-outline,
.dx-editor-cell.dx-cell-modified,
.dx-editor-cell.dx-datagrid-invalid {
  padding: 0;
}
.dx-editor-cell.dx-editor-inline-block:not(.dx-command-select)::before {
  display: inline-block;
  content: "​";
  vertical-align: middle;
}
.dx-column-lines .dx-cell-modified::after,
.dx-column-lines .dx-datagrid-invalid::after,
.dx-column-lines .dx-highlight-outline::after {
  left: 0;
}
.dx-datagrid-headers {
  position: relative;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.dx-header-row .dx-editor-cell .dx-select-checkbox {
  display: inline-block;
}
.dx-header-row > td {
  white-space: nowrap;
  overflow: hidden;
}
.dx-header-row > td > .dx-datagrid-text-content {
  white-space: normal;
  vertical-align: top;
}
.dx-header-row .dx-text-content-alignment-left,
.dx-header-row .dx-text-content-alignment-right {
  display: inline-block;
  max-width: 100%;
}
.dx-header-row .dx-header-filter-indicator,
.dx-header-row .dx-sort-indicator {
  max-width: calc(100% - 17px);
}
.dx-header-row .dx-header-filter-indicator.dx-text-content-alignment-left,
.dx-header-row .dx-sort-indicator.dx-text-content-alignment-left {
  margin-right: 3px;
}
.dx-header-row .dx-header-filter-indicator.dx-text-content-alignment-right,
.dx-header-row .dx-sort-indicator.dx-text-content-alignment-right {
  margin-left: 3px;
}
.dx-header-row .dx-header-filter-indicator.dx-text-content-alignment-left.dx-text-content-alignment-right,
.dx-header-row .dx-sort-indicator.dx-text-content-alignment-left.dx-text-content-alignment-right {
  max-width: calc(100% - 34px);
}
.dx-header-row .dx-sort-indicator.dx-header-filter-indicator {
  max-width: calc(100% - 31px);
}
.dx-header-row .dx-sort-indicator.dx-header-filter-indicator.dx-text-content-alignment-left.dx-text-content-alignment-right {
  max-width: calc(100% - 62px);
}
.dx-header-row .dx-sort-indicator.dx-sort-index-indicator {
  max-width: calc(100% - 29px);
}
.dx-header-row .dx-sort-indicator.dx-sort-index-indicator.dx-header-filter-indicator {
  max-width: calc(100% - 43px);
}
.dx-header-row .dx-sort-indicator.dx-sort-index-indicator.dx-text-content-alignment-left.dx-text-content-alignment-right {
  max-width: calc(100% - 46px);
}
.dx-header-row .dx-sort-indicator.dx-sort-index-indicator.dx-header-filter-indicator.dx-text-content-alignment-left.dx-text-content-alignment-right {
  max-width: calc(100% - 74px);
}
.dx-datagrid-filter-range-overlay .dx-editor-container .dx-texteditor {
  border-width: 0;
}
.dx-datagrid-filter-range-overlay .dx-editor-container .dx-texteditor.dx-state-focused::after {
  content: " ";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  z-index: 1;
  pointer-events: none;
}
.dx-datagrid-filter-range-overlay .dx-datagrid-filter-range-end {
  border-top: 1px solid var(--dx-datagrid-border-transparent);
}
.dx-datagrid-filter-range-overlay .dx-editor-container.dx-cell-modified,
.dx-datagrid-filter-range-overlay .dx-editor-container.dx-datagrid-invalid {
  padding: 0;
}
.dx-datagrid-filter-row .dx-editor-cell .dx-menu {
  display: none;
}
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu {
  position: relative;
}
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-menu {
  display: block;
}
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder::before,
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input {
  padding-left: 32px;
}
.dx-datagrid-filter-row .dx-cell-modified::after,
.dx-datagrid-filter-row .dx-datagrid-invalid::after {
  pointer-events: none;
}
.dx-datagrid-filter-row .dx-focused .dx-highlight-outline::after,
.dx-datagrid-filter-row .dx-focused.dx-cell-modified::after,
.dx-datagrid-filter-row .dx-focused.dx-datagrid-invalid::after {
  border-color: var(--dx-datagrid-border-transparent);
}
.dx-datagrid-filter-row .dx-menu {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin-left: -2px;
  margin-top: -2px;
  height: 100%;
  overflow: visible;
}
.dx-datagrid-filter-row .dx-menu .dx-menu-horizontal {
  height: 100%;
}
.dx-datagrid-filter-row .dx-menu .dx-menu-horizontal .dx-menu-item-popout,
.dx-datagrid-filter-row .dx-menu .dx-menu-horizontal .dx-menu-item-text {
  display: none;
}
.dx-datagrid-filter-row .dx-menu-item.dx-state-focused::after {
  position: absolute;
  left: 2px;
  top: 2px;
  width: 100%;
  height: 102%;
  content: "";
}
.dx-datagrid-filter-row > .dx-first-cell .dx-menu,
.dx-datagrid-filter-row > td:first-child .dx-menu {
  margin-left: 0;
}
.dx-datagrid-filter-row .dx-menu-horizontal .dx-overlay-content ul .dx-menu-item {
  padding: 5px 30px 5px 5px;
}
.dx-datagrid-filter-row .dx-menu ul.dx-menu-horizontal > li > .dx-menu-item {
  padding: 8px 5px 7px;
}
.dx-datagrid-filter-row .dx-menu ul.dx-menu-horizontal > li > .dx-menu-item.dx-state-disabled:hover {
  padding: 9px 6px 8px;
}
.dx-datagrid-filter-row .dx-menu-caption {
  padding-left: 6px;
}
.dx-datagrid-filter-row .dx-menu ul .dx-menu-item .dx-menu-chouser-down {
  display: none;
}
.dx-datagrid-filter-row .dx-menu-item-highlight {
  font-weight: 400;
}
.dx-datagrid-scroll-container {
  overflow: hidden;
  width: 100%;
}
.dx-datagrid-header-panel {
  text-align: left;
}
.dx-datagrid-header-panel .dx-toolbar-menu-container .dx-button {
  margin-left: 10px;
}
.dx-state-disabled {
  cursor: pointer;
}
.dx-state-disabled .dx-menu-item {
  cursor: default;
}
.dx-datagrid-search-panel {
  margin: 0 0 0 15px;
}
.dx-gridbase-container > .dx-datagrid-rowsview {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  height: auto;
}
.dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable > .dx-scrollable-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: auto;
  min-width: 0;
  min-height: 0;
}
.dx-datagrid-rowsview {
  position: relative;
  overflow: hidden;
}
.dx-datagrid-rowsview.dx-empty {
  height: 100px;
}
.dx-datagrid-rowsview.dx-empty .dx-scrollable-content {
  height: 100%;
}
.dx-datagrid-rowsview.dx-fixed-columns.dx-scrollable .dx-scrollable-content {
  z-index: 2;
}
.dx-datagrid-rowsview.dx-fixed-columns .dx-scrollable-scrollbar {
  z-index: 3;
}
.dx-datagrid-rowsview .dx-datagrid-content {
  overflow-anchor: none;
}
.dx-datagrid-rowsview .dx-datagrid-content:not(.dx-datagrid-content-fixed) {
  min-height: 100%;
}
.dx-datagrid-rowsview:focus {
  outline: 0;
}
.dx-datagrid-rowsview .dx-row > td,
.dx-datagrid-rowsview .dx-row > tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dx-datagrid-rowsview .dx-row.dx-row-lines:first-child,
.dx-datagrid-rowsview .dx-row.dx-row-lines:first-child>td,
.dx-datagrid-rowsview .dx-row.dx-row-lines:first-child>tr>td {
  border-top: none;
}
.dx-datagrid-rowsview .dx-data-row > td:focus {
  outline: 0;
}
.dx-datagrid-rowsview .dx-selection > td .dx-link,
.dx-datagrid-rowsview .dx-selection > tr > td .dx-link,
.dx-datagrid-rowsview .dx-selection.dx-row:hover > td .dx-link,
.dx-datagrid-rowsview .dx-selection.dx-row:hover > tr > td .dx-link {
  color: inherit;
}
.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-freespace-row,
.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-virtual-row {
  border-top: 0;
  border-bottom: 0;
}
.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-freespace-row > td,
.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-virtual-row > td {
  padding-top: 0;
  padding-bottom: 0;
}
.dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td > .dx-select-checkbox {
  display: none;
}
.dx-datagrid-rowsview .dx-select-checkboxes-hidden>tbody>tr.dx-selection>td>.dx-select-checkbox,
.dx-datagrid-rowsview .dx-select-checkboxes-hidden>tbody>tr>td:hover>.dx-select-checkbox {
  display: inline-block;
}
.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
  padding: 30px;
}
.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell:focus {
  outline: 0;
}
.dx-datagrid-rowsview .dx-data-row.dx-edit-row .dx-cell-modified::after {
  border-color: var(--dx-datagrid-border-transparent);
}
.dx-datagrid-nodata {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
.dx-datagrid-bottom-load-panel {
  text-align: center;
  padding: 10px;
}
.dx-datagrid-hidden-column {
  white-space: nowrap;
}
.dx-datagrid .dx-row .dx-datagrid-hidden-column {
  border-right-width: 0;
  border-left-width: 0;
}
.dx-datagrid-hidden-column > * {
  display: none!important;
}
.dx-datagrid-total-footer > .dx-datagrid-content {
  padding-top: 0px;
  padding-bottom: 0px;
}
.dx-datagrid-summary-item {
  font-weight: 700;
}
.dx-datagrid-export-menu .dx-menu-item .dx-checkbox {
  margin-left: 0;
}
.dx-datagrid-export-menu .dx-menu-item .dx-checkbox .dx-checkbox-icon {
  width: 16px;
  height: 16px;
}
.dx-datagrid-export-menu .dx-menu-item .dx-checkbox .dx-checkbox-text {
  white-space: nowrap;
  word-break: normal;
}
.dx-command-adaptive {
  width: 36px;
  min-width: 36px;
}
.dx-datagrid-revert-tooltip.dx-popover-wrapper .dx-overlay-content {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dx-datagrid-revert-tooltip.dx-popover-wrapper .dx-overlay-content .dx-popup-content {
  padding: 0;
}
.dx-datagrid-revert-tooltip.dx-popover-wrapper .dx-popover-arrow {
  width: 0;
  height: 0;
}
.dx-datagrid-notouch-action {
  -ms-touch-action: none;
  touch-action: none;
  -ms-content-zooming: none;
  -ms-overflow-style: none;
}
.dx-datagrid-column-chooser-list.dx-treeview .dx-empty-message,
.dx-datagrid-column-chooser-list.dx-treeview .dx-treeview-item,
.dx-device-mobile .dx-datagrid-column-chooser-list.dx-treeview .dx-empty-message,
.dx-device-mobile .dx-datagrid-column-chooser-list.dx-treeview .dx-treeview-item {
  border: none;
}
.dx-datagrid-column-chooser-list.dx-treeview .dx-empty-message,
.dx-device-mobile .dx-datagrid-column-chooser-list.dx-treeview .dx-empty-message {
  text-align: center;
  left: 0;
  right: 0;
  bottom: 50%;
  position: absolute;
}
.dx-datagrid.dx-rtl .dx-menu-subitem .dx-menu-item,
.dx-rtl .dx-datagrid .dx-menu-subitem .dx-menu-item {
  padding: 7px 5px 7px 30px;
}
.dx-datagrid.dx-rtl .dx-menu-subitem .dx-menu-item .dx-menu-image,
.dx-rtl .dx-datagrid .dx-menu-subitem .dx-menu-item .dx-menu-image {
  background-position-x: right;
}
.dx-datagrid.dx-rtl .dx-texteditor-buttons-container,
.dx-rtl .dx-datagrid .dx-texteditor-buttons-container {
  text-align: start;
}
.dx-rtl .dx-datagrid-content .dx-datagrid-table {
  direction: rtl;
}
.dx-rtl .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-group-space {
  border-left: none;
}
.dx-rtl .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-group-space + td {
  border-right: none;
}
.dx-rtl .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-container .dx-editor-cell .dx-checkbox.dx-checkbox-checked .dx-checkbox-icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dx-rtl .dx-datagrid-content .dx-datagrid-table .dx-row .dx-filter-range-content {
  padding: 7px 32px 7px 7px;
}
.dx-rtl .dx-datagrid-headers,
.dx-rtl .dx-datagrid-rowsview,
.dx-rtl .dx-datagrid-total-footer {
  direction: ltr;
}
.dx-rtl .dx-datagrid-headers .dx-datagrid-table,
.dx-rtl .dx-datagrid-rowsview .dx-datagrid-table,
.dx-rtl .dx-datagrid-total-footer .dx-datagrid-table {
  direction: rtl;
}
.dx-rtl .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor .dx-placeholder::before,
.dx-rtl .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor .dx-texteditor-input {
  padding-right: 32px;
}
.dx-rtl .dx-datagrid-filter-row .dx-menu {
  right: 0;
  left: auto;
  margin-left: 0;
  margin-right: -2px;
}
.dx-rtl .dx-datagrid-filter-row > td:first-child .dx-menu {
  margin-left: 0;
}
.dx-rtl .dx-datagrid-filter-row .dx-menu-horizontal .dx-overlay-content ul .dx-menu-item {
  padding: 5px 5px 5px 30px;
}
.dx-rtl .dx-datagrid-filter-row .dx-menu-caption {
  padding-right: 6px;
}
.dx-rtl .dx-datagrid-header-panel {
  text-align: right;
}
.dx-rtl .dx-datagrid-header-panel .dx-datagrid-column-chooser-button {
  margin-left: 0;
}
.dx-rtl .dx-datagrid-header-panel .dx-toolbar-menu-container .dx-button {
  margin-left: 0;
  margin-right: 10px;
}
.dx-rtl .dx-datagrid-search-panel {
  margin: 0 15px 0 0;
}
.dx-datagrid-filter-panel,
.dx-datagrid-filter-panel-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  min-width: 0;
}
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter:hover,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text:hover {
  text-decoration: underline;
  cursor: pointer;
}
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-checkbox {
  padding-right: 10px;
}
.dx-datagrid-filter-panel .dx-icon-filter {
  margin: auto 7px auto auto;
  cursor: pointer;
}
.dx-datagrid {
  position: relative;
  cursor: default;
  white-space: normal;
  line-height: normal;
}
.dx-hidden.dx-group-cell {
  display: table-cell!important;
  font-size: 0!important;
}
.dx-datagrid-group-panel {
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}
.dx-datagrid-group-panel .dx-group-panel-message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dx-datagrid-group-panel .dx-group-panel-item {
  display: inline-block;
  min-width: 30px;
  white-space: nowrap;
}
.dx-datagrid-group-panel .dx-group-panel-item .dx-sort {
  margin-left: 6px;
}
.dx-datagrid-group-panel .dx-block-separator {
  display: inline-block;
  min-width: 30px;
  white-space: nowrap;
  position: relative;
  min-width: 0;
}
.dx-datagrid-group-panel .dx-block-separator .dx-sort {
  margin-left: 6px;
}
.dx-datagrid-rowsview .dx-row.dx-group-row td {
  border-top: 1px solid;
  border-bottom: 1px solid;
}
.dx-datagrid-rowsview .dx-row.dx-group-row:first-child td {
  border-top: none;
}
.dx-datagrid-rowsview .dx-group-row:focus {
  outline: 0;
}
.dx-datagrid-rowsview .dx-group-row.dx-row > td {
  border-left-color: var(--dx-datagrid-border-transparent);
  border-right-color: var(--dx-datagrid-border-transparent);
}
.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-field-item-content:focus {
  outline: 0;
}
.dx-datagrid-group-closed::before,
.dx-datagrid-group-opened::before {
  position: absolute;
  display: block;
  right: 0;
  left: 0;
}
.dx-rtl .dx-datagrid-group-closed {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dx-rtl .dx-datagrid-content .dx-datagrid-table .dx-group-row.dx-row.dx-column-lines > td {
  border-left: none;
  border-right: none;
}
.dx-rtl .dx-datagrid-group-panel .dx-block-separator,
.dx-rtl .dx-datagrid-group-panel .dx-group-panel-item {
  margin-right: 0;
}
.dx-rtl .dx-datagrid-group-panel .dx-sort {
  margin-left: 0;
  margin-right: 6px;
}
.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid-borders > .dx-datagrid-rowsview,
.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-left: 1px solid var(--dx-datagrid-border-transparent);
  border-right: 0 solid var(--dx-datagrid-border-transparent); // Remove right border.
}
.dx-datagrid-borders > .dx-datagrid-rowsview,
.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-bottom: 1px solid var(--dx-datagrid-border-color);
}
.dx-datagrid-borders > .dx-datagrid-filter-panel {
  border-top: 1px solid var(--dx-datagrid-border-color);
}
.dx-datagrid-borders > .dx-datagrid-headers {
  border-top: 1px solid var(--dx-datagrid-border-transparent);
}
.dx-datagrid-borders > .dx-datagrid-pager {
  border-top: 1px solid var(--dx-datagrid-border-transparent);
}
.dx-datagrid-borders > .dx-datagrid-filter-panel {
  border-top-width: 0;
  border-left: 1px solid var(--dx-datagrid-border-transparent);
  border-right: 1px solid var(--dx-datagrid-border-transparent);
  border-bottom: 1px solid var(--dx-datagrid-border-color);
}
.dx-sortable-dragging > div > .dx-gridbase-container > .dx-datagrid-rowsview {
  border-color: rgba(227,242,237,.5);
}
.dx-datagrid .dx-sort-up {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-sort-up::before {
  content: "";
}
.dx-datagrid .dx-sort-down {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-sort-down::before {
  content: "";
}
.dx-datagrid .dx-sort-down,
.dx-datagrid .dx-sort-up {
  font-family: inherit;
}
.dx-datagrid .dx-sort-down::before,
.dx-datagrid .dx-sort-up::before {
  font-family: DXIcons,sans-serif;
}
.dx-datagrid .dx-datagrid-header-panel .dx-group-panel-item,
.dx-datagrid .dx-datagrid-header-panel .dx-header-filter {
  outline: 0;
}
.dx-datagrid .dx-datagrid-header-panel.dx-state-focused .dx-datagrid-group-panel .dx-group-panel-item:focus,
.dx-datagrid .dx-datagrid-header-panel.dx-state-focused .dx-datagrid-group-panel .dx-header-filter:focus {
  outline: var(--dx-datagrid-border-transparent) solid 2px;
  outline-offset: -2px;
}
.dx-datagrid .dx-datagrid-header-panel.dx-state-focused .dx-datagrid-group-panel .dx-header-filter:focus {
  outline-offset: 2px;
}
.dx-datagrid .dx-datagrid-headers .dx-header-filter,
.dx-datagrid .dx-datagrid-headers .dx-header-row > td {
  outline: 0;
}
.dx-datagrid .dx-datagrid-headers.dx-state-focused .dx-header-row td .dx-header-filter:focus,
.dx-datagrid .dx-datagrid-headers.dx-state-focused .dx-header-row td:focus {
  outline: var(--dx-datagrid-border-transparent) solid 2px;
  outline-offset: 2px;
}
.dx-datagrid .dx-datagrid-headers.dx-state-focused .dx-header-row td:focus {
  outline-offset: -2px;
}
.dx-datagrid .dx-header-filter {
  position: relative;
  color: #959595;
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-header-filter::before {
  content: "";
}
.dx-datagrid .dx-header-filter-empty {
  color: rgba(149,149,149,.5);
}
.dx-datagrid.dx-filter-menu .dx-menu-item-content .dx-icon {
  width: 14px;
  height: 14px;
  background-position: 0 0;
  background-size: 14px 14px;
  padding: 0;
  font-size: 14px;
  text-align: center;
  line-height: 14px;
}
.dx-datagrid .dx-datagrid-content-fixed .dx-datagrid-table .dx-row:not(.dx-freespace-row):not(.dx-header-row) .dx-col-fixed {
  background-color: var(--dx-datagrid-table-fixed-cell-background-color) !important;
}

.dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none {
  border-left: 2px solid var(--dx-datagrid-border-transparent);
  border-right: 2px solid var(--dx-datagrid-border-transparent);
}
.dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none.dx-first-cell,
.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none.dx-first-cell,
.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none.dx-first-cell,
.dx-datagrid .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none.dx-first-cell,
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none.dx-first-cell {
  border-left: none;
}
.dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none.dx-last-cell,
.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none.dx-last-cell,
.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none.dx-last-cell,
.dx-datagrid .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none.dx-last-cell,
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none.dx-last-cell {
  border-right: none;
}
.dx-datagrid .dx-datagrid-rowsview.dx-state-focused .dx-data-row > td.dx-command-edit > a.dx-link:focus {
  outline: var(--dx-datagrid-border-transparent) solid 2px;
  outline-offset: 2px;
}
.dx-datagrid .dx-datagrid-rowsview.dx-state-focused .dx-data-row.dx-row-focused > td.dx-command-edit > a.dx-link:focus {
  outline-color: #fff;
  outline-offset: 0;
}
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-edit-form {
  background-color: var(--dx-datagrid-background-color);
}
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row > td {
  position: relative;
}
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row > td:not(.dx-datagrid-group-space)::before {
  display: block;
  height: 100%;
  content: "";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAACqCAYAAABbAOqQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA39pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpjYWQ2ODE5MS00ZDMxLWRjNGYtOTU0NC1jNjJkMTIxMjY2M2IiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Njc1MjJDQUMzRDYzMTFFODhCMEJDMTY1QUY0RUJDNUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Njc1MjJDQUIzRDYzMTFFODhCMEJDMTY1QUY0RUJDNUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjVlMjM1Y2U0LTc5ZWUtNGI0NC05ZjlkLTk2NTZmZGFjNjhhNCIgc3RSZWY6ZG9jdW1lbnRJRD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOjk1OTQ2MjBiLTUyMTQtYTM0Yy04Nzc5LTEwMmEyMTY4MTlhOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvW6FhcAAAFESURBVHja7Nu7DYQwFEVBg+iDmFLI6D9zJQ+REThA4iNjzynBo0uwqzdERFI9jZ4AiIAAERAgAgJEQPpuWtZt9gyPli3EJ0tAgAiIgAARECACAkRAgOjzpnTz10lZCBABASIgAgJEQIAICBABASIgAgJEQIAISKMd/6nXekGVLURABASIgAARECACAkRABASILuaCykIEBIiAABEQIAICREAEBIiAABEQIAICREBUruYLqjfLFiIgQAQEiIAAERABASIgQASkqVxQWYiAABEQIAICRECACIiAABEQIAICRECACIjK9XpBZSECAkRAgAgIEAERECACAkRA2soFlYUICBABASIgQAQEiIAICBABASIgQAQEiICo3F8vqLKFCAgQAREQIAICRECACAgQAdGpISK8goUICBABASIgQAQEiGppF2AAqqIPxEOTqaQAAAAASUVORK5CYII=);
  background-repeat: no-repeat repeat;
}
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row > td[style*="text-align: right"]::before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dx-datagrid .dx-datagrid-filter-row .dx-filter-range-content {
  color: var(--dx-datagrid-text-color);
}
.dx-datagrid .dx-error-row td {
  color: var(--dx-datagrid-text-color-inverted);
  padding: 0;
}
.dx-datagrid .dx-column-lines > td:first-child {
  border-left: none;
}
.dx-datagrid .dx-column-lines > td:last-child {
  border-right: none;
}
.dx-datagrid .dx-command-drag .dx-datagrid-drag-icon {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-command-drag .dx-datagrid-drag-icon::before {
  content: "";
}
.dx-datagrid-filter-panel {
  padding: 10px;
  color: var(--dx-datagrid-text-color);
  border-top: 1px solid var(--dx-datagrid-border-color);
}
.dx-datagrid-filter-panel.dx-state-focused .dx-datagrid-filter-panel-clear-filter:focus,
.dx-datagrid-filter-panel.dx-state-focused .dx-datagrid-filter-panel-text:focus,
.dx-datagrid-filter-panel.dx-state-focused .dx-icon-filter:focus {
  outline: var(--dx-datagrid-border-transparent) solid 2px;
  outline-offset: 3px;
}
.dx-datagrid-form-buttons-container {
  float: right;
}
.dx-datagrid-form-buttons-container .dx-button {
  margin-left: 10px;
  margin-top: 10px;
}
.dx-datagrid-adaptive-more {
  cursor: pointer;
  font: 21px/21px DXIcons;
}
.dx-datagrid-adaptive-more::before {
  content: "";
}
.dx-datagrid-edit-popup .dx-error-message {
  background-color: #fa929e;
  white-space: normal;
  word-wrap: break-word;
  color: var(--dx-datagrid-text-color-inverted);
  margin-bottom: 20px;
}
.dx-rtl .dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none,
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none,
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none,
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none,
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none {
  border-left: 2px solid var(--dx-datagrid-border-transparent);
  border-right: 2px solid var(--dx-datagrid-border-transparent);
}
.dx-rtl .dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none.dx-first-cell,
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none.dx-first-cell,
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none.dx-first-cell,
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none.dx-first-cell,
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none.dx-first-cell {
  border-right: none;
}
.dx-rtl .dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none.dx-last-cell,
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none.dx-last-cell,
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none.dx-last-cell,
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none.dx-last-cell,
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none.dx-last-cell {
  border-left: none;
}
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-virtual-row > td::before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-virtual-row > td[style*="text-align: left"]::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.dx-rtl .dx-datagrid .dx-column-lines > td:first-child {
  border-right: none;
  border-left: 1px solid var(--dx-datagrid-border-transparent);
}
.dx-rtl .dx-datagrid .dx-column-lines > td:last-child {
  border-left: none;
  border-right: 1px solid var(--dx-datagrid-border-transparent);
}
.dx-rtl .dx-datagrid-form-buttons-container {
  float: left;
}
.dx-rtl .dx-datagrid-form-buttons-container .dx-button {
  margin-left: 0;
  margin-right: 10px;
}
.dx-datagrid-cell-updated-animation {
  -webkit-animation: 1s dx-datagrid-highlight-change;
  animation: 1s dx-datagrid-highlight-change;
}
@-webkit-keyframes dx-datagrid-highlight-change {
  50%,
  from {
    background-color: rgba(51,51,51,.08);
  }
}
@keyframes dx-datagrid-highlight-change {
  50%,
  from {
    background-color: rgba(51,51,51,.08);
  }
}
.dx-datagrid-container.dx-filter-menu .dx-menu-item-has-icon .dx-icon,
.dx-datagrid.dx-filter-menu .dx-menu-item-has-icon .dx-icon {
  color: #728e94;
}
.dx-datagrid-container.dx-context-menu .dx-menu-items-container .dx-icon-context-menu-sort-asc,
.dx-datagrid.dx-context-menu .dx-menu-items-container .dx-icon-context-menu-sort-asc {
  font: 16px/16px DXIcons;
  width: 16px;
  height: 16px;
  background-position: 0 0;
  background-size: 16px 16px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-container.dx-context-menu .dx-menu-items-container .dx-icon-context-menu-sort-asc::before,
.dx-datagrid.dx-context-menu .dx-menu-items-container .dx-icon-context-menu-sort-asc::before {
  content: "";
}
.dx-datagrid-container.dx-context-menu .dx-menu-items-container .dx-icon-context-menu-sort-desc,
.dx-datagrid.dx-context-menu .dx-menu-items-container .dx-icon-context-menu-sort-desc {
  font: 16px/16px DXIcons;
  width: 16px;
  height: 16px;
  background-position: 0 0;
  background-size: 16px 16px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-container.dx-context-menu .dx-menu-items-container .dx-icon-context-menu-sort-desc::before,
.dx-datagrid.dx-context-menu .dx-menu-items-container .dx-icon-context-menu-sort-desc::before {
  content: "";
}
.dx-datagrid .dx-icon-filter-operation-equals,
.dx-datagrid-container .dx-icon-filter-operation-equals {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-equals::before,
.dx-datagrid-container .dx-icon-filter-operation-equals::before {
  content: "";
}
.dx-datagrid .dx-icon-filter-operation-default,
.dx-datagrid-container .dx-icon-filter-operation-default {
  font: 12px/12px DXIcons;
  width: 12px;
  height: 12px;
  background-position: 0 0;
  background-size: 12px 12px;
  padding: 0;
  text-align: center;
}
.dx-datagrid .dx-icon-filter-operation-default::before,
.dx-datagrid-container .dx-icon-filter-operation-default::before {
  content: "";
}
.dx-datagrid .dx-icon-filter-operation-not-equals,
.dx-datagrid-container .dx-icon-filter-operation-not-equals {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-not-equals::before,
.dx-datagrid-container .dx-icon-filter-operation-not-equals::before {
  content: "";
}
.dx-datagrid .dx-icon-filter-operation-less,
.dx-datagrid-container .dx-icon-filter-operation-less {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-less::before,
.dx-datagrid-container .dx-icon-filter-operation-less::before {
  content: "";
}
.dx-datagrid .dx-icon-filter-operation-less-equal,
.dx-datagrid-container .dx-icon-filter-operation-less-equal {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-less-equal::before,
.dx-datagrid-container .dx-icon-filter-operation-less-equal::before {
  content: "";
}
.dx-datagrid .dx-icon-filter-operation-greater,
.dx-datagrid-container .dx-icon-filter-operation-greater {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-greater::before,
.dx-datagrid-container .dx-icon-filter-operation-greater::before {
  content: "";
}
.dx-datagrid .dx-icon-filter-operation-greater-equal,
.dx-datagrid-container .dx-icon-filter-operation-greater-equal {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-greater-equal::before,
.dx-datagrid-container .dx-icon-filter-operation-greater-equal::before {
  content: "";
}
.dx-datagrid .dx-icon-filter-operation-contains,
.dx-datagrid-container .dx-icon-filter-operation-contains {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-contains::before,
.dx-datagrid-container .dx-icon-filter-operation-contains::before {
  content: "";
}
.dx-datagrid .dx-icon-filter-operation-not-contains,
.dx-datagrid-container .dx-icon-filter-operation-not-contains {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-not-contains::before,
.dx-datagrid-container .dx-icon-filter-operation-not-contains::before {
  content: "";
}
.dx-datagrid .dx-icon-filter-operation-starts-with,
.dx-datagrid-container .dx-icon-filter-operation-starts-with {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-starts-with::before,
.dx-datagrid-container .dx-icon-filter-operation-starts-with::before {
  content: "";
}
.dx-datagrid .dx-icon-filter-operation-ends-with,
.dx-datagrid-container .dx-icon-filter-operation-ends-with {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-ends-with::before,
.dx-datagrid-container .dx-icon-filter-operation-ends-with::before {
  content: "";
}
.dx-datagrid .dx-icon-filter-operation-between,
.dx-datagrid-container .dx-icon-filter-operation-between {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-between::before,
.dx-datagrid-container .dx-icon-filter-operation-between::before {
  content: "";
}
.dx-datagrid {
  color: var(--dx-datagrid-text-color);
  background-color: var(--dx-datagrid-background-color);
  line-height: inherit;
}
.dx-datagrid .dx-row-alt > td,
.dx-datagrid .dx-row-alt > tr > td {
  background-color: rgba(232,232,232,.4);
  border-top: 1px solid rgba(232,232,232,.4);
  border-bottom: 1px solid rgba(232,232,232,.4);
}
.dx-datagrid .dx-row-alt.dx-row:not(.dx-row-removed) {
  border-bottom-color: var(--dx-datagrid-border-transparent);
}
.dx-datagrid .dx-link {
  color: #3eb8b0;
}
.dx-datagrid .dx-row-lines > td {
  border-bottom: 1px solid var(--dx-datagrid-border-color);
}
.dx-datagrid .dx-datagrid-headers .dx-column-lines > td {
  border-left: 1px solid var(--dx-datagrid-border-color);
  border-right: 0 solid var(--dx-datagrid-border-color); // Remove right border.
}
.dx-datagrid .dx-datagrid-rowsview .dx-column-lines > td {
  border-left: 1px solid var(--dx-datagrid-border-transparent);
  border-right: 0 solid var(--dx-datagrid-border-transparent); // Remove right border.
}
.dx-datagrid .dx-error-row .dx-closebutton {
  float: right;
  margin: 9px;
  font: 14px/14px DXIcons;
  width: 14px;
  height: 14px;
  background-position: 0 0;
  background-size: 14px 14px;
  padding: 0;
  text-align: center;
}
.dx-datagrid .dx-error-row .dx-closebutton::before {
  content: "";
}
.dx-datagrid .dx-error-row .dx-error-message {
  background-color: #fa929e;
  white-space: normal;
  word-wrap: break-word;
  padding: 7px 35px 7px 7px;
}
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit-with-icons .dx-link {
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  margin: 0 3px;
}
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row > td[style*="text-align: right"]:before {
  background-position-y: 34px;
}
.dx-datagrid .dx-command-drag {
  width: 36px;
  min-width: 36px;
}
.dx-datagrid .dx-command-drag .dx-datagrid-drag-icon {
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  line-height: 19px;
}
.dx-datagrid-edit-popup .dx-error-message {
  padding: 7px;
}
.dx-datagrid-headers .dx-texteditor-input,
.dx-datagrid-rowsview .dx-texteditor-input {
  padding: 7px;
  min-height: 33px;
}
.dx-datagrid-headers .dx-numberbox.dx-show-clear-button.dx-texteditor-empty:not(.dx-invalid) .dx-texteditor-input,
.dx-datagrid-headers .dx-textbox.dx-show-clear-button.dx-texteditor-empty:not(.dx-invalid) .dx-texteditor-input,
.dx-datagrid-rowsview .dx-numberbox.dx-show-clear-button.dx-texteditor-empty:not(.dx-invalid) .dx-texteditor-input,
.dx-datagrid-rowsview .dx-textbox.dx-show-clear-button.dx-texteditor-empty:not(.dx-invalid) .dx-texteditor-input {
  padding-right: 7px;
}
.dx-datagrid-headers .dx-lookup,
.dx-datagrid-rowsview .dx-lookup {
  height: auto;
}
.dx-datagrid-headers .dx-lookup-field,
.dx-datagrid-rowsview .dx-lookup-field {
  padding-left: 7px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.dx-datagrid-headers .dx-searchbox .dx-placeholder::before,
.dx-datagrid-headers .dx-searchbox .dx-texteditor-input,
.dx-datagrid-rowsview .dx-searchbox .dx-placeholder::before,
.dx-datagrid-rowsview .dx-searchbox .dx-texteditor-input {
  padding-left: 34px;
}
.dx-rtl .dx-datagrid-headers .dx-searchbox .dx-placeholder::before,
.dx-rtl .dx-datagrid-headers .dx-searchbox .dx-texteditor-input,
.dx-rtl .dx-datagrid-rowsview .dx-searchbox .dx-placeholder::before,
.dx-rtl .dx-datagrid-rowsview .dx-searchbox .dx-texteditor-input,
.dx-rtl.dx-datagrid-headers .dx-searchbox .dx-placeholder::before,
.dx-rtl.dx-datagrid-headers .dx-searchbox .dx-texteditor-input,
.dx-rtl.dx-datagrid-rowsview .dx-searchbox .dx-placeholder::before,
.dx-rtl.dx-datagrid-rowsview .dx-searchbox .dx-texteditor-input {
  padding-right: 34px;
}
.dx-editor-cell .dx-numberbox-spin-button {
  background-color: transparent;
}
.dx-editor-cell .dx-icon-clear {
  left: 0;
}
.dx-editor-cell .dx-texteditor,
.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  background: var(--dx-datagrid-background-color);
}
.dx-editor-cell .dx-dropdowneditor {
  background-color: var(--dx-datagrid-background-color);
}
.dx-editor-cell.dx-focused .dx-dropdowneditor-icon {
  border-radius: 0;
}
.dx-editor-cell.dx-editor-inline-block:not(.dx-command-select)::before {
  padding-top: 2px;
  padding-bottom: 2px;
}
.dx-datagrid-checkbox-size {
  vertical-align: middle;
  line-height: 1em;
}
.dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
  font-size: 12px;
}
.dx-datagrid-checkbox-size .dx-checkbox-icon {
  height: 16px;
  width: 16px;
}
.dx-datagrid-checkbox-size.dx-checkbox-indeterminate .dx-checkbox-icon {
  font-size: 6px;
}
.dx-datagrid-column-chooser-list .dx-empty-message,
.dx-device-mobile .dx-datagrid-column-chooser-list .dx-empty-message {
  color: #999;
  padding: 0 20px;
}
.dx-datagrid-column-chooser {
  color: var(--dx-datagrid-text-color);
  font-weight: 400;
  font-size: 14px;
  font-family: var(--font-family-base);
  line-height: 1.35715;
}
.dx-datagrid-column-chooser input,
.dx-datagrid-column-chooser textarea {
  font-family: var(--font-family-base);
  line-height: 1.35715;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-drag .dx-popup-content {
  padding: 0 20px 20px;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-drag .dx-popup-content .dx-treeview-search {
  margin-bottom: 10px;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-drag .dx-popup-content .dx-treeview-node {
  padding-left: 20px;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-select .dx-popup-content {
  padding: 0 20px 20px;
}
.dx-datagrid-column-chooser .dx-overlay-content {
  background-color: var(--dx-datagrid-background-color);
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.2);
  box-shadow: 0 1px 3px rgba(0,0,0,.2);
}
.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-title {
  padding-top: 7px;
  padding-bottom: 9px;
  background-color: transparent;
}
.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-content .dx-column-chooser-item {
  margin-bottom: 10px;
  background-color: var(--dx-datagrid-background-color);
  color: #959595;
  font-weight: 400;
  border: 1px solid var(--dx-datagrid-border-color);
  padding: 7px;
  -webkit-box-shadow: 0 1px 3px -1px rgba(0,0,0,.2);
  box-shadow: 0 1px 3px -1px rgba(0,0,0,.2);
}
.dx-datagrid-drag-header {
  -webkit-box-shadow: 0 0 1px rgba(0,0,0,.1),0 1px 3px rgba(0,0,0,.2);
  box-shadow: 0 0 1px rgba(0,0,0,.1),0 1px 3px rgba(0,0,0,.2);
  color: #959595;
  font-weight: 400;
  padding: 7px;
  border: 1px solid rgba(227,242,237,.5);
  background-color: var(--dx-datagrid-background-color);
}
.dx-datagrid-columns-separator {
  background-color: rgba(227,242,237,.5);
}
.dx-datagrid-columns-separator-transparent {
  background-color: transparent;
}
.dx-datagrid-drop-highlight > td {
  background-color: var(--dx-datagrid-background-color);
  color: var(--dx-datagrid-text-color);
}
.dx-datagrid-focus-overlay {
  border: 2px solid var(--dx-datagrid-border-transparent);
}
.dx-datagrid-table .dx-row .dx-command-select {
  width: 70px;
  min-width: 70px;
}
.dx-datagrid-table .dx-row .dx-command-edit {
  width: 85px;
  min-width: 85px;
}
.dx-datagrid-table .dx-row .dx-command-expand {
  width: 30px;
  min-width: 30px;
}
.dx-datagrid-table .dx-row .dx-command-expand.dx-datagrid-group-space {
  padding-top: 7px;
}
.dx-datagrid-table .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused) {
  background-color: var(--dx-datagrid-table-cell-hover-background-color);
  color: var(--dx-datagrid-text-color);
}

.dx-datagrid-table .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused).dx-datagrid-group-space {
  border-right-color: rgba(232,232,232,.4);
}
.dx-datagrid-table .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused).dx-command-select {
  overflow: inherit;
}
.dx-datagrid-table .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > .dx-datagrid-readonly .dx-texteditor .dx-texteditor-input {
  background-color: rgba(232,232,232,.4);
  color: var(--dx-datagrid-text-color);
}
.dx-datagrid-table .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td.dx-pointer-events-none {
  background-color: transparent;
}
.dx-datagrid-headers {
  color: var(--dx-datagrid-table-head-text-color);
  font-weight: var(--dx-datagrid-table-head-font-weight);
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  border-bottom: 1px solid var(--dx-datagrid-border-transparent);
  background-color: var(--dx-datagrid-table-head-background-color);
  border-radius: 5px;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td[role="columnheader"] {
  --dx-datagrid-table-head-cell-height: 32px;
  font-weight: var(--dx-datagrid-table-head-font-weight);
  height: var(--dx-datagrid-table-head-cell-height);
  max-height: var(--dx-datagrid-table-head-cell-height);
  min-height: var(--dx-datagrid-table-head-cell-height);
  padding: var(--dx-datagrid-table-head-cell-padding-y) var(--dx-datagrid-table-head-cell-padding-x);
}

.dx-datagrid-headers .dx-datagrid-content {
  margin-bottom: -1px;
}
.dx-datagrid-borders .dx-datagrid-headers .dx-datagrid-table {
  border-bottom-width: 1px;
}
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: 1px solid var(--dx-datagrid-border-transparent);
}
.dx-datagrid-filter-row .dx-menu .dx-overlay-content {
  color: var(--dx-datagrid-text-color);
}
.dx-datagrid-filter-row .dx-menu-item.dx-state-focused {
  background-color: transparent;
}
.dx-datagrid-filter-row .dx-menu-item.dx-state-focused::after {
  border: 2px solid var(--dx-datagrid-border-color);
}
.dx-datagrid-filter-row .dx-menu-item.dx-state-focused.dx-menu-item-expanded {
  background-color: var(--dx-datagrid-background-color);
}
.dx-datagrid-filter-row .dx-menu-item.dx-state-focused.dx-menu-item-expanded::after {
  border-color: var(--dx-datagrid-border-transparent);
}
.dx-datagrid-filter-row .dx-menu-item-has-submenu.dx-state-hover {
  background-color: var(--dx-datagrid-border-transparent);
}
.dx-datagrid-filter-row .dx-menu-item-has-submenu.dx-menu-item-expanded.dx-state-hover {
  background-color: var(--dx-datagrid-background-color);
}
.dx-datagrid-filter-row .dx-cell-modified::after,
.dx-datagrid-filter-row .dx-datagrid-invalid::after,
.dx-datagrid-filter-row .dx-highlight-outline::after {
  border-color: rgba(114,214,60,.5);
}
.dx-datagrid-filter-row .dx-menu-item-content .dx-icon {
  color: #728e94;
}
.dx-datagrid-filter-row td .dx-editor-container .dx-filter-range-content {
  padding: 7px 7px 7px 32px;
}
.dx-datagrid-filter-range-overlay .dx-overlay-content {
  border: 1px solid var(--dx-datagrid-border-color);
  overflow: inherit;
  -webkit-box-shadow: 2px 2px 3px rgba(0,0,0,.15);
  box-shadow: 2px 2px 3px rgba(0,0,0,.15);
}
.dx-datagrid-filter-range-overlay .dx-overlay-content .dx-editor-container.dx-cell-modified::after,
.dx-datagrid-filter-range-overlay .dx-overlay-content .dx-editor-container.dx-datagrid-invalid::after {
  border-color: rgba(114,214,60,.5);
  left: 0;
}
.dx-datagrid-filter-range-overlay .dx-overlay-content .dx-texteditor .dx-texteditor-input {
  background-color: var(--dx-datagrid-background-color);
  padding: 7px;
}
.dx-datagrid-filter-range-overlay .dx-overlay-content .dx-texteditor.dx-state-focused::after {
  border: 2px solid var(--dx-datagrid-border-color);
}
.dx-filter-menu.dx-menu {
  background-color: transparent;
  height: 100%;
}
.dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
  padding: 6px 5px 7px;
}
.dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content .dx-icon {
  margin: 0 3px;
}
.dx-editor-with-menu .dx-filter-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default,
.dx-filter-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default {
  margin-top: 2px;
}
.dx-cell-modified,
.dx-datagrid-invalid {
  padding: 7px;
}
.dx-datagrid-header-panel {
  border-bottom: 1px solid var(--dx-datagrid-border-color);
}
.dx-datagrid-header-panel .dx-toolbar {
  margin-bottom: 10px;
}
.dx-datagrid-header-panel .dx-apply-button {
  background-color: #72d63c;
  border-color: #64cd2b;
  color: var(--dx-datagrid-text-color-inverted);
}
.dx-datagrid-header-panel .dx-apply-button .dx-icon {
  color: var(--dx-datagrid-text-color-inverted);
}
.dx-datagrid-header-panel .dx-apply-button.dx-state-hover {
  background-color: #5ab827;
  border-color: #64cd2b;
}
.dx-datagrid-header-panel .dx-apply-button.dx-state-focused {
  background-color: #50a322;
  border-color: #64cd2b;
}
.dx-datagrid-header-panel .dx-apply-button.dx-state-active {
  background-color: #458e1e;
  border-color: #64cd2b;
  color: var(--dx-datagrid-text-color-inverted);
}
.dx-icon-column-chooser {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-icon-column-chooser::before {
  content: "";
}
.dx-datagrid-addrow-button .dx-icon-edit-button-addrow {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-addrow-button .dx-icon-edit-button-addrow::before {
  content: "";
}
.dx-datagrid-cancel-button .dx-icon-edit-button-cancel {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-cancel-button .dx-icon-edit-button-cancel::before {
  content: "";
}
.dx-datagrid-save-button .dx-icon-edit-button-save {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-save-button .dx-icon-edit-button-save::before {
  content: "";
}
.dx-apply-button .dx-icon-apply-filter {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-apply-button .dx-icon-apply-filter::before {
  content: "";
}
.dx-datagrid-export-button .dx-icon-export-to {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-export-button .dx-icon-export-to::before {
  content: "";
}
.dx-datagrid-export-button .dx-icon-export-excel-button {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-export-button .dx-icon-export-excel-button::before {
  content: "";
}
.dx-datagrid-adaptive-more {
  width: 21px;
  height: 21px;
  background-position: 0 0;
  background-size: 21px 21px;
  padding: 0;
  font-size: 21px;
  text-align: center;
  line-height: 21px;
}
.dx-datagrid-borders .dx-datagrid-rowsview,
.dx-datagrid-headers + .dx-datagrid-rowsview {
  border-top: 1px solid var(--dx-datagrid-border-transparent);
}
.dx-datagrid-rowsview .dx-row:first-child {
  border-top: 1px solid var(--dx-datagrid-border-transparent);
}
.dx-datagrid-rowsview .dx-row {
  border-top: 1px solid var(--dx-datagrid-border-color);
  border-bottom: 1px solid var(--dx-datagrid-border-color);
}
.dx-datagrid-rowsview .dx-row:hover {
  cursor: pointer;
}
.dx-datagrid-rowsview .dx-row.dx-edit-row:first-child > td {
  border-top-width: 0;
  border-bottom: 1px solid var(--dx-datagrid-border-color);
}
.dx-datagrid-rowsview .dx-row.dx-edit-row > td {
  border-top: 1px solid var(--dx-datagrid-border-color);
  border-bottom: 1px solid var(--dx-datagrid-border-color);
}
.dx-datagrid-rowsview .dx-row.dx-datagrid-edit-form {
  white-space: normal;
}
.dx-datagrid-rowsview .dx-master-detail-row .dx-master-detail-cell,
.dx-datagrid-rowsview .dx-master-detail-row > .dx-datagrid-group-space {
  border-top: 1px solid var(--dx-datagrid-border-color);
  border-bottom: 1px solid var(--dx-datagrid-border-color);
}
.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) .dx-master-detail-cell,
.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) > .dx-datagrid-group-space {
  background-color: #fafafa;
}
.dx-datagrid-rowsview .dx-data-row .dx-validator.dx-datagrid-invalid.dx-cell-modified::after,
.dx-datagrid-rowsview .dx-data-row .dx-validator.dx-datagrid-invalid.dx-datagrid-invalid::after {
  border: 1px solid rgba(247,74,94,.4);
}
.dx-datagrid-rowsview .dx-data-row .dx-validator.dx-datagrid-invalid.dx-focused.dx-cell-modified::after,
.dx-datagrid-rowsview .dx-data-row .dx-validator.dx-datagrid-invalid.dx-focused.dx-datagrid-invalid::after {
  border-color: var(--dx-datagrid-border-transparent);
}
.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after,
.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-datagrid-invalid::after {
  border-color: rgba(114,214,60,.5);
}
.dx-datagrid-rowsview .dx-overlay-wrapper.dx-invalid-message .dx-overlay-content {
  padding: 9px 17px;
}
.dx-datagrid-rowsview .dx-row-removed > td {
  background-color: rgba(114,214,60,.5);
  border-top: 1px solid rgba(114,214,60,.5);
  border-bottom: 1px solid rgba(114,214,60,.5);
}
.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-adaptive-item-text {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
}
.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-datagrid-invalid {
  border: 1px solid rgba(247,74,94,.4);
}
.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-datagrid-invalid.dx-adaptive-item-text {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 7px;
}
.dx-datagrid-rowsview .dx-item-modified {
  border-radius: 2px;
  border: 2px solid rgba(114,214,60,.5);
}
.dx-datagrid-rowsview .dx-item-modified.dx-adaptive-item-text {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
}
.dx-datagrid-rowsview .dx-row-focused:focus {
  outline: 0;
}
.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
  background-color: var(--dx-datagrid-table-cell-focused-background-color);
  color: var(--dx-datagrid-text-color-normal);
}
.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link .dx-texteditor-input,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused) .dx-texteditor-input,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) .dx-texteditor-input {
  border-radius: 0;
}
.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link .dx-datagrid-group-closed,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link .dx-datagrid-group-opened,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused) .dx-datagrid-group-closed,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused) .dx-datagrid-group-opened,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) .dx-datagrid-group-closed,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) .dx-datagrid-group-opened {
  color: var(--dx-datagrid-text-color-inverted);
}
.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link:focus {
  background-color: var(--dx-datagrid-background-color);
  color: var(--dx-datagrid-text-color);
}
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
  border-bottom: 1px solid var(--dx-datagrid-border-transparent);
}
.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > tr:first-child > td {
  border-top: 1px solid var(--dx-datagrid-border-transparent);
  border-bottom: 1px solid var(--dx-datagrid-border-transparent);
}
.dx-datagrid-rowsview .dx-row-focused.dx-group-row {
  background-color: #DCE5EA;
  color: var(--dx-datagrid-text-color-normal);
}
.dx-datagrid-rowsview .dx-row-focused.dx-group-row .dx-datagrid-group-closed,
.dx-datagrid-rowsview .dx-row-focused.dx-group-row .dx-datagrid-group-opened,
.dx-datagrid-rowsview .dx-row-focused.dx-group-row .dx-datagrid-summary-item {
  color: var(--dx-datagrid-text-color-inverted);
}
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
  background-color: var(--dx-datagrid-table-body-row-selected);
  color: var(--dx-datagrid-text-color);
}
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td.dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td.dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td.dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td.dx-datagrid-group-space {
  border-right-color: var(--dx-datagrid-border-transparent);
}
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td.dx-pointer-events-none,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td.dx-pointer-events-none,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td.dx-pointer-events-none,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td.dx-pointer-events-none {
  border-left-color: var(--dx-datagrid-border-transparent);
  border-right-color: var(--dx-datagrid-border-transparent);
}
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td.dx-focused,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td.dx-focused,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td.dx-focused,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td.dx-focused {
  background-color: var(--dx-datagrid-background-color);
  color: var(--dx-datagrid-text-color);
}
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > td {
  border-bottom: 1px solid var(--dx-datagrid-border-transparent);
  border-top: 1px solid var(--dx-datagrid-border-transparent);
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines) > tr:first-child > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > tr:first-child > td {
  border-top: 1px solid var(--dx-datagrid-border-transparent);
}
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines) > tr:last-child > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > tr:last-child > td {
  border-bottom: 1px solid var(--dx-datagrid-border-transparent);
}
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover.dx-column-lines > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover.dx-column-lines > tr > td {
  border-left-color: var(--dx-datagrid-border-transparent);
  border-right-color: var(--dx-datagrid-border-transparent);
}
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-row-lines > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-row-lines > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover.dx-row-lines > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover.dx-row-lines > tr > td {
  border-bottom-color: var(--dx-datagrid-border-transparent);
}
.dx-datagrid-rowsview.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-both .dx-scrollable-wrapper .dx-scrollable-container .dx-scrollable-content {
  padding-right: 0;
}
.dx-datagrid-rowsview td.dx-validation-pending {
  position: relative;
  padding: 0;
}
.dx-datagrid-rowsview td.dx-validation-pending.dx-cell-modified,
.dx-datagrid-rowsview td.dx-validation-pending.dx-datagrid-invalid {
  padding: 7px 34px 7px 7px;
}
.dx-rtl .dx-datagrid-rowsview td.dx-validation-pending.dx-cell-modified,
.dx-rtl .dx-datagrid-rowsview td.dx-validation-pending.dx-datagrid-invalid,
.dx-rtl.dx-datagrid-rowsview td.dx-validation-pending.dx-cell-modified,
.dx-rtl.dx-datagrid-rowsview td.dx-validation-pending.dx-datagrid-invalid {
  padding-left: 34px;
  padding-right: 7px;
}
.dx-datagrid-rowsview td.dx-validation-pending .dx-pending-indicator {
  pointer-events: none;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  width: 22px;
  height: 22px;
  right: 4px;
  background-color: var(--dx-datagrid-background-color);
}
.dx-rtl .dx-datagrid-rowsview td.dx-validation-pending .dx-pending-indicator,
.dx-rtl.dx-datagrid-rowsview td.dx-validation-pending .dx-pending-indicator {
  left: 4px;
  right: auto;
}
.dx-datagrid-rowsview td.dx-editor-cell .dx-texteditor.dx-validation-pending .dx-texteditor-input {
  padding-right: 34px;
}
.dx-datagrid-rowsview td.dx-editor-cell .dx-texteditor.dx-validation-pending.dx-rtl .dx-texteditor-input {
  padding-right: 7px;
  padding-bottom: 7px;
  padding-left: 34px;
}
.dx-datagrid-search-text {
  color: var(--dx-datagrid-text-color);
  background-color: var(--dx-datagrid-background-color);
}
.dx-datagrid-nodata {
  color: #999;
  font-size: 17px;
}
.dx-datagrid-bottom-load-panel {
  border-top: 1px solid var(--dx-datagrid-border-color);
}
.dx-datagrid-pager {
  border-top: 3px double var(--dx-datagrid-border-color);
}
.dx-datagrid-pager.dx-widget {
  color: var(--dx-datagrid-text-color);
}
.dx-datagrid-pager .dx-navigate-button,
.dx-datagrid-pager .dx-page,
.dx-datagrid-pager .dx-page-size {
  outline: 0;
}
.dx-datagrid-pager.dx-state-focused .dx-navigate-button:focus,
.dx-datagrid-pager.dx-state-focused .dx-page-size:focus,
.dx-datagrid-pager.dx-state-focused .dx-page:focus {
  outline: var(--dx-datagrid-border-transparent) solid 2px;
  outline-offset: -2px;
}
.dx-datagrid-summary-item {
  color: var(--color-text-strong);
}
.dx-datagrid-total-footer {
  position: relative;
  border-top: 1px solid var(--dx-datagrid-border-color);
}
.dx-datagrid-revert-tooltip .dx-overlay-content {
  background-color: var(--dx-datagrid-background-color);
  min-width: inherit;
}
.dx-datagrid-revert-tooltip .dx-revert-button {
  margin: 0 1px;
  background-color: #f74a5e;
  border-color: #f63248;
  color: var(--dx-datagrid-text-color-inverted);
}
.dx-datagrid-revert-tooltip .dx-revert-button .dx-icon {
  color: var(--dx-datagrid-text-color-inverted);
}
.dx-datagrid-revert-tooltip .dx-revert-button.dx-state-hover {
  background-color: #f51933;
  border-color: #f63248;
}
.dx-datagrid-revert-tooltip .dx-revert-button.dx-state-focused {
  background-color: #ea0a24;
  border-color: #f63248;
}
.dx-datagrid-revert-tooltip .dx-revert-button.dx-state-active {
  background-color: #d20920;
  border-color: #f63248;
  color: var(--dx-datagrid-text-color-inverted);
}
.dx-datagrid-revert-tooltip .dx-revert-button > .dx-button-content {
  padding: 7px;
}
.dx-toolbar-menu-section .dx-datagrid-checkbox-size {
  width: 100%;
}
.dx-toolbar-menu-section .dx-datagrid-checkbox-size .dx-checkbox-container {
  padding: 14px;
}
.dx-toolbar-menu-section .dx-datagrid-checkbox-size .dx-checkbox-text {
  padding-left: 34px;
}
.dx-rtl .dx-toolbar-menu-section .dx-checkbox-text {
  padding-right: 34px;
  padding-left: 27px;
}
.dx-rtl .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused).dx-datagrid-group-space {
  border-left-color: rgba(232,232,232,.4);
  border-right-color: var(--dx-datagrid-border-transparent);
}
.dx-rtl .dx-datagrid-filter-row .dx-menu .dx-menu-item-has-submenu.dx-menu-item-has-icon .dx-icon {
  margin: 0 3px;
}
.dx-rtl .dx-datagrid-filter-row td .dx-editor-container .dx-filter-range-content {
  padding: 7px 32px 7px 7px;
}
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row > td,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row > tr > td,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row:hover > td,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row:hover > tr > td {
  border-right-color: var(--dx-datagrid-border-transparent);
}
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row > td:not(.dx-focused).dx-datagrid-group-space,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row > tr > td:not(.dx-focused).dx-datagrid-group-space,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row:hover > td:not(.dx-focused).dx-datagrid-group-space,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row:hover > tr > td:not(.dx-focused).dx-datagrid-group-space {
  border-left-color: var(--dx-datagrid-border-transparent);
}
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row > td.dx-pointer-events-none,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row > tr > td.dx-pointer-events-none,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row:hover > td.dx-pointer-events-none,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row:hover > tr > td.dx-pointer-events-none {
  border-left-color: var(--dx-datagrid-border-transparent);
}
.dx-rtl .dx-datagrid-rowsview.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-both .dx-scrollable-wrapper .dx-scrollable-container .dx-scrollable-content {
  padding-left: 0;
}
.dx-datagrid-group-panel {
  font-size: 14px;
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}
.dx-datagrid-group-panel .dx-group-panel-message {
  color: #959595;
  font-weight: 400;
  padding: 7px;
  border-top: 1px solid var(--dx-datagrid-border-transparent);
  border-bottom: 1px solid var(--dx-datagrid-border-transparent);
}
.dx-datagrid-group-panel .dx-group-panel-item {
  margin-right: 10px;
  color: #959595;
  font-weight: 400;
  border: 1px solid var(--dx-datagrid-border-color);
  padding: 7px;
}
.dx-datagrid-group-panel .dx-block-separator {
  margin-right: 10px;
  color: #959595;
  font-weight: 400;
  padding: 8px;
  background-color: #eee;
}
.dx-datagrid-group-panel .dx-sort {
  color: #728e94;
}
.dx-datagrid-rowsview .dx-row.dx-group-row:first-child {
  border-top: none;
}
.dx-datagrid-rowsview .dx-row.dx-group-row {
  font-weight: 700;
}
.dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
  color: #959595;
  background-color: var(--dx-datagrid-background-color);
}
.dx-datagrid-rowsview .dx-row.dx-group-row td {
  border-top-color: var(--dx-datagrid-border-color);
  border-bottom-color: var(--dx-datagrid-border-color);
}
.dx-datagrid-group-opened {
  font: 18px/18px DXIcons;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
  color: #728e94;
}
.dx-datagrid-group-opened::before {
  content: "";
}
.dx-datagrid-group-closed {
  font: 18px/18px DXIcons;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
  color: #728e94;
}
.dx-datagrid-group-closed::before {
  content: "";
}
.dx-datagrid-group-closed,
.dx-datagrid-group-opened {
  cursor: pointer;
  position: relative;
  width: 100%;
}
.dx-row.dx-datagrid-group-footer.dx-column-lines {
  border-bottom: 1px solid var(--dx-datagrid-border-color);
}
.dx-row.dx-datagrid-group-footer > td {
  background-color: var(--dx-datagrid-background-color);
  border-top: 1px solid var(--dx-datagrid-border-color);
  border-left-width: 0;
  border-right-width: 0;
}
.dx-rtl .dx-datagrid-group-panel .dx-block-separator,
.dx-rtl .dx-datagrid-group-panel .dx-group-panel-item {
  margin-left: 10px;
}
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text,
.dx-datagrid-filter-panel .dx-icon-filter {
  outline: 0;
  color: var(--dx-datagrid-text-color);
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td {
  font-weight: var(--dx-datagrid-font-weight);
}

.dx-datagrid-focus-overlay {
  border: 2px solid black;
}
