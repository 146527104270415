
.c-items-table .action-columns-dropzone-container,
.c-beyond-table .action-columns-dropzone-container,
.c-tree-list-table .action-columns-dropzone-container {
    display: grid;
    position: absolute;
    z-index: 1000;
    background: #f0f0f0;
    border-radius: 5px;
    border: 2px dashed lightgrey;
    min-height: 80px;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.1);
}

.c-items-table .hide-icon,
.c-beyond-table .hide-icon,
.c-tree-list-table .hide-icon {
  color: var(--color-bui-button-icon-weak);
  margin: 5px 0;
}

.cell-header-text-style {
  font-style: italic;
}
