.dx-treelist {
  --dx-treelist-table-head-background-color: var(--color-primary-300);
  --dx-treelist-table-head-font-weight: var(--font-weight-bold);
  --dx-treelist-table-head-text-color: var(--color-white);
  --dx-treelist-border-color: var(--color-border-weak);
  --dx-treelist-text-color: var(--color-text-normal);
}

.dx-treelist-container {
  color: var(--dx-treelist-text-color);
}

.dx-treelist-headers {
  background-color: var(--dx-treelist-table-head-background-color);
  color: var(--dx-treelist-table-head-text-color);
  font-weight: var(--dx-treelist-table-head-font-weight);
}

.dx-treelist-headers .dx-treelist-table .dx-header-row {
  td:hover {
    background: #486B7E;
  }
}

.dx-treelist-headers .dx-column-lines > td {
  border-bottom-color: transparent;
  border-left-color: var(--dx-treelist-border-color);
  border-right-color: var(--dx-treelist-border-color);
  text-align: left !important;
}

.dx-treelist-content .dx-treelist-table .dx-row {
  height: 30px;
}

.dx-row > td {
  padding: 0 17px;
  vertical-align: middle;
}

.dx-column-lines > td {
  border-left-color: transparent;
  border-right-color: transparent;
}

.dx-treelist-content .dx-treelist-table .dx-row {
  border-color: var(--dx-treelist-border-color);
}

.dx-row-lines > td {
  border-bottom-color: var(--dx-treelist-border-color);
}

.dx-treelist-borders .dx-treelist-rowsview,
.dx-treelist-headers + .dx-treelist-rowsview,
.dx-treelist-rowsview.dx-treelist-after-headers {
  border-top-color: #0ff;
}

.dx-treelist-borders > .dx-treelist-rowsview,
.dx-treelist-borders > .dx-treelist-total-footer {
  border-color: transparent;
}
