.mat-radio-label {
  white-space: initial !important;
}

.mat-radio-disabled {
  opacity: 0.38;
}

.material-light-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle, .material-light-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: var(--color-grayscale-800);
}

.material-light-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: var(--color-grayscale-800);
}

.material-light-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.material-light-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.material-light-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.material-light-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--color-system-success);
}

.material-light-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--color-system-success);
}

@media (min-width: 361px) {
  .mat-radio-button + .mat-radio-button {
    --mat-button-left-margin: var(--space-s);
    margin-left: var(--mat-button-left-margin);
  }
}

@media (max-width: 360px) {
  .mat-radio-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .mat-radio-group:first-child {
    margin-bottom: 20px;
  }

  .mat-radio-group p {
    margin-bottom: 5px;
    margin-top: 0;
  }
}

.mat-radio-group:first-child p {
  margin-top: 0;
}

.material-light-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: var(--color-grayscale-800);
}

.material-light-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: var(--color-grayscale-800);
}

.material-light-theme .c-account-organization__application-radio .mat-radio-outer-circle {
  border-color: var(--color-grayscale-800);
}
