
.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border .dx-area-description-cell .dx-area-field {
  background-color: var(--color-primary-300);
}

.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border .dx-area-description-cell {
  background-color: #fff;
}

.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border .dx-area-description-cell .dx-area-field .dx-area-field-content{
  color: #fff;
}

.dx-pivotgrid .dx-area-description-cell .dx-pivotgrid-fields-area .dx-sort-up {
  color: #fff;
}

.dx-pivotgrid .dx-area-description-cell .dx-pivotgrid-fields-area .dx-sort-down {
  color: #fff;
}

.dx-pivotgrid .dx-area-description-cell .dx-pivotgrid-fields-area .dx-header-filter-empty {
  color: #fff;
  font-size: 12px;
  height: 14px;
  margin-top: 2.5px;
}

.dx-pivotgrid .dx-area-description-cell .dx-pivotgrid-fields-area-head td {
  padding: 0 6px 5px 0;
  border-radius: 5px;
}

.dx-pivotgrid .dx-area-description-cell .dx-pivotgrid-fields-area-head .dx-area-field.dx-area-box {
  border-radius: 5px;
}
