.mat-tab-body-wrapper {
  --c-beyond-tabs-inner-active-tab-text-color: var(--color-primary);

  padding-top: var(--space-xs);
}

.c-beyond-tabs > .mat-tab-body-wrapper {
  padding-top: 0 !important;
  overflow-y: auto;
  height: 100%;

  .is-touch-device & {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
  }
}

.mat-tab-label {
  max-width: 250px !important;
  opacity: 1 !important;
  padding: 0 !important;
  height: 35px !important;
}

.c-save-object__body-section .mat-tab-label {
  max-width: initial !important;
  width: 100%;
}

.c-beyond-tabs .mat-tab-label {
  display: grid !important;
  grid-template-columns: auto !important;
  justify-content: unset;

  &:not(:last-child) {
    .mat-tab-label-content {
      position: relative;
    }

    .c-beyond-tabs__tab-content {
      &::after {
        --c-beyond-tabs-separator-distance: 8px;
        background-color: var(--color-background-medium);
        bottom: var(--c-beyond-tabs-separator-distance);
        content: '';
        display: inline-block;
        position: absolute;
        right: 0;
        top: var(--c-beyond-tabs-separator-distance);
        width: 1px;
      }
    }
  }
}

.c-beyond-tabs .mat-tab-label-content {
  display: block !important;
}

.c-beyond-tabs__tab-content-actions .mat-button-wrapper {
  align-items: center !important;
  display: flex;
  height: 34px !important;
  justify-content: center;
  width: 40px;
}

.c-beyond-tabs .mat-tab-header-pagination-chevron {
  border-color: var(--color-icon-text-weak);
}

.c-beyond-tabs .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.28);
}

.c-beyond-tabs .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-ink-bar {
  --c-beyond-tabs-tab-active-border-color: var(--color-primary);

  height: 1px !important;
  background-color: var(--c-beyond-tabs-tab-active-border-color);
}

.c-devops-management-client-details .mat-tab-label {
  min-width: 150px;
}

.mat-tab-header .mat-tab-label-active .mat-tab-label-content {
  color: var(--c-beyond-tabs-inner-active-tab-text-color);
}

.c-execute-action-dialog .mat-tab-label-active .mat-tab-label-content span {
  color: var(--color-primary) !important;
}

.c-execute-action-dialog .mat-tab-header {
  background-color: var(--color-white);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.c-beyond-tabs--hidden-tabs .mat-tab-header {
  display: none;
}

.c-bui-section__body--is-expanded .mat-tab-group {
  height: 100%;
}
