.dx-header-filter-menu {
  .dx-button.dx-button-mode-contained {
    border: none !important;
  }

  .dx-toolbar-items-container {
    .dx-toolbar-after {
      .dx-toolbar-button:nth-of-type(1) {
        .dx-button-text {
          color: var(--color-link-normal);
        }
      }
    }
  }
}

.dx-overlay-wrapper.dx-popup-wrapper.dx-header-filter-menu {
  .dx-overlay-content.dx-dropdowneditor-overlay {
    max-height: 250px;
    max-width: 200px;
  }

  .dx-popup-content {
    padding: 3px 2px 0;
  }

  .dx-popup-bottom.dx-toolbar .dx-toolbar-after {
    right: 37px;
  }

  .dx-popup-bottom.dx-toolbar {
    padding: 0;
    .dx-toolbar-item {
      padding: 5px 0;
    }
    .dx-button {
      min-width: 50px;
      .dx-button-content {
        padding: 7px 10px 8px;
      }
    }
  }

  .dx-dropdowneditor-overlay {
    padding: 5px;
    border-radius: 5px;

    .dx-list-select-all {
      border-bottom: 1px solid #ddd;
      display: flex;
    }
    .dx-list-item {
      border-top: 0;
    }
    .dx-checkbox-icon {
      width: 18px;
      height: 18px;
    }
    .dx-list-item-content {
      padding: 5px;
    }
  }

  .dx-checkbox-icon {
    border: 1px solid var(--color-text-weak);
  }

  .dx-checkbox-checked .dx-checkbox-icon {
    color: white;
    background: var(--color-system-success);
    border: 1px solid var(--color-system-success);
  }

  .dx-checkbox-indeterminate .dx-checkbox-icon::before {
    background-color: var(--color-system-success);
  }
}
