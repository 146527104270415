:root {
  --scrollbar-size: 6px;
  --scrollbar-track-background-color: transparent;
  --scrollbar-thumb-background-color: var(--color-background-medium);
}

::-webkit-scrollbar {
  height: var(--scrollbar-size);
  width: var(--scrollbar-size);
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-background-color, transparent);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-background-color);
}
