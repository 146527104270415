.dx-popup-normal {
  border-radius: var(--space-xs);
  padding: 15px 24px;

  @media screen and (width < 500px) {
    width: 90vw !important;
  }
}

.dx-popup-title {
  padding: 0 0 10px 10px !important;
}

.dx-popup-title .dx-closebutton .dx-icon {
  color: #707070;
}

.dx-popup-title.dx-toolbar .dx-toolbar-label {
  max-width: unset;
}

.dx-toolbar-label > div {
  color: var(--color-primary);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.125;
  padding: 0;
}

.dx-popup-content {
  padding: 20px 0 0 0;
  display: flex;
  flex-direction: column;
}

.dx-dropdowneditor-overlay {
  border-radius: 20px;
  padding: 20px 30px 0;
}

.dx-list-item-content:focus {
  background-color: var(--color-grayscale-50) !important;
}

.dx-list-item-content:hover {
  background-color: var(--color-grayscale-50) !important;
}

.dx-dropdownmenu-list {
  height: auto !important;
  padding: 0 !important;
}

.dx-calendar-navigator-previous-month.dx-button .dx-icon,
.dx-calendar-navigator-previous-view.dx-button .dx-icon,
.dx-calendar-navigator-next-month.dx-button .dx-icon,
.dx-calendar-navigator-next-view.dx-button .dx-icon {
  color: var(--color-icon-weak);
}

.dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content {
  color: var(--color-accent);
}

.dx-calendar-cell.dx-calendar-selected-date, .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today {
  box-shadow: inset 0 0 0 1000px var(--color-accent);
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected {
  background-color: var(--color-grayscale-50) !important;
}
