/* For use in src/lib/core/theming/_palette.scss */
:root {
  --color-primary: #175473;
  --color-primary-50: #e3eaee;
  --color-primary-50-rgb: 227, 234, 238;
  --color-primary-100: #b9ccd5;
  --color-primary-100-rgb: 185, 204, 213;
  --color-primary-200: #8baab9;
  --color-primary-200-rgb: 139, 170, 185;
  --color-primary-300: #5d879d;
  --color-primary-300-rgb: 93, 135, 157;
  --color-primary-400: #3a6e88;
  --color-primary-400-rgb: 58, 110, 136;
  --color-primary-500: #175473;
  --color-primary-500-rgb: 23, 84, 115;
  --color-primary-600: #144d6b;
  --color-primary-600-rgb: 20, 77, 107;
  --color-primary-700: #114360;
  --color-primary-700-rgb: 17, 67, 96;
  --color-primary-800: #0d3a56;
  --color-primary-800-rgb: 13, 58, 86;
  --color-primary-900: #072943;
  --color-primary-900-rgb: 7, 41, 67;
  --color-primary-A100: #7abeff;
  --color-primary-A100-rgb: 122, 190, 255;
  --color-primary-A200: #47a5ff;
  --color-primary-A200-rgb: 71, 165, 255;
  --color-primary-A400: #148cff;
  --color-primary-A400-rgb: 20, 140, 255;
  --color-primary-A700: #007ff9;
  --color-primary-A700-rgb: 0, 127, 249;
  --color-primary-contrast-50: #ffffff;
  --color-primary-contrast-50-rgb: 255, 255, 255;
  --color-primary-contrast-100: #ffffff;
  --color-primary-contrast-100-rgb: 255, 255, 255;
  --color-primary-contrast-200: #ffffff;
  --color-primary-contrast-200-rgb: 255, 255, 255;
  --color-primary-contrast-300: #ffffff;
  --color-primary-contrast-300-rgb: 255, 255, 255;
  --color-primary-contrast-400: #ffffff;
  --color-primary-contrast-400-rgb: 255, 255, 255;
  --color-primary-contrast-500: #ffffff;
  --color-primary-contrast-500-rgb: 255, 255, 255;
  --color-primary-contrast-600: #ffffff;
  --color-primary-contrast-600-rgb: 255, 255, 255;
  --color-primary-contrast-700: #ffffff;
  --color-primary-contrast-700-rgb: 255, 255, 255;
  --color-primary-contrast-800: #ffffff;
  --color-primary-contrast-800-rgb: 255, 255, 255;
  --color-primary-contrast-900: #ffffff;
  --color-primary-contrast-900-rgb: 255, 255, 255;
  --color-primary-contrast-A100: #ffffff;
  --color-primary-contrast-A100-rgb: 255, 255, 255;
  --color-primary-contrast-A200: #ffffff;
  --color-primary-contrast-A200-rgb: 255, 255, 255;
  --color-primary-contrast-A400: #ffffff;
  --color-primary-contrast-A400-rgb: 255, 255, 255;
  --color-primary-contrast-A700: #ffffff;
  --color-primary-contrast-A700-rgb: 255, 255, 255;
  --color-secondary: #3dbfb8;
  --color-secondary-50: #e0f8f7;
  --color-secondary-50-rgb: 224, 248, 247;
  --color-secondary-100: #b3eeea;
  --color-secondary-100-rgb: 179, 238, 234;
  --color-secondary-200: #80e3dd;
  --color-secondary-200-rgb: 128, 227, 221;
  --color-secondary-300: #4dd8cf;
  --color-secondary-300-rgb: 77, 216, 207;
  --color-secondary-400: #26cfc4;
  --color-secondary-400-rgb: 38, 207, 196;
  --color-secondary-500: #3dbfb8;
  --color-secondary-500-rgb: 61, 191, 184;
  --color-secondary-600: #00c1b3;
  --color-secondary-600-rgb: 0, 193, 179;
  --color-secondary-700: #00baab;
  --color-secondary-700-rgb: 0, 186, 171;
  --color-secondary-800: #00b3a3;
  --color-secondary-800-rgb: 0, 179, 163;
  --color-secondary-900: #00a694;
  --color-secondary-900-rgb: 0, 166, 148;
  --color-secondary-A100: #d0fff9;
  --color-secondary-A100-rgb: 208, 255, 249;
  --color-secondary-A200: #9dfff2;
  --color-secondary-A200-rgb: 157, 255, 242;
  --color-secondary-A400: #6affec;
  --color-secondary-A400-rgb: 106, 255, 236;
  --color-secondary-A700: #51ffe9;
  --color-secondary-A700-rgb: 81, 255, 233;
  --color-secondary-contrast-50: #ffffff;
  --color-secondary-contrast-50-rgb: 255, 255, 255;
  --color-secondary-contrast-100: #ffffff;
  --color-secondary-contrast-100-rgb: 255, 255, 255;
  --color-secondary-contrast-200: #ffffff;
  --color-secondary-contrast-200-rgb: 255, 255, 255;
  --color-secondary-contrast-300: #ffffff;
  --color-secondary-contrast-300-rgb: 255, 255, 255;
  --color-secondary-contrast-400: #ffffff;
  --color-secondary-contrast-400-rgb: 255, 255, 255;
  --color-secondary-contrast-500: #ffffff;
  --color-secondary-contrast-500-rgb: 255, 255, 255;
  --color-secondary-contrast-600: #ffffff;
  --color-secondary-contrast-600-rgb: 255, 255, 255;
  --color-secondary-contrast-700: #ffffff;
  --color-secondary-contrast-700-rgb: 255, 255, 255;
  --color-secondary-contrast-800: #ffffff;
  --color-secondary-contrast-800-rgb: 255, 255, 255;
  --color-secondary-contrast-900: #ffffff;
  --color-secondary-contrast-900-rgb: 255, 255, 255;
  --color-secondary-contrast-A100: #ffffff;
  --color-secondary-contrast-A100-rgb: 255, 255, 255;
  --color-secondary-contrast-A200: #ffffff;
  --color-secondary-contrast-A200-rgb: 255, 255, 255;
  --color-secondary-contrast-A400: #ffffff;
  --color-secondary-contrast-A400-rgb: 255, 255, 255;
  --color-secondary-contrast-A700: #ffffff;
  --color-secondary-contrast-A700-rgb: 255, 255, 255;
  --color-tertiary: #36468f;
  --color-tertiary-50: #e7e9f2;
  --color-tertiary-50-rgb: 231, 233, 242;
  --color-tertiary-100: #c3c8dd;
  --color-tertiary-100-rgb: 195, 200, 221;
  --color-tertiary-200: #9ba3c7;
  --color-tertiary-200-rgb: 155, 163, 199;
  --color-tertiary-300: #727eb1;
  --color-tertiary-300-rgb: 114, 126, 177;
  --color-tertiary-400: #5462a0;
  --color-tertiary-400-rgb: 84, 98, 160;
  --color-tertiary-500: #36468f;
  --color-tertiary-500-rgb: 54, 70, 143;
  --color-tertiary-600: #303f87;
  --color-tertiary-600-rgb: 48, 63, 135;
  --color-tertiary-700: #29377c;
  --color-tertiary-700-rgb: 41, 55, 124;
  --color-tertiary-800: #222f72;
  --color-tertiary-800-rgb: 34, 47, 114;
  --color-tertiary-900: #162060;
  --color-tertiary-900-rgb: 22, 32, 96;
  --color-tertiary-A100: #9aa6ff;
  --color-tertiary-A100-rgb: 154, 166, 255;
  --color-tertiary-A200: #6778ff;
  --color-tertiary-A200-rgb: 103, 120, 255;
  --color-tertiary-A400: #344bff;
  --color-tertiary-A400-rgb: 52, 75, 255;
  --color-tertiary-A700: #1a35ff;
  --color-tertiary-A700-rgb: 26, 53, 255;
  --color-tertiary-contrast-50: #ffffff;
  --color-tertiary-contrast-50-rgb: 255, 255, 255;
  --color-tertiary-contrast-100: #ffffff;
  --color-tertiary-contrast-100-rgb: 255, 255, 255;
  --color-tertiary-contrast-200: #ffffff;
  --color-tertiary-contrast-200-rgb: 255, 255, 255;
  --color-tertiary-contrast-300: #ffffff;
  --color-tertiary-contrast-300-rgb: 255, 255, 255;
  --color-tertiary-contrast-400: #ffffff;
  --color-tertiary-contrast-400-rgb: 255, 255, 255;
  --color-tertiary-contrast-500: #ffffff;
  --color-tertiary-contrast-500-rgb: 255, 255, 255;
  --color-tertiary-contrast-600: #ffffff;
  --color-tertiary-contrast-600-rgb: 255, 255, 255;
  --color-tertiary-contrast-700: #ffffff;
  --color-tertiary-contrast-700-rgb: 255, 255, 255;
  --color-tertiary-contrast-800: #ffffff;
  --color-tertiary-contrast-800-rgb: 255, 255, 255;
  --color-tertiary-contrast-900: #ffffff;
  --color-tertiary-contrast-900-rgb: 255, 255, 255;
  --color-tertiary-contrast-A100: #ffffff;
  --color-tertiary-contrast-A100-rgb: 255, 255, 255;
  --color-tertiary-contrast-A200: #ffffff;
  --color-tertiary-contrast-A200-rgb: 255, 255, 255;
  --color-tertiary-contrast-A400: #ffffff;
  --color-tertiary-contrast-A400-rgb: 255, 255, 255;
  --color-tertiary-contrast-A700: #ffffff;
  --color-tertiary-contrast-A700-rgb: 255, 255, 255;
  --color-quaternary: #fea223;
  --color-quaternary-50: #fff4e5;
  --color-quaternary-50-rgb: 255, 244, 229;
  --color-quaternary-100: #ffe3bd;
  --color-quaternary-100-rgb: 255, 227, 189;
  --color-quaternary-200: #ffd191;
  --color-quaternary-200-rgb: 255, 209, 145;
  --color-quaternary-300: #febe65;
  --color-quaternary-300-rgb: 254, 190, 101;
  --color-quaternary-400: #feb044;
  --color-quaternary-400-rgb: 254, 176, 68;
  --color-quaternary-500: #fea223;
  --color-quaternary-500-rgb: 254, 162, 35;
  --color-quaternary-600: #fe9a1f;
  --color-quaternary-600-rgb: 254, 154, 31;
  --color-quaternary-700: #fe901a;
  --color-quaternary-700-rgb: 254, 144, 26;
  --color-quaternary-800: #fe8615;
  --color-quaternary-800-rgb: 254, 134, 21;
  --color-quaternary-900: #fd750c;
  --color-quaternary-900-rgb: 253, 117, 12;
  --color-quaternary-A100: #ffffff;
  --color-quaternary-A100-rgb: 255, 255, 255;
  --color-quaternary-A200: #fff8f4;
  --color-quaternary-A200-rgb: 255, 248, 244;
  --color-quaternary-A400: #ffd9c1;
  --color-quaternary-A400-rgb: 255, 217, 193;
  --color-quaternary-A700: #ffcaa7;
  --color-quaternary-A700-rgb: 255, 202, 167;
  --color-quaternary-contrast-50: #ffffff;
  --color-quaternary-contrast-50-rgb: 255, 255, 255;
  --color-quaternary-contrast-100: #ffffff;
  --color-quaternary-contrast-100-rgb: 255, 255, 255;
  --color-quaternary-contrast-200: #ffffff;
  --color-quaternary-contrast-200-rgb: 255, 255, 255;
  --color-quaternary-contrast-300: #ffffff;
  --color-quaternary-contrast-300-rgb: 255, 255, 255;
  --color-quaternary-contrast-400: #ffffff;
  --color-quaternary-contrast-400-rgb: 255, 255, 255;
  --color-quaternary-contrast-500: #ffffff;
  --color-quaternary-contrast-500-rgb: 255, 255, 255;
  --color-quaternary-contrast-600: #ffffff;
  --color-quaternary-contrast-600-rgb: 255, 255, 255;
  --color-quaternary-contrast-700: #ffffff;
  --color-quaternary-contrast-700-rgb: 255, 255, 255;
  --color-quaternary-contrast-800: #ffffff;
  --color-quaternary-contrast-800-rgb: 255, 255, 255;
  --color-quaternary-contrast-900: #ffffff;
  --color-quaternary-contrast-900-rgb: 255, 255, 255;
  --color-quaternary-contrast-A100: #ffffff;
  --color-quaternary-contrast-A100-rgb: 255, 255, 255;
  --color-quaternary-contrast-A200: #ffffff;
  --color-quaternary-contrast-A200-rgb: 255, 255, 255;
  --color-quaternary-contrast-A400: #ffffff;
  --color-quaternary-contrast-A400-rgb: 255, 255, 255;
  --color-quaternary-contrast-A700: #ffffff;
  --color-quaternary-contrast-A700-rgb: 255, 255, 255;
  --color-accent: #3dbfb8;
  --color-accent-50: #e0f8f7;
  --color-accent-50-rgb: 224, 248, 247;
  --color-accent-100: #b3eeea;
  --color-accent-100-rgb: 179, 238, 234;
  --color-accent-200: #80e3dd;
  --color-accent-200-rgb: 128, 227, 221;
  --color-accent-300: #4dd8cf;
  --color-accent-300-rgb: 77, 216, 207;
  --color-accent-400: #26cfc4;
  --color-accent-400-rgb: 38, 207, 196;
  --color-accent-500: #3dbfb8;
  --color-accent-500-rgb: 61, 191, 184;
  --color-accent-600: #00c1b3;
  --color-accent-600-rgb: 0, 193, 179;
  --color-accent-700: #00baab;
  --color-accent-700-rgb: 0, 186, 171;
  --color-accent-800: #00b3a3;
  --color-accent-800-rgb: 0, 179, 163;
  --color-accent-900: #00a694;
  --color-accent-900-rgb: 0, 166, 148;
  --color-accent-A100: #d0fff9;
  --color-accent-A100-rgb: 208, 255, 249;
  --color-accent-A200: #9dfff2;
  --color-accent-A200-rgb: 157, 255, 242;
  --color-accent-A400: #6affec;
  --color-accent-A400-rgb: 106, 255, 236;
  --color-accent-A700: #51ffe9;
  --color-accent-A700-rgb: 81, 255, 233;
  --color-accent-contrast-50: #ffffff;
  --color-accent-contrast-50-rgb: 255, 255, 255;
  --color-accent-contrast-100: #ffffff;
  --color-accent-contrast-100-rgb: 255, 255, 255;
  --color-accent-contrast-200: #ffffff;
  --color-accent-contrast-200-rgb: 255, 255, 255;
  --color-accent-contrast-300: #ffffff;
  --color-accent-contrast-300-rgb: 255, 255, 255;
  --color-accent-contrast-400: #ffffff;
  --color-accent-contrast-400-rgb: 255, 255, 255;
  --color-accent-contrast-500: #ffffff;
  --color-accent-contrast-500-rgb: 255, 255, 255;
  --color-accent-contrast-600: #ffffff;
  --color-accent-contrast-600-rgb: 255, 255, 255;
  --color-accent-contrast-700: #ffffff;
  --color-accent-contrast-700-rgb: 255, 255, 255;
  --color-accent-contrast-800: #ffffff;
  --color-accent-contrast-800-rgb: 255, 255, 255;
  --color-accent-contrast-900: #ffffff;
  --color-accent-contrast-900-rgb: 255, 255, 255;
  --color-accent-contrast-A100: #ffffff;
  --color-accent-contrast-A100-rgb: 255, 255, 255;
  --color-accent-contrast-A200: #ffffff;
  --color-accent-contrast-A200-rgb: 255, 255, 255;
  --color-accent-contrast-A400: #ffffff;
  --color-accent-contrast-A400-rgb: 255, 255, 255;
  --color-accent-contrast-A700: #ffffff;
  --color-accent-contrast-A700-rgb: 255, 255, 255;
  --color-grayscale: #707070;
  --color-grayscale-50: #f2f2f2;
  --color-grayscale-50-rgb: 242, 242, 242;
  --color-grayscale-100: #eeeeee;
  --color-grayscale-100-rgb: 238, 238, 238;
  --color-grayscale-200: #d1d1d1;
  --color-grayscale-200-rgb: 209, 209, 209;
  --color-grayscale-300: #a6a6a6;
  --color-grayscale-300-rgb: 166, 166, 166;
  --color-grayscale-400: #868686;
  --color-grayscale-400-rgb: 134, 134, 134;
  --color-grayscale-500: #707070;
  --color-grayscale-500-rgb: 112, 112, 112;
  --color-grayscale-600: #686868;
  --color-grayscale-600-rgb: 104, 104, 104;
  --color-grayscale-700: #404040;
  --color-grayscale-700-rgb: 64, 64, 64;
  --color-grayscale-800: #333333;
  --color-grayscale-800-rgb: 51, 51, 51;
  --color-grayscale-900: #262626;
  --color-grayscale-900-rgb: 38, 38, 38;
  --color-grayscale-A100: #FFFFFF;
  --color-grayscale-A100-rgb: 255, 255, 255;
  --color-grayscale-A200: #222222;
  --color-grayscale-A200-rgb: 34, 34, 34;
  --color-grayscale-A400: #111111;
  --color-grayscale-A400-rgb: 17, 17, 17;
  --color-grayscale-A700: #000000;
  --color-grayscale-A700-rgb: 0, 0, 0;
  --color-grayscale-contrast-50: #ffffff;
  --color-grayscale-contrast-50-rgb: 255, 255, 255;
  --color-grayscale-contrast-100: #ffffff;
  --color-grayscale-contrast-100-rgb: 255, 255, 255;
  --color-grayscale-contrast-200: #ffffff;
  --color-grayscale-contrast-200-rgb: 255, 255, 255;
  --color-grayscale-contrast-300: #ffffff;
  --color-grayscale-contrast-300-rgb: 255, 255, 255;
  --color-grayscale-contrast-400: #ffffff;
  --color-grayscale-contrast-400-rgb: 255, 255, 255;
  --color-grayscale-contrast-500: #ffffff;
  --color-grayscale-contrast-500-rgb: 255, 255, 255;
  --color-grayscale-contrast-600: #ffffff;
  --color-grayscale-contrast-600-rgb: 255, 255, 255;
  --color-grayscale-contrast-700: #ffffff;
  --color-grayscale-contrast-700-rgb: 255, 255, 255;
  --color-grayscale-contrast-800: #ffffff;
  --color-grayscale-contrast-800-rgb: 255, 255, 255;
  --color-grayscale-contrast-900: #ffffff;
  --color-grayscale-contrast-900-rgb: 255, 255, 255;
  --color-grayscale-contrast-A100: #ffffff;
  --color-grayscale-contrast-A100-rgb: 255, 255, 255;
  --color-grayscale-contrast-A200: #ffffff;
  --color-grayscale-contrast-A200-rgb: 255, 255, 255;
  --color-grayscale-contrast-A400: #ffffff;
  --color-grayscale-contrast-A400-rgb: 255, 255, 255;
  --color-grayscale-contrast-A700: #ffffff;
  --color-grayscale-contrast-A700-rgb: 255, 255, 255;
  --color-cyan: #509da9;
  --color-cyan-50: #eef6f7;
  --color-cyan-50-rgb: 238, 246, 247;
  --color-cyan-100: #cbe3e6;
  --color-cyan-100-rgb: 203, 227, 230;
  --color-cyan-200: #a8d0d6;
  --color-cyan-200-rgb: 168, 208, 214;
  --color-cyan-300: #86bdc6;
  --color-cyan-300-rgb: 134, 189, 198;
  --color-cyan-400: #63aab5;
  --color-cyan-400-rgb: 99, 170, 181;
  --color-cyan-500: #509da9;
  --color-cyan-500-rgb: 80, 157, 169;
  --color-cyan-600: #4a919c;
  --color-cyan-600-rgb: 74, 145, 156;
  --color-cyan-700: #397179;
  --color-cyan-700-rgb: 57, 113, 121;
  --color-cyan-contrast-50: #ffffff;
  --color-cyan-contrast-50-rgb: 255, 255, 255;
  --color-cyan-contrast-100: #ffffff;
  --color-cyan-contrast-100-rgb: 255, 255, 255;
  --color-cyan-contrast-200: #ffffff;
  --color-cyan-contrast-200-rgb: 255, 255, 255;
  --color-cyan-contrast-300: #ffffff;
  --color-cyan-contrast-300-rgb: 255, 255, 255;
  --color-cyan-contrast-400: #ffffff;
  --color-cyan-contrast-400-rgb: 255, 255, 255;
  --color-cyan-contrast-500: #ffffff;
  --color-cyan-contrast-500-rgb: 255, 255, 255;
  --color-cyan-contrast-600: #ffffff;
  --color-cyan-contrast-600-rgb: 255, 255, 255;
  --color-cyan-contrast-700: #ffffff;
  --color-cyan-contrast-700-rgb: 255, 255, 255;
  --color-cyan-contrast-800: #ffffff;
  --color-cyan-contrast-800-rgb: 255, 255, 255;
  --color-cyan-contrast-900: #ffffff;
  --color-cyan-contrast-900-rgb: 255, 255, 255;
  --color-cyan-contrast-A100: #ffffff;
  --color-cyan-contrast-A100-rgb: 255, 255, 255;
  --color-cyan-contrast-A200: #ffffff;
  --color-cyan-contrast-A200-rgb: 255, 255, 255;
  --color-cyan-contrast-A400: #ffffff;
  --color-cyan-contrast-A400-rgb: 255, 255, 255;
  --color-cyan-contrast-A700: #ffffff;
  --color-cyan-contrast-A700-rgb: 255, 255, 255;
  --color-green: #2d836a;
  --color-green-50: #e6f0ed;
  --color-green-50-rgb: 230, 240, 237;
  --color-green-100: #c0dad2;
  --color-green-100-rgb: 192, 218, 210;
  --color-green-200: #96c1b5;
  --color-green-200-rgb: 150, 193, 181;
  --color-green-300: #6ca897;
  --color-green-300-rgb: 108, 168, 151;
  --color-green-400: #4d9680;
  --color-green-400-rgb: 77, 150, 128;
  --color-green-500: #2d836a;
  --color-green-500-rgb: 45, 131, 106;
  --color-green-600: #287b62;
  --color-green-600-rgb: 40, 123, 98;
  --color-green-700: #227057;
  --color-green-700-rgb: 34, 112, 87;
  --color-green-800: #1c664d;
  --color-green-800-rgb: 28, 102, 77;
  --color-green-900: #11533c;
  --color-green-900-rgb: 17, 83, 60;
  --color-green-A100: #8bffd3;
  --color-green-A100-rgb: 139, 255, 211;
  --color-green-A200: #58ffbf;
  --color-green-A200-rgb: 88, 255, 191;
  --color-green-A400: #25ffac;
  --color-green-A400-rgb: 37, 255, 172;
  --color-green-A700: #0cffa2;
  --color-green-A700-rgb: 12, 255, 162;
  --color-green-contrast-50: #ffffff;
  --color-green-contrast-50-rgb: 255, 255, 255;
  --color-green-contrast-100: #ffffff;
  --color-green-contrast-100-rgb: 255, 255, 255;
  --color-green-contrast-200: #ffffff;
  --color-green-contrast-200-rgb: 255, 255, 255;
  --color-green-contrast-300: #ffffff;
  --color-green-contrast-300-rgb: 255, 255, 255;
  --color-green-contrast-400: #ffffff;
  --color-green-contrast-400-rgb: 255, 255, 255;
  --color-green-contrast-500: #ffffff;
  --color-green-contrast-500-rgb: 255, 255, 255;
  --color-green-contrast-600: #ffffff;
  --color-green-contrast-600-rgb: 255, 255, 255;
  --color-green-contrast-700: #ffffff;
  --color-green-contrast-700-rgb: 255, 255, 255;
  --color-green-contrast-800: #ffffff;
  --color-green-contrast-800-rgb: 255, 255, 255;
  --color-green-contrast-900: #ffffff;
  --color-green-contrast-900-rgb: 255, 255, 255;
  --color-green-contrast-A100: #ffffff;
  --color-green-contrast-A100-rgb: 255, 255, 255;
  --color-green-contrast-A200: #ffffff;
  --color-green-contrast-A200-rgb: 255, 255, 255;
  --color-green-contrast-A400: #ffffff;
  --color-green-contrast-A400-rgb: 255, 255, 255;
  --color-green-contrast-A700: #ffffff;
  --color-green-contrast-A700-rgb: 255, 255, 255;
  --color-system-info: #0c6ccc;
  --color-system-info-50: #e2edf9;
  --color-system-info-50-rgb: 226, 237, 249;
  --color-system-info-100: #b6d3f0;
  --color-system-info-100-rgb: 182, 211, 240;
  --color-system-info-200: #86b6e6;
  --color-system-info-200-rgb: 134, 182, 230;
  --color-system-info-300: #5598db;
  --color-system-info-300-rgb: 85, 152, 219;
  --color-system-info-400: #3082d4;
  --color-system-info-400-rgb: 48, 130, 212;
  --color-system-info-500: #0c6ccc;
  --color-system-info-500-rgb: 12, 108, 204;
  --color-system-info-600: #0a64c7;
  --color-system-info-600-rgb: 10, 100, 199;
  --color-system-info-700: #0859c0;
  --color-system-info-700-rgb: 8, 89, 192;
  --color-system-info-800: #064fb9;
  --color-system-info-800-rgb: 6, 79, 185;
  --color-system-info-900: #033dad;
  --color-system-info-900-rgb: 3, 61, 173;
  --color-system-info-A100: #d7e2ff;
  --color-system-info-A100-rgb: 215, 226, 255;
  --color-system-info-A200: #a4bdff;
  --color-system-info-A200-rgb: 164, 189, 255;
  --color-system-info-A400: #7198ff;
  --color-system-info-A400-rgb: 113, 152, 255;
  --color-system-info-A700: #5886ff;
  --color-system-info-A700-rgb: 88, 134, 255;
  --color-system-info-contrast-50: #ffffff;
  --color-system-info-contrast-50-rgb: 255, 255, 255;
  --color-system-info-contrast-100: #ffffff;
  --color-system-info-contrast-100-rgb: 255, 255, 255;
  --color-system-info-contrast-200: #ffffff;
  --color-system-info-contrast-200-rgb: 255, 255, 255;
  --color-system-info-contrast-300: #ffffff;
  --color-system-info-contrast-300-rgb: 255, 255, 255;
  --color-system-info-contrast-400: #ffffff;
  --color-system-info-contrast-400-rgb: 255, 255, 255;
  --color-system-info-contrast-500: #ffffff;
  --color-system-info-contrast-500-rgb: 255, 255, 255;
  --color-system-info-contrast-600: #ffffff;
  --color-system-info-contrast-600-rgb: 255, 255, 255;
  --color-system-info-contrast-700: #ffffff;
  --color-system-info-contrast-700-rgb: 255, 255, 255;
  --color-system-info-contrast-800: #ffffff;
  --color-system-info-contrast-800-rgb: 255, 255, 255;
  --color-system-info-contrast-900: #ffffff;
  --color-system-info-contrast-900-rgb: 255, 255, 255;
  --color-system-info-contrast-A100: #ffffff;
  --color-system-info-contrast-A100-rgb: 255, 255, 255;
  --color-system-info-contrast-A200: #ffffff;
  --color-system-info-contrast-A200-rgb: 255, 255, 255;
  --color-system-info-contrast-A400: #ffffff;
  --color-system-info-contrast-A400-rgb: 255, 255, 255;
  --color-system-info-contrast-A700: #ffffff;
  --color-system-info-contrast-A700-rgb: 255, 255, 255;
  --color-system-error: #ff5251;
  --color-system-error-50: #ffeaea;
  --color-system-error-50-rgb: 255, 234, 234;
  --color-system-error-100: #ffcbcb;
  --color-system-error-100-rgb: 255, 203, 203;
  --color-system-error-200: #ffa9a8;
  --color-system-error-200-rgb: 255, 169, 168;
  --color-system-error-300: #ff8685;
  --color-system-error-300-rgb: 255, 134, 133;
  --color-system-error-400: #ff6c6b;
  --color-system-error-400-rgb: 255, 108, 107;
  --color-system-error-500: #ff5251;
  --color-system-error-500-rgb: 255, 82, 81;
  --color-system-error-600: #ff4b4a;
  --color-system-error-600-rgb: 255, 75, 74;
  --color-system-error-700: #ff4140;
  --color-system-error-700-rgb: 255, 65, 64;
  --color-system-error-800: #ff3837;
  --color-system-error-800-rgb: 255, 56, 55;
  --color-system-error-900: #ff2827;
  --color-system-error-900-rgb: 255, 40, 39;
  --color-system-error-A100: #ffffff;
  --color-system-error-A100-rgb: 255, 255, 255;
  --color-system-error-A200: #ffffff;
  --color-system-error-A200-rgb: 255, 255, 255;
  --color-system-error-A400: #ffffff;
  --color-system-error-A400-rgb: 255, 255, 255;
  --color-system-error-A700: #ffffff;
  --color-system-error-A700-rgb: 255, 255, 255;
  --color-system-error-contrast-50: #ffffff;
  --color-system-error-contrast-50-rgb: 255, 255, 255;
  --color-system-error-contrast-100: #ffffff;
  --color-system-error-contrast-100-rgb: 255, 255, 255;
  --color-system-error-contrast-200: #ffffff;
  --color-system-error-contrast-200-rgb: 255, 255, 255;
  --color-system-error-contrast-300: #ffffff;
  --color-system-error-contrast-300-rgb: 255, 255, 255;
  --color-system-error-contrast-400: #ffffff;
  --color-system-error-contrast-400-rgb: 255, 255, 255;
  --color-system-error-contrast-500: #ffffff;
  --color-system-error-contrast-500-rgb: 255, 255, 255;
  --color-system-error-contrast-600: #ffffff;
  --color-system-error-contrast-600-rgb: 255, 255, 255;
  --color-system-error-contrast-700: #ffffff;
  --color-system-error-contrast-700-rgb: 255, 255, 255;
  --color-system-error-contrast-800: #ffffff;
  --color-system-error-contrast-800-rgb: 255, 255, 255;
  --color-system-error-contrast-900: #ffffff;
  --color-system-error-contrast-900-rgb: 255, 255, 255;
  --color-system-error-contrast-A100: #ffffff;
  --color-system-error-contrast-A100-rgb: 255, 255, 255;
  --color-system-error-contrast-A200: #ffffff;
  --color-system-error-contrast-A200-rgb: 255, 255, 255;
  --color-system-error-contrast-A400: #ffffff;
  --color-system-error-contrast-A400-rgb: 255, 255, 255;
  --color-system-error-contrast-A700: #ffffff;
  --color-system-error-contrast-A700-rgb: 255, 255, 255;
  --color-system-success: #00ad43;
  --color-system-success-50: #e6f2e6;
  --color-system-success-50-rgb: 230, 242, 230;
  --color-system-success-100: #b3e6c7;
  --color-system-success-100-rgb: 179, 230, 199;
  --color-system-success-200: #80d6a1;
  --color-system-success-200-rgb: 128, 214, 161;
  --color-system-success-300: #4dc67b;
  --color-system-success-300-rgb: 77, 198, 123;
  --color-system-success-400: #26b95f;
  --color-system-success-400-rgb: 38, 185, 95;
  --color-system-success-500: #00ad43;
  --color-system-success-500-rgb: 0, 173, 67;
  --color-system-success-600: #00a63d;
  --color-system-success-600-rgb: 0, 166, 61;
  --color-system-success-700: #009c34;
  --color-system-success-700-rgb: 0, 156, 52;
  --color-system-success-800: #00932c;
  --color-system-success-800-rgb: 0, 147, 44;
  --color-system-success-900: #00831e;
  --color-system-success-900-rgb: 0, 131, 30;
  --color-system-success-A100: #b0ffbb;
  --color-system-success-A100-rgb: 176, 255, 187;
  --color-system-success-A200: #7dff90;
  --color-system-success-A200-rgb: 125, 255, 144;
  --color-system-success-A400: #4aff64;
  --color-system-success-A400-rgb: 74, 255, 100;
  --color-system-success-A700: #30ff4e;
  --color-system-success-A700-rgb: 48, 255, 78;
  --color-system-success-contrast-50: #ffffff;
  --color-system-success-contrast-50-rgb: 255, 255, 255;
  --color-system-success-contrast-100: #ffffff;
  --color-system-success-contrast-100-rgb: 255, 255, 255;
  --color-system-success-contrast-200: #ffffff;
  --color-system-success-contrast-200-rgb: 255, 255, 255;
  --color-system-success-contrast-300: #ffffff;
  --color-system-success-contrast-300-rgb: 255, 255, 255;
  --color-system-success-contrast-400: #ffffff;
  --color-system-success-contrast-400-rgb: 255, 255, 255;
  --color-system-success-contrast-500: #ffffff;
  --color-system-success-contrast-500-rgb: 255, 255, 255;
  --color-system-success-contrast-600: #ffffff;
  --color-system-success-contrast-600-rgb: 255, 255, 255;
  --color-system-success-contrast-700: #ffffff;
  --color-system-success-contrast-700-rgb: 255, 255, 255;
  --color-system-success-contrast-800: #ffffff;
  --color-system-success-contrast-800-rgb: 255, 255, 255;
  --color-system-success-contrast-900: #ffffff;
  --color-system-success-contrast-900-rgb: 255, 255, 255;
  --color-system-success-contrast-A100: #ffffff;
  --color-system-success-contrast-A100-rgb: 255, 255, 255;
  --color-system-success-contrast-A200: #ffffff;
  --color-system-success-contrast-A200-rgb: 255, 255, 255;
  --color-system-success-contrast-A400: #ffffff;
  --color-system-success-contrast-A400-rgb: 255, 255, 255;
  --color-system-success-contrast-A700: #ffffff;
  --color-system-success-contrast-A700-rgb: 255, 255, 255;
  --color-system-warning: #ffc107;
  --color-system-warning-50: #fff8e1;
  --color-system-warning-50-rgb: 255, 248, 225;
  --color-system-warning-100: #ffecb5;
  --color-system-warning-100-rgb: 255, 236, 181;
  --color-system-warning-200: #ffe083;
  --color-system-warning-200-rgb: 255, 224, 131;
  --color-system-warning-300: #ffd451;
  --color-system-warning-300-rgb: 255, 212, 81;
  --color-system-warning-400: #ffca2c;
  --color-system-warning-400-rgb: 255, 202, 44;
  --color-system-warning-500: #ffc107;
  --color-system-warning-500-rgb: 255, 193, 7;
  --color-system-warning-600: #ffbb06;
  --color-system-warning-600-rgb: 255, 187, 6;
  --color-system-warning-700: #ffb305;
  --color-system-warning-700-rgb: 255, 179, 5;
  --color-system-warning-800: #ffab04;
  --color-system-warning-800-rgb: 255, 171, 4;
  --color-system-warning-900: #ff9e02;
  --color-system-warning-900-rgb: 255, 158, 2;
  --color-system-warning-A100: #ffffff;
  --color-system-warning-A100-rgb: 255, 255, 255;
  --color-system-warning-A200: #fffaf2;
  --color-system-warning-A200-rgb: 255, 250, 242;
  --color-system-warning-A400: #ffe4bf;
  --color-system-warning-A400-rgb: 255, 228, 191;
  --color-system-warning-A700: #ffd9a6;
  --color-system-warning-A700-rgb: 255, 217, 166;
  --color-system-warning-contrast-50: #000000;
  --color-system-warning-contrast-50-rgb: 0, 0, 0;
  --color-system-warning-contrast-100: #000000;
  --color-system-warning-contrast-100-rgb: 0, 0, 0;
  --color-system-warning-contrast-200: #000000;
  --color-system-warning-contrast-200-rgb: 0, 0, 0;
  --color-system-warning-contrast-300: #000000;
  --color-system-warning-contrast-300-rgb: 0, 0, 0;
  --color-system-warning-contrast-400: #000000;
  --color-system-warning-contrast-400-rgb: 0, 0, 0;
  --color-system-warning-contrast-500: #000000;
  --color-system-warning-contrast-500-rgb: 0, 0, 0;
  --color-system-warning-contrast-600: #000000;
  --color-system-warning-contrast-600-rgb: 0, 0, 0;
  --color-system-warning-contrast-700: #000000;
  --color-system-warning-contrast-700-rgb: 0, 0, 0;
  --color-system-warning-contrast-800: #000000;
  --color-system-warning-contrast-800-rgb: 0, 0, 0;
  --color-system-warning-contrast-900: #000000;
  --color-system-warning-contrast-900-rgb: 0, 0, 0;
  --color-system-warning-contrast-A100: #000000;
  --color-system-warning-contrast-A100-rgb: 0, 0, 0;
  --color-system-warning-contrast-A200: #000000;
  --color-system-warning-contrast-A200-rgb: 0, 0, 0;
  --color-system-warning-contrast-A400: #000000;
  --color-system-warning-contrast-A400-rgb: 0, 0, 0;
  --color-system-warning-contrast-A700: #000000;
  --color-system-warning-contrast-A700-rgb: 0, 0, 0;
  --color-system-inactive: #707070;
  --color-red: #ff0200;
  --color-svg-default: #707070;
  --color-white: #ffffff;
  --color-transparent: transparent;
  --color-text-normal: #333333;
  --color-text-normal-inverted: #FFFFFF;
  --color-text-weak: #707070;
  --color-text-weaker-medium: #868686;
  --color-text-weaker: #a6a6a6;
  --color-text-strong: #000000;
  --color-link-normal: #0c6ccc;
  --color-link-hover: #86b6e6;
  --color-background-primary-weak: var(--color-primary-100);
  --color-background-normal: var(--color-grayscale-A100);
  --color-background-normal-rgb: var(--color-grayscale-A100-rgb);
  --color-background-normal-inverted: #333333;
  --color-background-strong: var(--color-grayscale);
  --color-background-medium: #d1d1d1;
  --color-background-weak: #f2f2f2;
  --color-background-info-weak: #e2edf9;
  --color-border-strongest: #000000;
  --color-border-strong: #333333;
  --color-border-normal: #d1d1d1;
  --color-border-normal-inverted: #FFFFFF;
  --color-border-weak: #f2f2f2;
  --color-border-transparent: transparent;
  --color-icon-primary: var(--color-primary);
  --color-icon-accent: var(--color-accent);
  --color-icon-normal: var(--color-grayscale-500);
  --color-icon-weak: var(--color-grayscale-400);
  --color-icon-weak-hover: var(--color-grayscale-300);
  --color-icon-inverted: var(--color-text-normal-inverted);
  --color-icon-text-normal: var(--color-text-normal);
  --color-icon-text-normal-inverted: var(--color-text-normal-inverted);
  --color-icon-text-weak: var(--color-text-weak);
  --color-form-input-border-normal: #333333;
  --color-form-input-border-hover: #333333;
  --color-form-input-border-focus: #333333;
  --color-form-input-border-disabled: #333333;
  --color-form-input-border-error: #333333;
  --color-form-input-border-success: #333333;
  --color-bui-button-background-primary: #175473;
  --color-bui-button-background-primary-hover: #8baab9;
  --color-bui-button-background-secondary: #3dbfb8;
  --color-bui-button-background-secondary-hover: #80e3dd;
  --color-bui-button-background-accent: #3dbfb8;
  --color-bui-button-background-accent-hover: #80e3dd;
  --color-bui-button-background-error: #ff5251;
  --color-bui-button-background-error-hover: #ffa9a8;
  --color-bui-button-background-normal: #333333;
  --color-bui-button-background-normal-hover: #707070;
  --color-bui-button-background-weak: #f2f2f2;
  --color-bui-button-background-weak-hover: #d1d1d1;
  --color-bui-button-background-inverted: #FFFFFF;
  --color-bui-button-background-inverted-hover: #f2f2f2;
  --color-bui-button-background-transparent: transparent;
  --color-bui-button-text-primary: #175473;
  --color-bui-button-text-primary-hover: #8baab9;
  --color-bui-button-text-accent: #3dbfb8;
  --color-bui-button-text-accent-hover: #80e3dd;
  --color-bui-button-text-tertiary: #36468f;
  --color-bui-button-text-tertiary-hover: #9ba3c7;
  --color-bui-button-text-link: #0c6ccc;
  --color-bui-button-text-link-hover: #86b6e6;
  --color-bui-button-text-normal: #707070;
  --color-bui-button-text-normal-hover: #707070;
  --color-bui-button-text-weak: #707070;
  --color-bui-button-text-weak-hover: #d1d1d1;
  --color-bui-button-text-inverted: #FFFFFF;
  --color-bui-button-text-inverted-hover: #f2f2f2;
  --color-bui-button-text-status-success: var(--color-system-success);
  --color-bui-button-text-status-success-hover: var(--color-system-success-200);
  --color-bui-button-text-status-error: var(--color-system-error);
  --color-bui-button-text-status-error-hover: var(--color-system-error-200);
  --color-bui-button-icon-weak: var(--color-grayscale-400);
  --color-bui-button-icon-weak-hover: var(--color-grayscale-400);
  --color-bui-button-icon-success: var(--color-system-success-900);
  --color-bui-button-icon-success-hover: var(--color-system-success-900);
  --color-table-head-row-background-normal: #b9ccd5;
  --color-table-body-row-background: #FFFFFF;
  --color-table-body-row-background-selected: #e6f2e6;
  --gradient-1: linear-gradient(90deg, #59AFB5, #306080);
  --gradient-primary: linear-gradient(90deg, var(--color-primary-400), var(--color-primary-600));
  --gradient-secondary: linear-gradient(90deg, var(--color-secondary-400), var(--color-secondary-600));
  --gradient-tertiary: linear-gradient(90deg, var(--color-tertiary-400), var(--color-tertiary-600));
  --gradient-accent: linear-gradient(90deg, var(--color-accent-400), var(--color-accent-600));
  --gradient-grayscale: linear-gradient(90deg, var(--color-grayscale-400), var(--color-grayscale-600));
  --gradient-system-info: linear-gradient(90deg, var(--color-system-info-400), var(--color-system-info-600));
  --gradient-system-error: linear-gradient(90deg, var(--color-system-error-400), var(--color-system-error-600));
  --gradient-system-success: linear-gradient(90deg, var(--color-system-success-400), var(--color-system-success-600));
  --gradient-system-warning: linear-gradient(90deg, var(--color-system-warning-400), var(--color-system-warning-600));
}

.theme-gray {
  --color-primary: #707070;
  --color-primary-50: #f2f2f2;
  --color-primary-50-rgb: 242, 242, 242;
  --color-primary-100: #eeeeee;
  --color-primary-100-rgb: 238, 238, 238;
  --color-primary-200: #d1d1d1;
  --color-primary-200-rgb: 209, 209, 209;
  --color-primary-300: #a6a6a6;
  --color-primary-300-rgb: 166, 166, 166;
  --color-primary-400: #868686;
  --color-primary-400-rgb: 134, 134, 134;
  --color-primary-500: #707070;
  --color-primary-500-rgb: 112, 112, 112;
  --color-primary-600: #686868;
  --color-primary-600-rgb: 104, 104, 104;
  --color-primary-700: #404040;
  --color-primary-700-rgb: 64, 64, 64;
  --color-primary-800: #333333;
  --color-primary-800-rgb: 51, 51, 51;
  --color-primary-900: #262626;
  --color-primary-900-rgb: 38, 38, 38;
  --color-primary-A100: #FFFFFF;
  --color-primary-A100-rgb: 255, 255, 255;
  --color-primary-A200: #222222;
  --color-primary-A200-rgb: 34, 34, 34;
  --color-primary-A400: #111111;
  --color-primary-A400-rgb: 17, 17, 17;
  --color-primary-A700: #000000;
  --color-primary-A700-rgb: 0, 0, 0;
  --color-primary-contrast-50: #ffffff;
  --color-primary-contrast-50-rgb: 255, 255, 255;
  --color-primary-contrast-100: #ffffff;
  --color-primary-contrast-100-rgb: 255, 255, 255;
  --color-primary-contrast-200: #ffffff;
  --color-primary-contrast-200-rgb: 255, 255, 255;
  --color-primary-contrast-300: #ffffff;
  --color-primary-contrast-300-rgb: 255, 255, 255;
  --color-primary-contrast-400: #ffffff;
  --color-primary-contrast-400-rgb: 255, 255, 255;
  --color-primary-contrast-500: #ffffff;
  --color-primary-contrast-500-rgb: 255, 255, 255;
  --color-primary-contrast-600: #ffffff;
  --color-primary-contrast-600-rgb: 255, 255, 255;
  --color-primary-contrast-700: #ffffff;
  --color-primary-contrast-700-rgb: 255, 255, 255;
  --color-primary-contrast-800: #ffffff;
  --color-primary-contrast-800-rgb: 255, 255, 255;
  --color-primary-contrast-900: #ffffff;
  --color-primary-contrast-900-rgb: 255, 255, 255;
  --color-primary-contrast-A100: #ffffff;
  --color-primary-contrast-A100-rgb: 255, 255, 255;
  --color-primary-contrast-A200: #ffffff;
  --color-primary-contrast-A200-rgb: 255, 255, 255;
  --color-primary-contrast-A400: #ffffff;
  --color-primary-contrast-A400-rgb: 255, 255, 255;
  --color-primary-contrast-A700: #ffffff;
  --color-primary-contrast-A700-rgb: 255, 255, 255;
  --color-secondary: #3dbfb8;
  --color-secondary-50: #e0f8f7;
  --color-secondary-50-rgb: 224, 248, 247;
  --color-secondary-100: #b3eeea;
  --color-secondary-100-rgb: 179, 238, 234;
  --color-secondary-200: #80e3dd;
  --color-secondary-200-rgb: 128, 227, 221;
  --color-secondary-300: #4dd8cf;
  --color-secondary-300-rgb: 77, 216, 207;
  --color-secondary-400: #26cfc4;
  --color-secondary-400-rgb: 38, 207, 196;
  --color-secondary-500: #3dbfb8;
  --color-secondary-500-rgb: 61, 191, 184;
  --color-secondary-600: #00c1b3;
  --color-secondary-600-rgb: 0, 193, 179;
  --color-secondary-700: #00baab;
  --color-secondary-700-rgb: 0, 186, 171;
  --color-secondary-800: #00b3a3;
  --color-secondary-800-rgb: 0, 179, 163;
  --color-secondary-900: #00a694;
  --color-secondary-900-rgb: 0, 166, 148;
  --color-secondary-A100: #d0fff9;
  --color-secondary-A100-rgb: 208, 255, 249;
  --color-secondary-A200: #9dfff2;
  --color-secondary-A200-rgb: 157, 255, 242;
  --color-secondary-A400: #6affec;
  --color-secondary-A400-rgb: 106, 255, 236;
  --color-secondary-A700: #51ffe9;
  --color-secondary-A700-rgb: 81, 255, 233;
  --color-secondary-contrast-50: #ffffff;
  --color-secondary-contrast-50-rgb: 255, 255, 255;
  --color-secondary-contrast-100: #ffffff;
  --color-secondary-contrast-100-rgb: 255, 255, 255;
  --color-secondary-contrast-200: #ffffff;
  --color-secondary-contrast-200-rgb: 255, 255, 255;
  --color-secondary-contrast-300: #ffffff;
  --color-secondary-contrast-300-rgb: 255, 255, 255;
  --color-secondary-contrast-400: #ffffff;
  --color-secondary-contrast-400-rgb: 255, 255, 255;
  --color-secondary-contrast-500: #ffffff;
  --color-secondary-contrast-500-rgb: 255, 255, 255;
  --color-secondary-contrast-600: #ffffff;
  --color-secondary-contrast-600-rgb: 255, 255, 255;
  --color-secondary-contrast-700: #ffffff;
  --color-secondary-contrast-700-rgb: 255, 255, 255;
  --color-secondary-contrast-800: #ffffff;
  --color-secondary-contrast-800-rgb: 255, 255, 255;
  --color-secondary-contrast-900: #ffffff;
  --color-secondary-contrast-900-rgb: 255, 255, 255;
  --color-secondary-contrast-A100: #ffffff;
  --color-secondary-contrast-A100-rgb: 255, 255, 255;
  --color-secondary-contrast-A200: #ffffff;
  --color-secondary-contrast-A200-rgb: 255, 255, 255;
  --color-secondary-contrast-A400: #ffffff;
  --color-secondary-contrast-A400-rgb: 255, 255, 255;
  --color-secondary-contrast-A700: #ffffff;
  --color-secondary-contrast-A700-rgb: 255, 255, 255;
  --color-tertiary: #36468f;
  --color-tertiary-50: #e7e9f2;
  --color-tertiary-50-rgb: 231, 233, 242;
  --color-tertiary-100: #c3c8dd;
  --color-tertiary-100-rgb: 195, 200, 221;
  --color-tertiary-200: #9ba3c7;
  --color-tertiary-200-rgb: 155, 163, 199;
  --color-tertiary-300: #727eb1;
  --color-tertiary-300-rgb: 114, 126, 177;
  --color-tertiary-400: #5462a0;
  --color-tertiary-400-rgb: 84, 98, 160;
  --color-tertiary-500: #36468f;
  --color-tertiary-500-rgb: 54, 70, 143;
  --color-tertiary-600: #303f87;
  --color-tertiary-600-rgb: 48, 63, 135;
  --color-tertiary-700: #29377c;
  --color-tertiary-700-rgb: 41, 55, 124;
  --color-tertiary-800: #222f72;
  --color-tertiary-800-rgb: 34, 47, 114;
  --color-tertiary-900: #162060;
  --color-tertiary-900-rgb: 22, 32, 96;
  --color-tertiary-A100: #9aa6ff;
  --color-tertiary-A100-rgb: 154, 166, 255;
  --color-tertiary-A200: #6778ff;
  --color-tertiary-A200-rgb: 103, 120, 255;
  --color-tertiary-A400: #344bff;
  --color-tertiary-A400-rgb: 52, 75, 255;
  --color-tertiary-A700: #1a35ff;
  --color-tertiary-A700-rgb: 26, 53, 255;
  --color-tertiary-contrast-50: #ffffff;
  --color-tertiary-contrast-50-rgb: 255, 255, 255;
  --color-tertiary-contrast-100: #ffffff;
  --color-tertiary-contrast-100-rgb: 255, 255, 255;
  --color-tertiary-contrast-200: #ffffff;
  --color-tertiary-contrast-200-rgb: 255, 255, 255;
  --color-tertiary-contrast-300: #ffffff;
  --color-tertiary-contrast-300-rgb: 255, 255, 255;
  --color-tertiary-contrast-400: #ffffff;
  --color-tertiary-contrast-400-rgb: 255, 255, 255;
  --color-tertiary-contrast-500: #ffffff;
  --color-tertiary-contrast-500-rgb: 255, 255, 255;
  --color-tertiary-contrast-600: #ffffff;
  --color-tertiary-contrast-600-rgb: 255, 255, 255;
  --color-tertiary-contrast-700: #ffffff;
  --color-tertiary-contrast-700-rgb: 255, 255, 255;
  --color-tertiary-contrast-800: #ffffff;
  --color-tertiary-contrast-800-rgb: 255, 255, 255;
  --color-tertiary-contrast-900: #ffffff;
  --color-tertiary-contrast-900-rgb: 255, 255, 255;
  --color-tertiary-contrast-A100: #ffffff;
  --color-tertiary-contrast-A100-rgb: 255, 255, 255;
  --color-tertiary-contrast-A200: #ffffff;
  --color-tertiary-contrast-A200-rgb: 255, 255, 255;
  --color-tertiary-contrast-A400: #ffffff;
  --color-tertiary-contrast-A400-rgb: 255, 255, 255;
  --color-tertiary-contrast-A700: #ffffff;
  --color-tertiary-contrast-A700-rgb: 255, 255, 255;
  --color-quaternary: #fea223;
  --color-quaternary-50: #fff4e5;
  --color-quaternary-50-rgb: 255, 244, 229;
  --color-quaternary-100: #ffe3bd;
  --color-quaternary-100-rgb: 255, 227, 189;
  --color-quaternary-200: #ffd191;
  --color-quaternary-200-rgb: 255, 209, 145;
  --color-quaternary-300: #febe65;
  --color-quaternary-300-rgb: 254, 190, 101;
  --color-quaternary-400: #feb044;
  --color-quaternary-400-rgb: 254, 176, 68;
  --color-quaternary-500: #fea223;
  --color-quaternary-500-rgb: 254, 162, 35;
  --color-quaternary-600: #fe9a1f;
  --color-quaternary-600-rgb: 254, 154, 31;
  --color-quaternary-700: #fe901a;
  --color-quaternary-700-rgb: 254, 144, 26;
  --color-quaternary-800: #fe8615;
  --color-quaternary-800-rgb: 254, 134, 21;
  --color-quaternary-900: #fd750c;
  --color-quaternary-900-rgb: 253, 117, 12;
  --color-quaternary-A100: #ffffff;
  --color-quaternary-A100-rgb: 255, 255, 255;
  --color-quaternary-A200: #fff8f4;
  --color-quaternary-A200-rgb: 255, 248, 244;
  --color-quaternary-A400: #ffd9c1;
  --color-quaternary-A400-rgb: 255, 217, 193;
  --color-quaternary-A700: #ffcaa7;
  --color-quaternary-A700-rgb: 255, 202, 167;
  --color-quaternary-contrast-50: #ffffff;
  --color-quaternary-contrast-50-rgb: 255, 255, 255;
  --color-quaternary-contrast-100: #ffffff;
  --color-quaternary-contrast-100-rgb: 255, 255, 255;
  --color-quaternary-contrast-200: #ffffff;
  --color-quaternary-contrast-200-rgb: 255, 255, 255;
  --color-quaternary-contrast-300: #ffffff;
  --color-quaternary-contrast-300-rgb: 255, 255, 255;
  --color-quaternary-contrast-400: #ffffff;
  --color-quaternary-contrast-400-rgb: 255, 255, 255;
  --color-quaternary-contrast-500: #ffffff;
  --color-quaternary-contrast-500-rgb: 255, 255, 255;
  --color-quaternary-contrast-600: #ffffff;
  --color-quaternary-contrast-600-rgb: 255, 255, 255;
  --color-quaternary-contrast-700: #ffffff;
  --color-quaternary-contrast-700-rgb: 255, 255, 255;
  --color-quaternary-contrast-800: #ffffff;
  --color-quaternary-contrast-800-rgb: 255, 255, 255;
  --color-quaternary-contrast-900: #ffffff;
  --color-quaternary-contrast-900-rgb: 255, 255, 255;
  --color-quaternary-contrast-A100: #ffffff;
  --color-quaternary-contrast-A100-rgb: 255, 255, 255;
  --color-quaternary-contrast-A200: #ffffff;
  --color-quaternary-contrast-A200-rgb: 255, 255, 255;
  --color-quaternary-contrast-A400: #ffffff;
  --color-quaternary-contrast-A400-rgb: 255, 255, 255;
  --color-quaternary-contrast-A700: #ffffff;
  --color-quaternary-contrast-A700-rgb: 255, 255, 255;
  --color-accent: #3dbfb8;
  --color-accent-50: #e0f8f7;
  --color-accent-50-rgb: 224, 248, 247;
  --color-accent-100: #b3eeea;
  --color-accent-100-rgb: 179, 238, 234;
  --color-accent-200: #80e3dd;
  --color-accent-200-rgb: 128, 227, 221;
  --color-accent-300: #4dd8cf;
  --color-accent-300-rgb: 77, 216, 207;
  --color-accent-400: #26cfc4;
  --color-accent-400-rgb: 38, 207, 196;
  --color-accent-500: #3dbfb8;
  --color-accent-500-rgb: 61, 191, 184;
  --color-accent-600: #00c1b3;
  --color-accent-600-rgb: 0, 193, 179;
  --color-accent-700: #00baab;
  --color-accent-700-rgb: 0, 186, 171;
  --color-accent-800: #00b3a3;
  --color-accent-800-rgb: 0, 179, 163;
  --color-accent-900: #00a694;
  --color-accent-900-rgb: 0, 166, 148;
  --color-accent-A100: #d0fff9;
  --color-accent-A100-rgb: 208, 255, 249;
  --color-accent-A200: #9dfff2;
  --color-accent-A200-rgb: 157, 255, 242;
  --color-accent-A400: #6affec;
  --color-accent-A400-rgb: 106, 255, 236;
  --color-accent-A700: #51ffe9;
  --color-accent-A700-rgb: 81, 255, 233;
  --color-accent-contrast-50: #ffffff;
  --color-accent-contrast-50-rgb: 255, 255, 255;
  --color-accent-contrast-100: #ffffff;
  --color-accent-contrast-100-rgb: 255, 255, 255;
  --color-accent-contrast-200: #ffffff;
  --color-accent-contrast-200-rgb: 255, 255, 255;
  --color-accent-contrast-300: #ffffff;
  --color-accent-contrast-300-rgb: 255, 255, 255;
  --color-accent-contrast-400: #ffffff;
  --color-accent-contrast-400-rgb: 255, 255, 255;
  --color-accent-contrast-500: #ffffff;
  --color-accent-contrast-500-rgb: 255, 255, 255;
  --color-accent-contrast-600: #ffffff;
  --color-accent-contrast-600-rgb: 255, 255, 255;
  --color-accent-contrast-700: #ffffff;
  --color-accent-contrast-700-rgb: 255, 255, 255;
  --color-accent-contrast-800: #ffffff;
  --color-accent-contrast-800-rgb: 255, 255, 255;
  --color-accent-contrast-900: #ffffff;
  --color-accent-contrast-900-rgb: 255, 255, 255;
  --color-accent-contrast-A100: #ffffff;
  --color-accent-contrast-A100-rgb: 255, 255, 255;
  --color-accent-contrast-A200: #ffffff;
  --color-accent-contrast-A200-rgb: 255, 255, 255;
  --color-accent-contrast-A400: #ffffff;
  --color-accent-contrast-A400-rgb: 255, 255, 255;
  --color-accent-contrast-A700: #ffffff;
  --color-accent-contrast-A700-rgb: 255, 255, 255;
  --color-grayscale: #707070;
  --color-grayscale-50: #f2f2f2;
  --color-grayscale-50-rgb: 242, 242, 242;
  --color-grayscale-100: #eeeeee;
  --color-grayscale-100-rgb: 238, 238, 238;
  --color-grayscale-200: #d1d1d1;
  --color-grayscale-200-rgb: 209, 209, 209;
  --color-grayscale-300: #a6a6a6;
  --color-grayscale-300-rgb: 166, 166, 166;
  --color-grayscale-400: #868686;
  --color-grayscale-400-rgb: 134, 134, 134;
  --color-grayscale-500: #707070;
  --color-grayscale-500-rgb: 112, 112, 112;
  --color-grayscale-600: #686868;
  --color-grayscale-600-rgb: 104, 104, 104;
  --color-grayscale-700: #404040;
  --color-grayscale-700-rgb: 64, 64, 64;
  --color-grayscale-800: #333333;
  --color-grayscale-800-rgb: 51, 51, 51;
  --color-grayscale-900: #262626;
  --color-grayscale-900-rgb: 38, 38, 38;
  --color-grayscale-A100: #FFFFFF;
  --color-grayscale-A100-rgb: 255, 255, 255;
  --color-grayscale-A200: #222222;
  --color-grayscale-A200-rgb: 34, 34, 34;
  --color-grayscale-A400: #111111;
  --color-grayscale-A400-rgb: 17, 17, 17;
  --color-grayscale-A700: #000000;
  --color-grayscale-A700-rgb: 0, 0, 0;
  --color-grayscale-contrast-50: #ffffff;
  --color-grayscale-contrast-50-rgb: 255, 255, 255;
  --color-grayscale-contrast-100: #ffffff;
  --color-grayscale-contrast-100-rgb: 255, 255, 255;
  --color-grayscale-contrast-200: #ffffff;
  --color-grayscale-contrast-200-rgb: 255, 255, 255;
  --color-grayscale-contrast-300: #ffffff;
  --color-grayscale-contrast-300-rgb: 255, 255, 255;
  --color-grayscale-contrast-400: #ffffff;
  --color-grayscale-contrast-400-rgb: 255, 255, 255;
  --color-grayscale-contrast-500: #ffffff;
  --color-grayscale-contrast-500-rgb: 255, 255, 255;
  --color-grayscale-contrast-600: #ffffff;
  --color-grayscale-contrast-600-rgb: 255, 255, 255;
  --color-grayscale-contrast-700: #ffffff;
  --color-grayscale-contrast-700-rgb: 255, 255, 255;
  --color-grayscale-contrast-800: #ffffff;
  --color-grayscale-contrast-800-rgb: 255, 255, 255;
  --color-grayscale-contrast-900: #ffffff;
  --color-grayscale-contrast-900-rgb: 255, 255, 255;
  --color-grayscale-contrast-A100: #ffffff;
  --color-grayscale-contrast-A100-rgb: 255, 255, 255;
  --color-grayscale-contrast-A200: #ffffff;
  --color-grayscale-contrast-A200-rgb: 255, 255, 255;
  --color-grayscale-contrast-A400: #ffffff;
  --color-grayscale-contrast-A400-rgb: 255, 255, 255;
  --color-grayscale-contrast-A700: #ffffff;
  --color-grayscale-contrast-A700-rgb: 255, 255, 255;
  --color-cyan: #509da9;
  --color-cyan-50: #eef6f7;
  --color-cyan-50-rgb: 238, 246, 247;
  --color-cyan-100: #cbe3e6;
  --color-cyan-100-rgb: 203, 227, 230;
  --color-cyan-200: #a8d0d6;
  --color-cyan-200-rgb: 168, 208, 214;
  --color-cyan-300: #86bdc6;
  --color-cyan-300-rgb: 134, 189, 198;
  --color-cyan-400: #63aab5;
  --color-cyan-400-rgb: 99, 170, 181;
  --color-cyan-500: #509da9;
  --color-cyan-500-rgb: 80, 157, 169;
  --color-cyan-600: #4a919c;
  --color-cyan-600-rgb: 74, 145, 156;
  --color-cyan-700: #397179;
  --color-cyan-700-rgb: 57, 113, 121;
  --color-cyan-contrast-50: #ffffff;
  --color-cyan-contrast-50-rgb: 255, 255, 255;
  --color-cyan-contrast-100: #ffffff;
  --color-cyan-contrast-100-rgb: 255, 255, 255;
  --color-cyan-contrast-200: #ffffff;
  --color-cyan-contrast-200-rgb: 255, 255, 255;
  --color-cyan-contrast-300: #ffffff;
  --color-cyan-contrast-300-rgb: 255, 255, 255;
  --color-cyan-contrast-400: #ffffff;
  --color-cyan-contrast-400-rgb: 255, 255, 255;
  --color-cyan-contrast-500: #ffffff;
  --color-cyan-contrast-500-rgb: 255, 255, 255;
  --color-cyan-contrast-600: #ffffff;
  --color-cyan-contrast-600-rgb: 255, 255, 255;
  --color-cyan-contrast-700: #ffffff;
  --color-cyan-contrast-700-rgb: 255, 255, 255;
  --color-cyan-contrast-800: #ffffff;
  --color-cyan-contrast-800-rgb: 255, 255, 255;
  --color-cyan-contrast-900: #ffffff;
  --color-cyan-contrast-900-rgb: 255, 255, 255;
  --color-cyan-contrast-A100: #ffffff;
  --color-cyan-contrast-A100-rgb: 255, 255, 255;
  --color-cyan-contrast-A200: #ffffff;
  --color-cyan-contrast-A200-rgb: 255, 255, 255;
  --color-cyan-contrast-A400: #ffffff;
  --color-cyan-contrast-A400-rgb: 255, 255, 255;
  --color-cyan-contrast-A700: #ffffff;
  --color-cyan-contrast-A700-rgb: 255, 255, 255;
  --color-green: #2d836a;
  --color-green-50: #e6f0ed;
  --color-green-50-rgb: 230, 240, 237;
  --color-green-100: #c0dad2;
  --color-green-100-rgb: 192, 218, 210;
  --color-green-200: #96c1b5;
  --color-green-200-rgb: 150, 193, 181;
  --color-green-300: #6ca897;
  --color-green-300-rgb: 108, 168, 151;
  --color-green-400: #4d9680;
  --color-green-400-rgb: 77, 150, 128;
  --color-green-500: #2d836a;
  --color-green-500-rgb: 45, 131, 106;
  --color-green-600: #287b62;
  --color-green-600-rgb: 40, 123, 98;
  --color-green-700: #227057;
  --color-green-700-rgb: 34, 112, 87;
  --color-green-800: #1c664d;
  --color-green-800-rgb: 28, 102, 77;
  --color-green-900: #11533c;
  --color-green-900-rgb: 17, 83, 60;
  --color-green-A100: #8bffd3;
  --color-green-A100-rgb: 139, 255, 211;
  --color-green-A200: #58ffbf;
  --color-green-A200-rgb: 88, 255, 191;
  --color-green-A400: #25ffac;
  --color-green-A400-rgb: 37, 255, 172;
  --color-green-A700: #0cffa2;
  --color-green-A700-rgb: 12, 255, 162;
  --color-green-contrast-50: #ffffff;
  --color-green-contrast-50-rgb: 255, 255, 255;
  --color-green-contrast-100: #ffffff;
  --color-green-contrast-100-rgb: 255, 255, 255;
  --color-green-contrast-200: #ffffff;
  --color-green-contrast-200-rgb: 255, 255, 255;
  --color-green-contrast-300: #ffffff;
  --color-green-contrast-300-rgb: 255, 255, 255;
  --color-green-contrast-400: #ffffff;
  --color-green-contrast-400-rgb: 255, 255, 255;
  --color-green-contrast-500: #ffffff;
  --color-green-contrast-500-rgb: 255, 255, 255;
  --color-green-contrast-600: #ffffff;
  --color-green-contrast-600-rgb: 255, 255, 255;
  --color-green-contrast-700: #ffffff;
  --color-green-contrast-700-rgb: 255, 255, 255;
  --color-green-contrast-800: #ffffff;
  --color-green-contrast-800-rgb: 255, 255, 255;
  --color-green-contrast-900: #ffffff;
  --color-green-contrast-900-rgb: 255, 255, 255;
  --color-green-contrast-A100: #ffffff;
  --color-green-contrast-A100-rgb: 255, 255, 255;
  --color-green-contrast-A200: #ffffff;
  --color-green-contrast-A200-rgb: 255, 255, 255;
  --color-green-contrast-A400: #ffffff;
  --color-green-contrast-A400-rgb: 255, 255, 255;
  --color-green-contrast-A700: #ffffff;
  --color-green-contrast-A700-rgb: 255, 255, 255;
  --color-system-info: #0c6ccc;
  --color-system-info-50: #e2edf9;
  --color-system-info-50-rgb: 226, 237, 249;
  --color-system-info-100: #b6d3f0;
  --color-system-info-100-rgb: 182, 211, 240;
  --color-system-info-200: #86b6e6;
  --color-system-info-200-rgb: 134, 182, 230;
  --color-system-info-300: #5598db;
  --color-system-info-300-rgb: 85, 152, 219;
  --color-system-info-400: #3082d4;
  --color-system-info-400-rgb: 48, 130, 212;
  --color-system-info-500: #0c6ccc;
  --color-system-info-500-rgb: 12, 108, 204;
  --color-system-info-600: #0a64c7;
  --color-system-info-600-rgb: 10, 100, 199;
  --color-system-info-700: #0859c0;
  --color-system-info-700-rgb: 8, 89, 192;
  --color-system-info-800: #064fb9;
  --color-system-info-800-rgb: 6, 79, 185;
  --color-system-info-900: #033dad;
  --color-system-info-900-rgb: 3, 61, 173;
  --color-system-info-A100: #d7e2ff;
  --color-system-info-A100-rgb: 215, 226, 255;
  --color-system-info-A200: #a4bdff;
  --color-system-info-A200-rgb: 164, 189, 255;
  --color-system-info-A400: #7198ff;
  --color-system-info-A400-rgb: 113, 152, 255;
  --color-system-info-A700: #5886ff;
  --color-system-info-A700-rgb: 88, 134, 255;
  --color-system-info-contrast-50: #ffffff;
  --color-system-info-contrast-50-rgb: 255, 255, 255;
  --color-system-info-contrast-100: #ffffff;
  --color-system-info-contrast-100-rgb: 255, 255, 255;
  --color-system-info-contrast-200: #ffffff;
  --color-system-info-contrast-200-rgb: 255, 255, 255;
  --color-system-info-contrast-300: #ffffff;
  --color-system-info-contrast-300-rgb: 255, 255, 255;
  --color-system-info-contrast-400: #ffffff;
  --color-system-info-contrast-400-rgb: 255, 255, 255;
  --color-system-info-contrast-500: #ffffff;
  --color-system-info-contrast-500-rgb: 255, 255, 255;
  --color-system-info-contrast-600: #ffffff;
  --color-system-info-contrast-600-rgb: 255, 255, 255;
  --color-system-info-contrast-700: #ffffff;
  --color-system-info-contrast-700-rgb: 255, 255, 255;
  --color-system-info-contrast-800: #ffffff;
  --color-system-info-contrast-800-rgb: 255, 255, 255;
  --color-system-info-contrast-900: #ffffff;
  --color-system-info-contrast-900-rgb: 255, 255, 255;
  --color-system-info-contrast-A100: #ffffff;
  --color-system-info-contrast-A100-rgb: 255, 255, 255;
  --color-system-info-contrast-A200: #ffffff;
  --color-system-info-contrast-A200-rgb: 255, 255, 255;
  --color-system-info-contrast-A400: #ffffff;
  --color-system-info-contrast-A400-rgb: 255, 255, 255;
  --color-system-info-contrast-A700: #ffffff;
  --color-system-info-contrast-A700-rgb: 255, 255, 255;
  --color-system-error: #ff5251;
  --color-system-error-50: #ffeaea;
  --color-system-error-50-rgb: 255, 234, 234;
  --color-system-error-100: #ffcbcb;
  --color-system-error-100-rgb: 255, 203, 203;
  --color-system-error-200: #ffa9a8;
  --color-system-error-200-rgb: 255, 169, 168;
  --color-system-error-300: #ff8685;
  --color-system-error-300-rgb: 255, 134, 133;
  --color-system-error-400: #ff6c6b;
  --color-system-error-400-rgb: 255, 108, 107;
  --color-system-error-500: #ff5251;
  --color-system-error-500-rgb: 255, 82, 81;
  --color-system-error-600: #ff4b4a;
  --color-system-error-600-rgb: 255, 75, 74;
  --color-system-error-700: #ff4140;
  --color-system-error-700-rgb: 255, 65, 64;
  --color-system-error-800: #ff3837;
  --color-system-error-800-rgb: 255, 56, 55;
  --color-system-error-900: #ff2827;
  --color-system-error-900-rgb: 255, 40, 39;
  --color-system-error-A100: #ffffff;
  --color-system-error-A100-rgb: 255, 255, 255;
  --color-system-error-A200: #ffffff;
  --color-system-error-A200-rgb: 255, 255, 255;
  --color-system-error-A400: #ffffff;
  --color-system-error-A400-rgb: 255, 255, 255;
  --color-system-error-A700: #ffffff;
  --color-system-error-A700-rgb: 255, 255, 255;
  --color-system-error-contrast-50: #ffffff;
  --color-system-error-contrast-50-rgb: 255, 255, 255;
  --color-system-error-contrast-100: #ffffff;
  --color-system-error-contrast-100-rgb: 255, 255, 255;
  --color-system-error-contrast-200: #ffffff;
  --color-system-error-contrast-200-rgb: 255, 255, 255;
  --color-system-error-contrast-300: #ffffff;
  --color-system-error-contrast-300-rgb: 255, 255, 255;
  --color-system-error-contrast-400: #ffffff;
  --color-system-error-contrast-400-rgb: 255, 255, 255;
  --color-system-error-contrast-500: #ffffff;
  --color-system-error-contrast-500-rgb: 255, 255, 255;
  --color-system-error-contrast-600: #ffffff;
  --color-system-error-contrast-600-rgb: 255, 255, 255;
  --color-system-error-contrast-700: #ffffff;
  --color-system-error-contrast-700-rgb: 255, 255, 255;
  --color-system-error-contrast-800: #ffffff;
  --color-system-error-contrast-800-rgb: 255, 255, 255;
  --color-system-error-contrast-900: #ffffff;
  --color-system-error-contrast-900-rgb: 255, 255, 255;
  --color-system-error-contrast-A100: #ffffff;
  --color-system-error-contrast-A100-rgb: 255, 255, 255;
  --color-system-error-contrast-A200: #ffffff;
  --color-system-error-contrast-A200-rgb: 255, 255, 255;
  --color-system-error-contrast-A400: #ffffff;
  --color-system-error-contrast-A400-rgb: 255, 255, 255;
  --color-system-error-contrast-A700: #ffffff;
  --color-system-error-contrast-A700-rgb: 255, 255, 255;
  --color-system-success: #00ad43;
  --color-system-success-50: #e6f2e6;
  --color-system-success-50-rgb: 230, 242, 230;
  --color-system-success-100: #b3e6c7;
  --color-system-success-100-rgb: 179, 230, 199;
  --color-system-success-200: #80d6a1;
  --color-system-success-200-rgb: 128, 214, 161;
  --color-system-success-300: #4dc67b;
  --color-system-success-300-rgb: 77, 198, 123;
  --color-system-success-400: #26b95f;
  --color-system-success-400-rgb: 38, 185, 95;
  --color-system-success-500: #00ad43;
  --color-system-success-500-rgb: 0, 173, 67;
  --color-system-success-600: #00a63d;
  --color-system-success-600-rgb: 0, 166, 61;
  --color-system-success-700: #009c34;
  --color-system-success-700-rgb: 0, 156, 52;
  --color-system-success-800: #00932c;
  --color-system-success-800-rgb: 0, 147, 44;
  --color-system-success-900: #00831e;
  --color-system-success-900-rgb: 0, 131, 30;
  --color-system-success-A100: #b0ffbb;
  --color-system-success-A100-rgb: 176, 255, 187;
  --color-system-success-A200: #7dff90;
  --color-system-success-A200-rgb: 125, 255, 144;
  --color-system-success-A400: #4aff64;
  --color-system-success-A400-rgb: 74, 255, 100;
  --color-system-success-A700: #30ff4e;
  --color-system-success-A700-rgb: 48, 255, 78;
  --color-system-success-contrast-50: #ffffff;
  --color-system-success-contrast-50-rgb: 255, 255, 255;
  --color-system-success-contrast-100: #ffffff;
  --color-system-success-contrast-100-rgb: 255, 255, 255;
  --color-system-success-contrast-200: #ffffff;
  --color-system-success-contrast-200-rgb: 255, 255, 255;
  --color-system-success-contrast-300: #ffffff;
  --color-system-success-contrast-300-rgb: 255, 255, 255;
  --color-system-success-contrast-400: #ffffff;
  --color-system-success-contrast-400-rgb: 255, 255, 255;
  --color-system-success-contrast-500: #ffffff;
  --color-system-success-contrast-500-rgb: 255, 255, 255;
  --color-system-success-contrast-600: #ffffff;
  --color-system-success-contrast-600-rgb: 255, 255, 255;
  --color-system-success-contrast-700: #ffffff;
  --color-system-success-contrast-700-rgb: 255, 255, 255;
  --color-system-success-contrast-800: #ffffff;
  --color-system-success-contrast-800-rgb: 255, 255, 255;
  --color-system-success-contrast-900: #ffffff;
  --color-system-success-contrast-900-rgb: 255, 255, 255;
  --color-system-success-contrast-A100: #ffffff;
  --color-system-success-contrast-A100-rgb: 255, 255, 255;
  --color-system-success-contrast-A200: #ffffff;
  --color-system-success-contrast-A200-rgb: 255, 255, 255;
  --color-system-success-contrast-A400: #ffffff;
  --color-system-success-contrast-A400-rgb: 255, 255, 255;
  --color-system-success-contrast-A700: #ffffff;
  --color-system-success-contrast-A700-rgb: 255, 255, 255;
  --color-system-warning: #ffc107;
  --color-system-warning-50: #fff8e1;
  --color-system-warning-50-rgb: 255, 248, 225;
  --color-system-warning-100: #ffecb5;
  --color-system-warning-100-rgb: 255, 236, 181;
  --color-system-warning-200: #ffe083;
  --color-system-warning-200-rgb: 255, 224, 131;
  --color-system-warning-300: #ffd451;
  --color-system-warning-300-rgb: 255, 212, 81;
  --color-system-warning-400: #ffca2c;
  --color-system-warning-400-rgb: 255, 202, 44;
  --color-system-warning-500: #ffc107;
  --color-system-warning-500-rgb: 255, 193, 7;
  --color-system-warning-600: #ffbb06;
  --color-system-warning-600-rgb: 255, 187, 6;
  --color-system-warning-700: #ffb305;
  --color-system-warning-700-rgb: 255, 179, 5;
  --color-system-warning-800: #ffab04;
  --color-system-warning-800-rgb: 255, 171, 4;
  --color-system-warning-900: #ff9e02;
  --color-system-warning-900-rgb: 255, 158, 2;
  --color-system-warning-A100: #ffffff;
  --color-system-warning-A100-rgb: 255, 255, 255;
  --color-system-warning-A200: #fffaf2;
  --color-system-warning-A200-rgb: 255, 250, 242;
  --color-system-warning-A400: #ffe4bf;
  --color-system-warning-A400-rgb: 255, 228, 191;
  --color-system-warning-A700: #ffd9a6;
  --color-system-warning-A700-rgb: 255, 217, 166;
  --color-system-warning-contrast-50: #000000;
  --color-system-warning-contrast-50-rgb: 0, 0, 0;
  --color-system-warning-contrast-100: #000000;
  --color-system-warning-contrast-100-rgb: 0, 0, 0;
  --color-system-warning-contrast-200: #000000;
  --color-system-warning-contrast-200-rgb: 0, 0, 0;
  --color-system-warning-contrast-300: #000000;
  --color-system-warning-contrast-300-rgb: 0, 0, 0;
  --color-system-warning-contrast-400: #000000;
  --color-system-warning-contrast-400-rgb: 0, 0, 0;
  --color-system-warning-contrast-500: #000000;
  --color-system-warning-contrast-500-rgb: 0, 0, 0;
  --color-system-warning-contrast-600: #000000;
  --color-system-warning-contrast-600-rgb: 0, 0, 0;
  --color-system-warning-contrast-700: #000000;
  --color-system-warning-contrast-700-rgb: 0, 0, 0;
  --color-system-warning-contrast-800: #000000;
  --color-system-warning-contrast-800-rgb: 0, 0, 0;
  --color-system-warning-contrast-900: #000000;
  --color-system-warning-contrast-900-rgb: 0, 0, 0;
  --color-system-warning-contrast-A100: #000000;
  --color-system-warning-contrast-A100-rgb: 0, 0, 0;
  --color-system-warning-contrast-A200: #000000;
  --color-system-warning-contrast-A200-rgb: 0, 0, 0;
  --color-system-warning-contrast-A400: #000000;
  --color-system-warning-contrast-A400-rgb: 0, 0, 0;
  --color-system-warning-contrast-A700: #000000;
  --color-system-warning-contrast-A700-rgb: 0, 0, 0;
  --color-system-inactive: #707070;
  --color-red: #ff0200;
  --color-svg-default: #707070;
  --color-white: #ffffff;
  --color-transparent: transparent;
  --color-text-normal: #333333;
  --color-text-normal-inverted: #FFFFFF;
  --color-text-weak: #707070;
  --color-text-weaker-medium: #868686;
  --color-text-weaker: #a6a6a6;
  --color-text-strong: #000000;
  --color-link-normal: #0c6ccc;
  --color-link-hover: #86b6e6;
  --color-background-primary-weak: var(--color-primary-100);
  --color-background-normal: var(--color-grayscale-A100);
  --color-background-normal-rgb: var(--color-grayscale-A100-rgb);
  --color-background-normal-inverted: #333333;
  --color-background-strong: var(--color-grayscale);
  --color-background-medium: #d1d1d1;
  --color-background-weak: #f2f2f2;
  --color-background-info-weak: #e2edf9;
  --color-border-strongest: #000000;
  --color-border-strong: #333333;
  --color-border-normal: #d1d1d1;
  --color-border-normal-inverted: #FFFFFF;
  --color-border-weak: #f2f2f2;
  --color-border-transparent: transparent;
  --color-icon-primary: var(--color-primary);
  --color-icon-accent: var(--color-accent);
  --color-icon-normal: var(--color-grayscale-500);
  --color-icon-weak: var(--color-grayscale-400);
  --color-icon-weak-hover: var(--color-grayscale-300);
  --color-icon-inverted: var(--color-text-normal-inverted);
  --color-icon-text-normal: var(--color-text-normal);
  --color-icon-text-normal-inverted: var(--color-text-normal-inverted);
  --color-icon-text-weak: var(--color-text-weak);
  --color-form-input-border-normal: #333333;
  --color-form-input-border-hover: #333333;
  --color-form-input-border-focus: #333333;
  --color-form-input-border-disabled: #333333;
  --color-form-input-border-error: #333333;
  --color-form-input-border-success: #333333;
  --color-bui-button-background-primary: #707070;
  --color-bui-button-background-primary-hover: #d1d1d1;
  --color-bui-button-background-secondary: #3dbfb8;
  --color-bui-button-background-secondary-hover: #80e3dd;
  --color-bui-button-background-accent: #3dbfb8;
  --color-bui-button-background-accent-hover: #80e3dd;
  --color-bui-button-background-error: #ff5251;
  --color-bui-button-background-error-hover: #ffa9a8;
  --color-bui-button-background-normal: #333333;
  --color-bui-button-background-normal-hover: #707070;
  --color-bui-button-background-weak: #f2f2f2;
  --color-bui-button-background-weak-hover: #d1d1d1;
  --color-bui-button-background-inverted: #FFFFFF;
  --color-bui-button-background-inverted-hover: #f2f2f2;
  --color-bui-button-background-transparent: transparent;
  --color-bui-button-text-primary: #707070;
  --color-bui-button-text-primary-hover: #d1d1d1;
  --color-bui-button-text-accent: #3dbfb8;
  --color-bui-button-text-accent-hover: #80e3dd;
  --color-bui-button-text-tertiary: #36468f;
  --color-bui-button-text-tertiary-hover: #9ba3c7;
  --color-bui-button-text-link: #0c6ccc;
  --color-bui-button-text-link-hover: #86b6e6;
  --color-bui-button-text-normal: #707070;
  --color-bui-button-text-normal-hover: #707070;
  --color-bui-button-text-weak: #707070;
  --color-bui-button-text-weak-hover: #d1d1d1;
  --color-bui-button-text-inverted: #FFFFFF;
  --color-bui-button-text-inverted-hover: #f2f2f2;
  --color-bui-button-text-status-success: var(--color-system-success);
  --color-bui-button-text-status-success-hover: var(--color-system-success-200);
  --color-bui-button-text-status-error: var(--color-system-error);
  --color-bui-button-text-status-error-hover: var(--color-system-error-200);
  --color-bui-button-icon-weak: var(--color-grayscale-400);
  --color-bui-button-icon-weak-hover: var(--color-grayscale-400);
  --color-bui-button-icon-success: var(--color-system-success-900);
  --color-bui-button-icon-success-hover: var(--color-system-success-900);
  --color-table-head-row-background-normal: #eeeeee;
  --color-table-body-row-background: #FFFFFF;
  --color-table-body-row-background-selected: #e6f2e6;
}

.theme-system-error {
  --color-primary: #ff5251;
  --color-primary-50: #ffeaea;
  --color-primary-50-rgb: 255, 234, 234;
  --color-primary-100: #ffcbcb;
  --color-primary-100-rgb: 255, 203, 203;
  --color-primary-200: #ffa9a8;
  --color-primary-200-rgb: 255, 169, 168;
  --color-primary-300: #ff8685;
  --color-primary-300-rgb: 255, 134, 133;
  --color-primary-400: #ff6c6b;
  --color-primary-400-rgb: 255, 108, 107;
  --color-primary-500: #ff5251;
  --color-primary-500-rgb: 255, 82, 81;
  --color-primary-600: #ff4b4a;
  --color-primary-600-rgb: 255, 75, 74;
  --color-primary-700: #ff4140;
  --color-primary-700-rgb: 255, 65, 64;
  --color-primary-800: #ff3837;
  --color-primary-800-rgb: 255, 56, 55;
  --color-primary-900: #ff2827;
  --color-primary-900-rgb: 255, 40, 39;
  --color-primary-A100: #ffffff;
  --color-primary-A100-rgb: 255, 255, 255;
  --color-primary-A200: #ffffff;
  --color-primary-A200-rgb: 255, 255, 255;
  --color-primary-A400: #ffffff;
  --color-primary-A400-rgb: 255, 255, 255;
  --color-primary-A700: #ffffff;
  --color-primary-A700-rgb: 255, 255, 255;
  --color-primary-contrast-50: #ffffff;
  --color-primary-contrast-50-rgb: 255, 255, 255;
  --color-primary-contrast-100: #ffffff;
  --color-primary-contrast-100-rgb: 255, 255, 255;
  --color-primary-contrast-200: #ffffff;
  --color-primary-contrast-200-rgb: 255, 255, 255;
  --color-primary-contrast-300: #ffffff;
  --color-primary-contrast-300-rgb: 255, 255, 255;
  --color-primary-contrast-400: #ffffff;
  --color-primary-contrast-400-rgb: 255, 255, 255;
  --color-primary-contrast-500: #ffffff;
  --color-primary-contrast-500-rgb: 255, 255, 255;
  --color-primary-contrast-600: #ffffff;
  --color-primary-contrast-600-rgb: 255, 255, 255;
  --color-primary-contrast-700: #ffffff;
  --color-primary-contrast-700-rgb: 255, 255, 255;
  --color-primary-contrast-800: #ffffff;
  --color-primary-contrast-800-rgb: 255, 255, 255;
  --color-primary-contrast-900: #ffffff;
  --color-primary-contrast-900-rgb: 255, 255, 255;
  --color-primary-contrast-A100: #ffffff;
  --color-primary-contrast-A100-rgb: 255, 255, 255;
  --color-primary-contrast-A200: #ffffff;
  --color-primary-contrast-A200-rgb: 255, 255, 255;
  --color-primary-contrast-A400: #ffffff;
  --color-primary-contrast-A400-rgb: 255, 255, 255;
  --color-primary-contrast-A700: #ffffff;
  --color-primary-contrast-A700-rgb: 255, 255, 255;
  --color-secondary: #3dbfb8;
  --color-secondary-50: #e0f8f7;
  --color-secondary-50-rgb: 224, 248, 247;
  --color-secondary-100: #b3eeea;
  --color-secondary-100-rgb: 179, 238, 234;
  --color-secondary-200: #80e3dd;
  --color-secondary-200-rgb: 128, 227, 221;
  --color-secondary-300: #4dd8cf;
  --color-secondary-300-rgb: 77, 216, 207;
  --color-secondary-400: #26cfc4;
  --color-secondary-400-rgb: 38, 207, 196;
  --color-secondary-500: #3dbfb8;
  --color-secondary-500-rgb: 61, 191, 184;
  --color-secondary-600: #00c1b3;
  --color-secondary-600-rgb: 0, 193, 179;
  --color-secondary-700: #00baab;
  --color-secondary-700-rgb: 0, 186, 171;
  --color-secondary-800: #00b3a3;
  --color-secondary-800-rgb: 0, 179, 163;
  --color-secondary-900: #00a694;
  --color-secondary-900-rgb: 0, 166, 148;
  --color-secondary-A100: #d0fff9;
  --color-secondary-A100-rgb: 208, 255, 249;
  --color-secondary-A200: #9dfff2;
  --color-secondary-A200-rgb: 157, 255, 242;
  --color-secondary-A400: #6affec;
  --color-secondary-A400-rgb: 106, 255, 236;
  --color-secondary-A700: #51ffe9;
  --color-secondary-A700-rgb: 81, 255, 233;
  --color-secondary-contrast-50: #ffffff;
  --color-secondary-contrast-50-rgb: 255, 255, 255;
  --color-secondary-contrast-100: #ffffff;
  --color-secondary-contrast-100-rgb: 255, 255, 255;
  --color-secondary-contrast-200: #ffffff;
  --color-secondary-contrast-200-rgb: 255, 255, 255;
  --color-secondary-contrast-300: #ffffff;
  --color-secondary-contrast-300-rgb: 255, 255, 255;
  --color-secondary-contrast-400: #ffffff;
  --color-secondary-contrast-400-rgb: 255, 255, 255;
  --color-secondary-contrast-500: #ffffff;
  --color-secondary-contrast-500-rgb: 255, 255, 255;
  --color-secondary-contrast-600: #ffffff;
  --color-secondary-contrast-600-rgb: 255, 255, 255;
  --color-secondary-contrast-700: #ffffff;
  --color-secondary-contrast-700-rgb: 255, 255, 255;
  --color-secondary-contrast-800: #ffffff;
  --color-secondary-contrast-800-rgb: 255, 255, 255;
  --color-secondary-contrast-900: #ffffff;
  --color-secondary-contrast-900-rgb: 255, 255, 255;
  --color-secondary-contrast-A100: #ffffff;
  --color-secondary-contrast-A100-rgb: 255, 255, 255;
  --color-secondary-contrast-A200: #ffffff;
  --color-secondary-contrast-A200-rgb: 255, 255, 255;
  --color-secondary-contrast-A400: #ffffff;
  --color-secondary-contrast-A400-rgb: 255, 255, 255;
  --color-secondary-contrast-A700: #ffffff;
  --color-secondary-contrast-A700-rgb: 255, 255, 255;
  --color-tertiary: #36468f;
  --color-tertiary-50: #e7e9f2;
  --color-tertiary-50-rgb: 231, 233, 242;
  --color-tertiary-100: #c3c8dd;
  --color-tertiary-100-rgb: 195, 200, 221;
  --color-tertiary-200: #9ba3c7;
  --color-tertiary-200-rgb: 155, 163, 199;
  --color-tertiary-300: #727eb1;
  --color-tertiary-300-rgb: 114, 126, 177;
  --color-tertiary-400: #5462a0;
  --color-tertiary-400-rgb: 84, 98, 160;
  --color-tertiary-500: #36468f;
  --color-tertiary-500-rgb: 54, 70, 143;
  --color-tertiary-600: #303f87;
  --color-tertiary-600-rgb: 48, 63, 135;
  --color-tertiary-700: #29377c;
  --color-tertiary-700-rgb: 41, 55, 124;
  --color-tertiary-800: #222f72;
  --color-tertiary-800-rgb: 34, 47, 114;
  --color-tertiary-900: #162060;
  --color-tertiary-900-rgb: 22, 32, 96;
  --color-tertiary-A100: #9aa6ff;
  --color-tertiary-A100-rgb: 154, 166, 255;
  --color-tertiary-A200: #6778ff;
  --color-tertiary-A200-rgb: 103, 120, 255;
  --color-tertiary-A400: #344bff;
  --color-tertiary-A400-rgb: 52, 75, 255;
  --color-tertiary-A700: #1a35ff;
  --color-tertiary-A700-rgb: 26, 53, 255;
  --color-tertiary-contrast-50: #ffffff;
  --color-tertiary-contrast-50-rgb: 255, 255, 255;
  --color-tertiary-contrast-100: #ffffff;
  --color-tertiary-contrast-100-rgb: 255, 255, 255;
  --color-tertiary-contrast-200: #ffffff;
  --color-tertiary-contrast-200-rgb: 255, 255, 255;
  --color-tertiary-contrast-300: #ffffff;
  --color-tertiary-contrast-300-rgb: 255, 255, 255;
  --color-tertiary-contrast-400: #ffffff;
  --color-tertiary-contrast-400-rgb: 255, 255, 255;
  --color-tertiary-contrast-500: #ffffff;
  --color-tertiary-contrast-500-rgb: 255, 255, 255;
  --color-tertiary-contrast-600: #ffffff;
  --color-tertiary-contrast-600-rgb: 255, 255, 255;
  --color-tertiary-contrast-700: #ffffff;
  --color-tertiary-contrast-700-rgb: 255, 255, 255;
  --color-tertiary-contrast-800: #ffffff;
  --color-tertiary-contrast-800-rgb: 255, 255, 255;
  --color-tertiary-contrast-900: #ffffff;
  --color-tertiary-contrast-900-rgb: 255, 255, 255;
  --color-tertiary-contrast-A100: #ffffff;
  --color-tertiary-contrast-A100-rgb: 255, 255, 255;
  --color-tertiary-contrast-A200: #ffffff;
  --color-tertiary-contrast-A200-rgb: 255, 255, 255;
  --color-tertiary-contrast-A400: #ffffff;
  --color-tertiary-contrast-A400-rgb: 255, 255, 255;
  --color-tertiary-contrast-A700: #ffffff;
  --color-tertiary-contrast-A700-rgb: 255, 255, 255;
  --color-quaternary: #fea223;
  --color-quaternary-50: #fff4e5;
  --color-quaternary-50-rgb: 255, 244, 229;
  --color-quaternary-100: #ffe3bd;
  --color-quaternary-100-rgb: 255, 227, 189;
  --color-quaternary-200: #ffd191;
  --color-quaternary-200-rgb: 255, 209, 145;
  --color-quaternary-300: #febe65;
  --color-quaternary-300-rgb: 254, 190, 101;
  --color-quaternary-400: #feb044;
  --color-quaternary-400-rgb: 254, 176, 68;
  --color-quaternary-500: #fea223;
  --color-quaternary-500-rgb: 254, 162, 35;
  --color-quaternary-600: #fe9a1f;
  --color-quaternary-600-rgb: 254, 154, 31;
  --color-quaternary-700: #fe901a;
  --color-quaternary-700-rgb: 254, 144, 26;
  --color-quaternary-800: #fe8615;
  --color-quaternary-800-rgb: 254, 134, 21;
  --color-quaternary-900: #fd750c;
  --color-quaternary-900-rgb: 253, 117, 12;
  --color-quaternary-A100: #ffffff;
  --color-quaternary-A100-rgb: 255, 255, 255;
  --color-quaternary-A200: #fff8f4;
  --color-quaternary-A200-rgb: 255, 248, 244;
  --color-quaternary-A400: #ffd9c1;
  --color-quaternary-A400-rgb: 255, 217, 193;
  --color-quaternary-A700: #ffcaa7;
  --color-quaternary-A700-rgb: 255, 202, 167;
  --color-quaternary-contrast-50: #ffffff;
  --color-quaternary-contrast-50-rgb: 255, 255, 255;
  --color-quaternary-contrast-100: #ffffff;
  --color-quaternary-contrast-100-rgb: 255, 255, 255;
  --color-quaternary-contrast-200: #ffffff;
  --color-quaternary-contrast-200-rgb: 255, 255, 255;
  --color-quaternary-contrast-300: #ffffff;
  --color-quaternary-contrast-300-rgb: 255, 255, 255;
  --color-quaternary-contrast-400: #ffffff;
  --color-quaternary-contrast-400-rgb: 255, 255, 255;
  --color-quaternary-contrast-500: #ffffff;
  --color-quaternary-contrast-500-rgb: 255, 255, 255;
  --color-quaternary-contrast-600: #ffffff;
  --color-quaternary-contrast-600-rgb: 255, 255, 255;
  --color-quaternary-contrast-700: #ffffff;
  --color-quaternary-contrast-700-rgb: 255, 255, 255;
  --color-quaternary-contrast-800: #ffffff;
  --color-quaternary-contrast-800-rgb: 255, 255, 255;
  --color-quaternary-contrast-900: #ffffff;
  --color-quaternary-contrast-900-rgb: 255, 255, 255;
  --color-quaternary-contrast-A100: #ffffff;
  --color-quaternary-contrast-A100-rgb: 255, 255, 255;
  --color-quaternary-contrast-A200: #ffffff;
  --color-quaternary-contrast-A200-rgb: 255, 255, 255;
  --color-quaternary-contrast-A400: #ffffff;
  --color-quaternary-contrast-A400-rgb: 255, 255, 255;
  --color-quaternary-contrast-A700: #ffffff;
  --color-quaternary-contrast-A700-rgb: 255, 255, 255;
  --color-accent: #3dbfb8;
  --color-accent-50: #e0f8f7;
  --color-accent-50-rgb: 224, 248, 247;
  --color-accent-100: #b3eeea;
  --color-accent-100-rgb: 179, 238, 234;
  --color-accent-200: #80e3dd;
  --color-accent-200-rgb: 128, 227, 221;
  --color-accent-300: #4dd8cf;
  --color-accent-300-rgb: 77, 216, 207;
  --color-accent-400: #26cfc4;
  --color-accent-400-rgb: 38, 207, 196;
  --color-accent-500: #3dbfb8;
  --color-accent-500-rgb: 61, 191, 184;
  --color-accent-600: #00c1b3;
  --color-accent-600-rgb: 0, 193, 179;
  --color-accent-700: #00baab;
  --color-accent-700-rgb: 0, 186, 171;
  --color-accent-800: #00b3a3;
  --color-accent-800-rgb: 0, 179, 163;
  --color-accent-900: #00a694;
  --color-accent-900-rgb: 0, 166, 148;
  --color-accent-A100: #d0fff9;
  --color-accent-A100-rgb: 208, 255, 249;
  --color-accent-A200: #9dfff2;
  --color-accent-A200-rgb: 157, 255, 242;
  --color-accent-A400: #6affec;
  --color-accent-A400-rgb: 106, 255, 236;
  --color-accent-A700: #51ffe9;
  --color-accent-A700-rgb: 81, 255, 233;
  --color-accent-contrast-50: #ffffff;
  --color-accent-contrast-50-rgb: 255, 255, 255;
  --color-accent-contrast-100: #ffffff;
  --color-accent-contrast-100-rgb: 255, 255, 255;
  --color-accent-contrast-200: #ffffff;
  --color-accent-contrast-200-rgb: 255, 255, 255;
  --color-accent-contrast-300: #ffffff;
  --color-accent-contrast-300-rgb: 255, 255, 255;
  --color-accent-contrast-400: #ffffff;
  --color-accent-contrast-400-rgb: 255, 255, 255;
  --color-accent-contrast-500: #ffffff;
  --color-accent-contrast-500-rgb: 255, 255, 255;
  --color-accent-contrast-600: #ffffff;
  --color-accent-contrast-600-rgb: 255, 255, 255;
  --color-accent-contrast-700: #ffffff;
  --color-accent-contrast-700-rgb: 255, 255, 255;
  --color-accent-contrast-800: #ffffff;
  --color-accent-contrast-800-rgb: 255, 255, 255;
  --color-accent-contrast-900: #ffffff;
  --color-accent-contrast-900-rgb: 255, 255, 255;
  --color-accent-contrast-A100: #ffffff;
  --color-accent-contrast-A100-rgb: 255, 255, 255;
  --color-accent-contrast-A200: #ffffff;
  --color-accent-contrast-A200-rgb: 255, 255, 255;
  --color-accent-contrast-A400: #ffffff;
  --color-accent-contrast-A400-rgb: 255, 255, 255;
  --color-accent-contrast-A700: #ffffff;
  --color-accent-contrast-A700-rgb: 255, 255, 255;
  --color-grayscale: #707070;
  --color-grayscale-50: #f2f2f2;
  --color-grayscale-50-rgb: 242, 242, 242;
  --color-grayscale-100: #eeeeee;
  --color-grayscale-100-rgb: 238, 238, 238;
  --color-grayscale-200: #d1d1d1;
  --color-grayscale-200-rgb: 209, 209, 209;
  --color-grayscale-300: #a6a6a6;
  --color-grayscale-300-rgb: 166, 166, 166;
  --color-grayscale-400: #868686;
  --color-grayscale-400-rgb: 134, 134, 134;
  --color-grayscale-500: #707070;
  --color-grayscale-500-rgb: 112, 112, 112;
  --color-grayscale-600: #686868;
  --color-grayscale-600-rgb: 104, 104, 104;
  --color-grayscale-700: #404040;
  --color-grayscale-700-rgb: 64, 64, 64;
  --color-grayscale-800: #333333;
  --color-grayscale-800-rgb: 51, 51, 51;
  --color-grayscale-900: #262626;
  --color-grayscale-900-rgb: 38, 38, 38;
  --color-grayscale-A100: #FFFFFF;
  --color-grayscale-A100-rgb: 255, 255, 255;
  --color-grayscale-A200: #222222;
  --color-grayscale-A200-rgb: 34, 34, 34;
  --color-grayscale-A400: #111111;
  --color-grayscale-A400-rgb: 17, 17, 17;
  --color-grayscale-A700: #000000;
  --color-grayscale-A700-rgb: 0, 0, 0;
  --color-grayscale-contrast-50: #ffffff;
  --color-grayscale-contrast-50-rgb: 255, 255, 255;
  --color-grayscale-contrast-100: #ffffff;
  --color-grayscale-contrast-100-rgb: 255, 255, 255;
  --color-grayscale-contrast-200: #ffffff;
  --color-grayscale-contrast-200-rgb: 255, 255, 255;
  --color-grayscale-contrast-300: #ffffff;
  --color-grayscale-contrast-300-rgb: 255, 255, 255;
  --color-grayscale-contrast-400: #ffffff;
  --color-grayscale-contrast-400-rgb: 255, 255, 255;
  --color-grayscale-contrast-500: #ffffff;
  --color-grayscale-contrast-500-rgb: 255, 255, 255;
  --color-grayscale-contrast-600: #ffffff;
  --color-grayscale-contrast-600-rgb: 255, 255, 255;
  --color-grayscale-contrast-700: #ffffff;
  --color-grayscale-contrast-700-rgb: 255, 255, 255;
  --color-grayscale-contrast-800: #ffffff;
  --color-grayscale-contrast-800-rgb: 255, 255, 255;
  --color-grayscale-contrast-900: #ffffff;
  --color-grayscale-contrast-900-rgb: 255, 255, 255;
  --color-grayscale-contrast-A100: #ffffff;
  --color-grayscale-contrast-A100-rgb: 255, 255, 255;
  --color-grayscale-contrast-A200: #ffffff;
  --color-grayscale-contrast-A200-rgb: 255, 255, 255;
  --color-grayscale-contrast-A400: #ffffff;
  --color-grayscale-contrast-A400-rgb: 255, 255, 255;
  --color-grayscale-contrast-A700: #ffffff;
  --color-grayscale-contrast-A700-rgb: 255, 255, 255;
  --color-cyan: #509da9;
  --color-cyan-50: #eef6f7;
  --color-cyan-50-rgb: 238, 246, 247;
  --color-cyan-100: #cbe3e6;
  --color-cyan-100-rgb: 203, 227, 230;
  --color-cyan-200: #a8d0d6;
  --color-cyan-200-rgb: 168, 208, 214;
  --color-cyan-300: #86bdc6;
  --color-cyan-300-rgb: 134, 189, 198;
  --color-cyan-400: #63aab5;
  --color-cyan-400-rgb: 99, 170, 181;
  --color-cyan-500: #509da9;
  --color-cyan-500-rgb: 80, 157, 169;
  --color-cyan-600: #4a919c;
  --color-cyan-600-rgb: 74, 145, 156;
  --color-cyan-700: #397179;
  --color-cyan-700-rgb: 57, 113, 121;
  --color-cyan-contrast-50: #ffffff;
  --color-cyan-contrast-50-rgb: 255, 255, 255;
  --color-cyan-contrast-100: #ffffff;
  --color-cyan-contrast-100-rgb: 255, 255, 255;
  --color-cyan-contrast-200: #ffffff;
  --color-cyan-contrast-200-rgb: 255, 255, 255;
  --color-cyan-contrast-300: #ffffff;
  --color-cyan-contrast-300-rgb: 255, 255, 255;
  --color-cyan-contrast-400: #ffffff;
  --color-cyan-contrast-400-rgb: 255, 255, 255;
  --color-cyan-contrast-500: #ffffff;
  --color-cyan-contrast-500-rgb: 255, 255, 255;
  --color-cyan-contrast-600: #ffffff;
  --color-cyan-contrast-600-rgb: 255, 255, 255;
  --color-cyan-contrast-700: #ffffff;
  --color-cyan-contrast-700-rgb: 255, 255, 255;
  --color-cyan-contrast-800: #ffffff;
  --color-cyan-contrast-800-rgb: 255, 255, 255;
  --color-cyan-contrast-900: #ffffff;
  --color-cyan-contrast-900-rgb: 255, 255, 255;
  --color-cyan-contrast-A100: #ffffff;
  --color-cyan-contrast-A100-rgb: 255, 255, 255;
  --color-cyan-contrast-A200: #ffffff;
  --color-cyan-contrast-A200-rgb: 255, 255, 255;
  --color-cyan-contrast-A400: #ffffff;
  --color-cyan-contrast-A400-rgb: 255, 255, 255;
  --color-cyan-contrast-A700: #ffffff;
  --color-cyan-contrast-A700-rgb: 255, 255, 255;
  --color-green: #2d836a;
  --color-green-50: #e6f0ed;
  --color-green-50-rgb: 230, 240, 237;
  --color-green-100: #c0dad2;
  --color-green-100-rgb: 192, 218, 210;
  --color-green-200: #96c1b5;
  --color-green-200-rgb: 150, 193, 181;
  --color-green-300: #6ca897;
  --color-green-300-rgb: 108, 168, 151;
  --color-green-400: #4d9680;
  --color-green-400-rgb: 77, 150, 128;
  --color-green-500: #2d836a;
  --color-green-500-rgb: 45, 131, 106;
  --color-green-600: #287b62;
  --color-green-600-rgb: 40, 123, 98;
  --color-green-700: #227057;
  --color-green-700-rgb: 34, 112, 87;
  --color-green-800: #1c664d;
  --color-green-800-rgb: 28, 102, 77;
  --color-green-900: #11533c;
  --color-green-900-rgb: 17, 83, 60;
  --color-green-A100: #8bffd3;
  --color-green-A100-rgb: 139, 255, 211;
  --color-green-A200: #58ffbf;
  --color-green-A200-rgb: 88, 255, 191;
  --color-green-A400: #25ffac;
  --color-green-A400-rgb: 37, 255, 172;
  --color-green-A700: #0cffa2;
  --color-green-A700-rgb: 12, 255, 162;
  --color-green-contrast-50: #ffffff;
  --color-green-contrast-50-rgb: 255, 255, 255;
  --color-green-contrast-100: #ffffff;
  --color-green-contrast-100-rgb: 255, 255, 255;
  --color-green-contrast-200: #ffffff;
  --color-green-contrast-200-rgb: 255, 255, 255;
  --color-green-contrast-300: #ffffff;
  --color-green-contrast-300-rgb: 255, 255, 255;
  --color-green-contrast-400: #ffffff;
  --color-green-contrast-400-rgb: 255, 255, 255;
  --color-green-contrast-500: #ffffff;
  --color-green-contrast-500-rgb: 255, 255, 255;
  --color-green-contrast-600: #ffffff;
  --color-green-contrast-600-rgb: 255, 255, 255;
  --color-green-contrast-700: #ffffff;
  --color-green-contrast-700-rgb: 255, 255, 255;
  --color-green-contrast-800: #ffffff;
  --color-green-contrast-800-rgb: 255, 255, 255;
  --color-green-contrast-900: #ffffff;
  --color-green-contrast-900-rgb: 255, 255, 255;
  --color-green-contrast-A100: #ffffff;
  --color-green-contrast-A100-rgb: 255, 255, 255;
  --color-green-contrast-A200: #ffffff;
  --color-green-contrast-A200-rgb: 255, 255, 255;
  --color-green-contrast-A400: #ffffff;
  --color-green-contrast-A400-rgb: 255, 255, 255;
  --color-green-contrast-A700: #ffffff;
  --color-green-contrast-A700-rgb: 255, 255, 255;
  --color-system-info: #0c6ccc;
  --color-system-info-50: #e2edf9;
  --color-system-info-50-rgb: 226, 237, 249;
  --color-system-info-100: #b6d3f0;
  --color-system-info-100-rgb: 182, 211, 240;
  --color-system-info-200: #86b6e6;
  --color-system-info-200-rgb: 134, 182, 230;
  --color-system-info-300: #5598db;
  --color-system-info-300-rgb: 85, 152, 219;
  --color-system-info-400: #3082d4;
  --color-system-info-400-rgb: 48, 130, 212;
  --color-system-info-500: #0c6ccc;
  --color-system-info-500-rgb: 12, 108, 204;
  --color-system-info-600: #0a64c7;
  --color-system-info-600-rgb: 10, 100, 199;
  --color-system-info-700: #0859c0;
  --color-system-info-700-rgb: 8, 89, 192;
  --color-system-info-800: #064fb9;
  --color-system-info-800-rgb: 6, 79, 185;
  --color-system-info-900: #033dad;
  --color-system-info-900-rgb: 3, 61, 173;
  --color-system-info-A100: #d7e2ff;
  --color-system-info-A100-rgb: 215, 226, 255;
  --color-system-info-A200: #a4bdff;
  --color-system-info-A200-rgb: 164, 189, 255;
  --color-system-info-A400: #7198ff;
  --color-system-info-A400-rgb: 113, 152, 255;
  --color-system-info-A700: #5886ff;
  --color-system-info-A700-rgb: 88, 134, 255;
  --color-system-info-contrast-50: #ffffff;
  --color-system-info-contrast-50-rgb: 255, 255, 255;
  --color-system-info-contrast-100: #ffffff;
  --color-system-info-contrast-100-rgb: 255, 255, 255;
  --color-system-info-contrast-200: #ffffff;
  --color-system-info-contrast-200-rgb: 255, 255, 255;
  --color-system-info-contrast-300: #ffffff;
  --color-system-info-contrast-300-rgb: 255, 255, 255;
  --color-system-info-contrast-400: #ffffff;
  --color-system-info-contrast-400-rgb: 255, 255, 255;
  --color-system-info-contrast-500: #ffffff;
  --color-system-info-contrast-500-rgb: 255, 255, 255;
  --color-system-info-contrast-600: #ffffff;
  --color-system-info-contrast-600-rgb: 255, 255, 255;
  --color-system-info-contrast-700: #ffffff;
  --color-system-info-contrast-700-rgb: 255, 255, 255;
  --color-system-info-contrast-800: #ffffff;
  --color-system-info-contrast-800-rgb: 255, 255, 255;
  --color-system-info-contrast-900: #ffffff;
  --color-system-info-contrast-900-rgb: 255, 255, 255;
  --color-system-info-contrast-A100: #ffffff;
  --color-system-info-contrast-A100-rgb: 255, 255, 255;
  --color-system-info-contrast-A200: #ffffff;
  --color-system-info-contrast-A200-rgb: 255, 255, 255;
  --color-system-info-contrast-A400: #ffffff;
  --color-system-info-contrast-A400-rgb: 255, 255, 255;
  --color-system-info-contrast-A700: #ffffff;
  --color-system-info-contrast-A700-rgb: 255, 255, 255;
  --color-system-error: #ff5251;
  --color-system-error-50: #ffeaea;
  --color-system-error-50-rgb: 255, 234, 234;
  --color-system-error-100: #ffcbcb;
  --color-system-error-100-rgb: 255, 203, 203;
  --color-system-error-200: #ffa9a8;
  --color-system-error-200-rgb: 255, 169, 168;
  --color-system-error-300: #ff8685;
  --color-system-error-300-rgb: 255, 134, 133;
  --color-system-error-400: #ff6c6b;
  --color-system-error-400-rgb: 255, 108, 107;
  --color-system-error-500: #ff5251;
  --color-system-error-500-rgb: 255, 82, 81;
  --color-system-error-600: #ff4b4a;
  --color-system-error-600-rgb: 255, 75, 74;
  --color-system-error-700: #ff4140;
  --color-system-error-700-rgb: 255, 65, 64;
  --color-system-error-800: #ff3837;
  --color-system-error-800-rgb: 255, 56, 55;
  --color-system-error-900: #ff2827;
  --color-system-error-900-rgb: 255, 40, 39;
  --color-system-error-A100: #ffffff;
  --color-system-error-A100-rgb: 255, 255, 255;
  --color-system-error-A200: #ffffff;
  --color-system-error-A200-rgb: 255, 255, 255;
  --color-system-error-A400: #ffffff;
  --color-system-error-A400-rgb: 255, 255, 255;
  --color-system-error-A700: #ffffff;
  --color-system-error-A700-rgb: 255, 255, 255;
  --color-system-error-contrast-50: #ffffff;
  --color-system-error-contrast-50-rgb: 255, 255, 255;
  --color-system-error-contrast-100: #ffffff;
  --color-system-error-contrast-100-rgb: 255, 255, 255;
  --color-system-error-contrast-200: #ffffff;
  --color-system-error-contrast-200-rgb: 255, 255, 255;
  --color-system-error-contrast-300: #ffffff;
  --color-system-error-contrast-300-rgb: 255, 255, 255;
  --color-system-error-contrast-400: #ffffff;
  --color-system-error-contrast-400-rgb: 255, 255, 255;
  --color-system-error-contrast-500: #ffffff;
  --color-system-error-contrast-500-rgb: 255, 255, 255;
  --color-system-error-contrast-600: #ffffff;
  --color-system-error-contrast-600-rgb: 255, 255, 255;
  --color-system-error-contrast-700: #ffffff;
  --color-system-error-contrast-700-rgb: 255, 255, 255;
  --color-system-error-contrast-800: #ffffff;
  --color-system-error-contrast-800-rgb: 255, 255, 255;
  --color-system-error-contrast-900: #ffffff;
  --color-system-error-contrast-900-rgb: 255, 255, 255;
  --color-system-error-contrast-A100: #ffffff;
  --color-system-error-contrast-A100-rgb: 255, 255, 255;
  --color-system-error-contrast-A200: #ffffff;
  --color-system-error-contrast-A200-rgb: 255, 255, 255;
  --color-system-error-contrast-A400: #ffffff;
  --color-system-error-contrast-A400-rgb: 255, 255, 255;
  --color-system-error-contrast-A700: #ffffff;
  --color-system-error-contrast-A700-rgb: 255, 255, 255;
  --color-system-success: #00ad43;
  --color-system-success-50: #e6f2e6;
  --color-system-success-50-rgb: 230, 242, 230;
  --color-system-success-100: #b3e6c7;
  --color-system-success-100-rgb: 179, 230, 199;
  --color-system-success-200: #80d6a1;
  --color-system-success-200-rgb: 128, 214, 161;
  --color-system-success-300: #4dc67b;
  --color-system-success-300-rgb: 77, 198, 123;
  --color-system-success-400: #26b95f;
  --color-system-success-400-rgb: 38, 185, 95;
  --color-system-success-500: #00ad43;
  --color-system-success-500-rgb: 0, 173, 67;
  --color-system-success-600: #00a63d;
  --color-system-success-600-rgb: 0, 166, 61;
  --color-system-success-700: #009c34;
  --color-system-success-700-rgb: 0, 156, 52;
  --color-system-success-800: #00932c;
  --color-system-success-800-rgb: 0, 147, 44;
  --color-system-success-900: #00831e;
  --color-system-success-900-rgb: 0, 131, 30;
  --color-system-success-A100: #b0ffbb;
  --color-system-success-A100-rgb: 176, 255, 187;
  --color-system-success-A200: #7dff90;
  --color-system-success-A200-rgb: 125, 255, 144;
  --color-system-success-A400: #4aff64;
  --color-system-success-A400-rgb: 74, 255, 100;
  --color-system-success-A700: #30ff4e;
  --color-system-success-A700-rgb: 48, 255, 78;
  --color-system-success-contrast-50: #ffffff;
  --color-system-success-contrast-50-rgb: 255, 255, 255;
  --color-system-success-contrast-100: #ffffff;
  --color-system-success-contrast-100-rgb: 255, 255, 255;
  --color-system-success-contrast-200: #ffffff;
  --color-system-success-contrast-200-rgb: 255, 255, 255;
  --color-system-success-contrast-300: #ffffff;
  --color-system-success-contrast-300-rgb: 255, 255, 255;
  --color-system-success-contrast-400: #ffffff;
  --color-system-success-contrast-400-rgb: 255, 255, 255;
  --color-system-success-contrast-500: #ffffff;
  --color-system-success-contrast-500-rgb: 255, 255, 255;
  --color-system-success-contrast-600: #ffffff;
  --color-system-success-contrast-600-rgb: 255, 255, 255;
  --color-system-success-contrast-700: #ffffff;
  --color-system-success-contrast-700-rgb: 255, 255, 255;
  --color-system-success-contrast-800: #ffffff;
  --color-system-success-contrast-800-rgb: 255, 255, 255;
  --color-system-success-contrast-900: #ffffff;
  --color-system-success-contrast-900-rgb: 255, 255, 255;
  --color-system-success-contrast-A100: #ffffff;
  --color-system-success-contrast-A100-rgb: 255, 255, 255;
  --color-system-success-contrast-A200: #ffffff;
  --color-system-success-contrast-A200-rgb: 255, 255, 255;
  --color-system-success-contrast-A400: #ffffff;
  --color-system-success-contrast-A400-rgb: 255, 255, 255;
  --color-system-success-contrast-A700: #ffffff;
  --color-system-success-contrast-A700-rgb: 255, 255, 255;
  --color-system-warning: #ffc107;
  --color-system-warning-50: #fff8e1;
  --color-system-warning-50-rgb: 255, 248, 225;
  --color-system-warning-100: #ffecb5;
  --color-system-warning-100-rgb: 255, 236, 181;
  --color-system-warning-200: #ffe083;
  --color-system-warning-200-rgb: 255, 224, 131;
  --color-system-warning-300: #ffd451;
  --color-system-warning-300-rgb: 255, 212, 81;
  --color-system-warning-400: #ffca2c;
  --color-system-warning-400-rgb: 255, 202, 44;
  --color-system-warning-500: #ffc107;
  --color-system-warning-500-rgb: 255, 193, 7;
  --color-system-warning-600: #ffbb06;
  --color-system-warning-600-rgb: 255, 187, 6;
  --color-system-warning-700: #ffb305;
  --color-system-warning-700-rgb: 255, 179, 5;
  --color-system-warning-800: #ffab04;
  --color-system-warning-800-rgb: 255, 171, 4;
  --color-system-warning-900: #ff9e02;
  --color-system-warning-900-rgb: 255, 158, 2;
  --color-system-warning-A100: #ffffff;
  --color-system-warning-A100-rgb: 255, 255, 255;
  --color-system-warning-A200: #fffaf2;
  --color-system-warning-A200-rgb: 255, 250, 242;
  --color-system-warning-A400: #ffe4bf;
  --color-system-warning-A400-rgb: 255, 228, 191;
  --color-system-warning-A700: #ffd9a6;
  --color-system-warning-A700-rgb: 255, 217, 166;
  --color-system-warning-contrast-50: #000000;
  --color-system-warning-contrast-50-rgb: 0, 0, 0;
  --color-system-warning-contrast-100: #000000;
  --color-system-warning-contrast-100-rgb: 0, 0, 0;
  --color-system-warning-contrast-200: #000000;
  --color-system-warning-contrast-200-rgb: 0, 0, 0;
  --color-system-warning-contrast-300: #000000;
  --color-system-warning-contrast-300-rgb: 0, 0, 0;
  --color-system-warning-contrast-400: #000000;
  --color-system-warning-contrast-400-rgb: 0, 0, 0;
  --color-system-warning-contrast-500: #000000;
  --color-system-warning-contrast-500-rgb: 0, 0, 0;
  --color-system-warning-contrast-600: #000000;
  --color-system-warning-contrast-600-rgb: 0, 0, 0;
  --color-system-warning-contrast-700: #000000;
  --color-system-warning-contrast-700-rgb: 0, 0, 0;
  --color-system-warning-contrast-800: #000000;
  --color-system-warning-contrast-800-rgb: 0, 0, 0;
  --color-system-warning-contrast-900: #000000;
  --color-system-warning-contrast-900-rgb: 0, 0, 0;
  --color-system-warning-contrast-A100: #000000;
  --color-system-warning-contrast-A100-rgb: 0, 0, 0;
  --color-system-warning-contrast-A200: #000000;
  --color-system-warning-contrast-A200-rgb: 0, 0, 0;
  --color-system-warning-contrast-A400: #000000;
  --color-system-warning-contrast-A400-rgb: 0, 0, 0;
  --color-system-warning-contrast-A700: #000000;
  --color-system-warning-contrast-A700-rgb: 0, 0, 0;
  --color-system-inactive: #707070;
  --color-red: #ff0200;
  --color-svg-default: #707070;
  --color-white: #ffffff;
  --color-transparent: transparent;
  --color-text-normal: #333333;
  --color-text-normal-inverted: #FFFFFF;
  --color-text-weak: #707070;
  --color-text-weaker-medium: #868686;
  --color-text-weaker: #a6a6a6;
  --color-text-strong: #000000;
  --color-link-normal: #0c6ccc;
  --color-link-hover: #86b6e6;
  --color-background-primary-weak: var(--color-primary-100);
  --color-background-normal: var(--color-grayscale-A100);
  --color-background-normal-rgb: var(--color-grayscale-A100-rgb);
  --color-background-normal-inverted: #333333;
  --color-background-strong: var(--color-grayscale);
  --color-background-medium: #d1d1d1;
  --color-background-weak: #f2f2f2;
  --color-background-info-weak: #e2edf9;
  --color-border-strongest: #000000;
  --color-border-strong: #333333;
  --color-border-normal: #d1d1d1;
  --color-border-normal-inverted: #FFFFFF;
  --color-border-weak: #f2f2f2;
  --color-border-transparent: transparent;
  --color-icon-primary: var(--color-primary);
  --color-icon-accent: var(--color-accent);
  --color-icon-normal: var(--color-grayscale-500);
  --color-icon-weak: var(--color-grayscale-400);
  --color-icon-weak-hover: var(--color-grayscale-300);
  --color-icon-inverted: var(--color-text-normal-inverted);
  --color-icon-text-normal: var(--color-text-normal);
  --color-icon-text-normal-inverted: var(--color-text-normal-inverted);
  --color-icon-text-weak: var(--color-text-weak);
  --color-form-input-border-normal: #333333;
  --color-form-input-border-hover: #333333;
  --color-form-input-border-focus: #333333;
  --color-form-input-border-disabled: #333333;
  --color-form-input-border-error: #333333;
  --color-form-input-border-success: #333333;
  --color-bui-button-background-primary: #ff5251;
  --color-bui-button-background-primary-hover: #ffa9a8;
  --color-bui-button-background-secondary: #3dbfb8;
  --color-bui-button-background-secondary-hover: #80e3dd;
  --color-bui-button-background-accent: #3dbfb8;
  --color-bui-button-background-accent-hover: #80e3dd;
  --color-bui-button-background-error: #ff5251;
  --color-bui-button-background-error-hover: #ffa9a8;
  --color-bui-button-background-normal: #333333;
  --color-bui-button-background-normal-hover: #707070;
  --color-bui-button-background-weak: #f2f2f2;
  --color-bui-button-background-weak-hover: #d1d1d1;
  --color-bui-button-background-inverted: #FFFFFF;
  --color-bui-button-background-inverted-hover: #f2f2f2;
  --color-bui-button-background-transparent: transparent;
  --color-bui-button-text-primary: #ff5251;
  --color-bui-button-text-primary-hover: #ffa9a8;
  --color-bui-button-text-accent: #3dbfb8;
  --color-bui-button-text-accent-hover: #80e3dd;
  --color-bui-button-text-tertiary: #36468f;
  --color-bui-button-text-tertiary-hover: #9ba3c7;
  --color-bui-button-text-link: #0c6ccc;
  --color-bui-button-text-link-hover: #86b6e6;
  --color-bui-button-text-normal: #707070;
  --color-bui-button-text-normal-hover: #707070;
  --color-bui-button-text-weak: #707070;
  --color-bui-button-text-weak-hover: #d1d1d1;
  --color-bui-button-text-inverted: #FFFFFF;
  --color-bui-button-text-inverted-hover: #f2f2f2;
  --color-bui-button-text-status-success: var(--color-system-success);
  --color-bui-button-text-status-success-hover: var(--color-system-success-200);
  --color-bui-button-text-status-error: var(--color-system-error);
  --color-bui-button-text-status-error-hover: var(--color-system-error-200);
  --color-bui-button-icon-weak: var(--color-grayscale-400);
  --color-bui-button-icon-weak-hover: var(--color-grayscale-400);
  --color-bui-button-icon-success: var(--color-system-success-900);
  --color-bui-button-icon-success-hover: var(--color-system-success-900);
  --color-table-head-row-background-normal: #ffcbcb;
  --color-table-body-row-background: #FFFFFF;
  --color-table-body-row-background-selected: #e6f2e6;
}

.theme-system-info {
  --color-primary: #0c6ccc;
  --color-primary-50: #e2edf9;
  --color-primary-50-rgb: 226, 237, 249;
  --color-primary-100: #b6d3f0;
  --color-primary-100-rgb: 182, 211, 240;
  --color-primary-200: #86b6e6;
  --color-primary-200-rgb: 134, 182, 230;
  --color-primary-300: #5598db;
  --color-primary-300-rgb: 85, 152, 219;
  --color-primary-400: #3082d4;
  --color-primary-400-rgb: 48, 130, 212;
  --color-primary-500: #0c6ccc;
  --color-primary-500-rgb: 12, 108, 204;
  --color-primary-600: #0a64c7;
  --color-primary-600-rgb: 10, 100, 199;
  --color-primary-700: #0859c0;
  --color-primary-700-rgb: 8, 89, 192;
  --color-primary-800: #064fb9;
  --color-primary-800-rgb: 6, 79, 185;
  --color-primary-900: #033dad;
  --color-primary-900-rgb: 3, 61, 173;
  --color-primary-A100: #d7e2ff;
  --color-primary-A100-rgb: 215, 226, 255;
  --color-primary-A200: #a4bdff;
  --color-primary-A200-rgb: 164, 189, 255;
  --color-primary-A400: #7198ff;
  --color-primary-A400-rgb: 113, 152, 255;
  --color-primary-A700: #5886ff;
  --color-primary-A700-rgb: 88, 134, 255;
  --color-primary-contrast-50: #ffffff;
  --color-primary-contrast-50-rgb: 255, 255, 255;
  --color-primary-contrast-100: #ffffff;
  --color-primary-contrast-100-rgb: 255, 255, 255;
  --color-primary-contrast-200: #ffffff;
  --color-primary-contrast-200-rgb: 255, 255, 255;
  --color-primary-contrast-300: #ffffff;
  --color-primary-contrast-300-rgb: 255, 255, 255;
  --color-primary-contrast-400: #ffffff;
  --color-primary-contrast-400-rgb: 255, 255, 255;
  --color-primary-contrast-500: #ffffff;
  --color-primary-contrast-500-rgb: 255, 255, 255;
  --color-primary-contrast-600: #ffffff;
  --color-primary-contrast-600-rgb: 255, 255, 255;
  --color-primary-contrast-700: #ffffff;
  --color-primary-contrast-700-rgb: 255, 255, 255;
  --color-primary-contrast-800: #ffffff;
  --color-primary-contrast-800-rgb: 255, 255, 255;
  --color-primary-contrast-900: #ffffff;
  --color-primary-contrast-900-rgb: 255, 255, 255;
  --color-primary-contrast-A100: #ffffff;
  --color-primary-contrast-A100-rgb: 255, 255, 255;
  --color-primary-contrast-A200: #ffffff;
  --color-primary-contrast-A200-rgb: 255, 255, 255;
  --color-primary-contrast-A400: #ffffff;
  --color-primary-contrast-A400-rgb: 255, 255, 255;
  --color-primary-contrast-A700: #ffffff;
  --color-primary-contrast-A700-rgb: 255, 255, 255;
  --color-secondary: #3dbfb8;
  --color-secondary-50: #e0f8f7;
  --color-secondary-50-rgb: 224, 248, 247;
  --color-secondary-100: #b3eeea;
  --color-secondary-100-rgb: 179, 238, 234;
  --color-secondary-200: #80e3dd;
  --color-secondary-200-rgb: 128, 227, 221;
  --color-secondary-300: #4dd8cf;
  --color-secondary-300-rgb: 77, 216, 207;
  --color-secondary-400: #26cfc4;
  --color-secondary-400-rgb: 38, 207, 196;
  --color-secondary-500: #3dbfb8;
  --color-secondary-500-rgb: 61, 191, 184;
  --color-secondary-600: #00c1b3;
  --color-secondary-600-rgb: 0, 193, 179;
  --color-secondary-700: #00baab;
  --color-secondary-700-rgb: 0, 186, 171;
  --color-secondary-800: #00b3a3;
  --color-secondary-800-rgb: 0, 179, 163;
  --color-secondary-900: #00a694;
  --color-secondary-900-rgb: 0, 166, 148;
  --color-secondary-A100: #d0fff9;
  --color-secondary-A100-rgb: 208, 255, 249;
  --color-secondary-A200: #9dfff2;
  --color-secondary-A200-rgb: 157, 255, 242;
  --color-secondary-A400: #6affec;
  --color-secondary-A400-rgb: 106, 255, 236;
  --color-secondary-A700: #51ffe9;
  --color-secondary-A700-rgb: 81, 255, 233;
  --color-secondary-contrast-50: #ffffff;
  --color-secondary-contrast-50-rgb: 255, 255, 255;
  --color-secondary-contrast-100: #ffffff;
  --color-secondary-contrast-100-rgb: 255, 255, 255;
  --color-secondary-contrast-200: #ffffff;
  --color-secondary-contrast-200-rgb: 255, 255, 255;
  --color-secondary-contrast-300: #ffffff;
  --color-secondary-contrast-300-rgb: 255, 255, 255;
  --color-secondary-contrast-400: #ffffff;
  --color-secondary-contrast-400-rgb: 255, 255, 255;
  --color-secondary-contrast-500: #ffffff;
  --color-secondary-contrast-500-rgb: 255, 255, 255;
  --color-secondary-contrast-600: #ffffff;
  --color-secondary-contrast-600-rgb: 255, 255, 255;
  --color-secondary-contrast-700: #ffffff;
  --color-secondary-contrast-700-rgb: 255, 255, 255;
  --color-secondary-contrast-800: #ffffff;
  --color-secondary-contrast-800-rgb: 255, 255, 255;
  --color-secondary-contrast-900: #ffffff;
  --color-secondary-contrast-900-rgb: 255, 255, 255;
  --color-secondary-contrast-A100: #ffffff;
  --color-secondary-contrast-A100-rgb: 255, 255, 255;
  --color-secondary-contrast-A200: #ffffff;
  --color-secondary-contrast-A200-rgb: 255, 255, 255;
  --color-secondary-contrast-A400: #ffffff;
  --color-secondary-contrast-A400-rgb: 255, 255, 255;
  --color-secondary-contrast-A700: #ffffff;
  --color-secondary-contrast-A700-rgb: 255, 255, 255;
  --color-tertiary: #36468f;
  --color-tertiary-50: #e7e9f2;
  --color-tertiary-50-rgb: 231, 233, 242;
  --color-tertiary-100: #c3c8dd;
  --color-tertiary-100-rgb: 195, 200, 221;
  --color-tertiary-200: #9ba3c7;
  --color-tertiary-200-rgb: 155, 163, 199;
  --color-tertiary-300: #727eb1;
  --color-tertiary-300-rgb: 114, 126, 177;
  --color-tertiary-400: #5462a0;
  --color-tertiary-400-rgb: 84, 98, 160;
  --color-tertiary-500: #36468f;
  --color-tertiary-500-rgb: 54, 70, 143;
  --color-tertiary-600: #303f87;
  --color-tertiary-600-rgb: 48, 63, 135;
  --color-tertiary-700: #29377c;
  --color-tertiary-700-rgb: 41, 55, 124;
  --color-tertiary-800: #222f72;
  --color-tertiary-800-rgb: 34, 47, 114;
  --color-tertiary-900: #162060;
  --color-tertiary-900-rgb: 22, 32, 96;
  --color-tertiary-A100: #9aa6ff;
  --color-tertiary-A100-rgb: 154, 166, 255;
  --color-tertiary-A200: #6778ff;
  --color-tertiary-A200-rgb: 103, 120, 255;
  --color-tertiary-A400: #344bff;
  --color-tertiary-A400-rgb: 52, 75, 255;
  --color-tertiary-A700: #1a35ff;
  --color-tertiary-A700-rgb: 26, 53, 255;
  --color-tertiary-contrast-50: #ffffff;
  --color-tertiary-contrast-50-rgb: 255, 255, 255;
  --color-tertiary-contrast-100: #ffffff;
  --color-tertiary-contrast-100-rgb: 255, 255, 255;
  --color-tertiary-contrast-200: #ffffff;
  --color-tertiary-contrast-200-rgb: 255, 255, 255;
  --color-tertiary-contrast-300: #ffffff;
  --color-tertiary-contrast-300-rgb: 255, 255, 255;
  --color-tertiary-contrast-400: #ffffff;
  --color-tertiary-contrast-400-rgb: 255, 255, 255;
  --color-tertiary-contrast-500: #ffffff;
  --color-tertiary-contrast-500-rgb: 255, 255, 255;
  --color-tertiary-contrast-600: #ffffff;
  --color-tertiary-contrast-600-rgb: 255, 255, 255;
  --color-tertiary-contrast-700: #ffffff;
  --color-tertiary-contrast-700-rgb: 255, 255, 255;
  --color-tertiary-contrast-800: #ffffff;
  --color-tertiary-contrast-800-rgb: 255, 255, 255;
  --color-tertiary-contrast-900: #ffffff;
  --color-tertiary-contrast-900-rgb: 255, 255, 255;
  --color-tertiary-contrast-A100: #ffffff;
  --color-tertiary-contrast-A100-rgb: 255, 255, 255;
  --color-tertiary-contrast-A200: #ffffff;
  --color-tertiary-contrast-A200-rgb: 255, 255, 255;
  --color-tertiary-contrast-A400: #ffffff;
  --color-tertiary-contrast-A400-rgb: 255, 255, 255;
  --color-tertiary-contrast-A700: #ffffff;
  --color-tertiary-contrast-A700-rgb: 255, 255, 255;
  --color-quaternary: #fea223;
  --color-quaternary-50: #fff4e5;
  --color-quaternary-50-rgb: 255, 244, 229;
  --color-quaternary-100: #ffe3bd;
  --color-quaternary-100-rgb: 255, 227, 189;
  --color-quaternary-200: #ffd191;
  --color-quaternary-200-rgb: 255, 209, 145;
  --color-quaternary-300: #febe65;
  --color-quaternary-300-rgb: 254, 190, 101;
  --color-quaternary-400: #feb044;
  --color-quaternary-400-rgb: 254, 176, 68;
  --color-quaternary-500: #fea223;
  --color-quaternary-500-rgb: 254, 162, 35;
  --color-quaternary-600: #fe9a1f;
  --color-quaternary-600-rgb: 254, 154, 31;
  --color-quaternary-700: #fe901a;
  --color-quaternary-700-rgb: 254, 144, 26;
  --color-quaternary-800: #fe8615;
  --color-quaternary-800-rgb: 254, 134, 21;
  --color-quaternary-900: #fd750c;
  --color-quaternary-900-rgb: 253, 117, 12;
  --color-quaternary-A100: #ffffff;
  --color-quaternary-A100-rgb: 255, 255, 255;
  --color-quaternary-A200: #fff8f4;
  --color-quaternary-A200-rgb: 255, 248, 244;
  --color-quaternary-A400: #ffd9c1;
  --color-quaternary-A400-rgb: 255, 217, 193;
  --color-quaternary-A700: #ffcaa7;
  --color-quaternary-A700-rgb: 255, 202, 167;
  --color-quaternary-contrast-50: #ffffff;
  --color-quaternary-contrast-50-rgb: 255, 255, 255;
  --color-quaternary-contrast-100: #ffffff;
  --color-quaternary-contrast-100-rgb: 255, 255, 255;
  --color-quaternary-contrast-200: #ffffff;
  --color-quaternary-contrast-200-rgb: 255, 255, 255;
  --color-quaternary-contrast-300: #ffffff;
  --color-quaternary-contrast-300-rgb: 255, 255, 255;
  --color-quaternary-contrast-400: #ffffff;
  --color-quaternary-contrast-400-rgb: 255, 255, 255;
  --color-quaternary-contrast-500: #ffffff;
  --color-quaternary-contrast-500-rgb: 255, 255, 255;
  --color-quaternary-contrast-600: #ffffff;
  --color-quaternary-contrast-600-rgb: 255, 255, 255;
  --color-quaternary-contrast-700: #ffffff;
  --color-quaternary-contrast-700-rgb: 255, 255, 255;
  --color-quaternary-contrast-800: #ffffff;
  --color-quaternary-contrast-800-rgb: 255, 255, 255;
  --color-quaternary-contrast-900: #ffffff;
  --color-quaternary-contrast-900-rgb: 255, 255, 255;
  --color-quaternary-contrast-A100: #ffffff;
  --color-quaternary-contrast-A100-rgb: 255, 255, 255;
  --color-quaternary-contrast-A200: #ffffff;
  --color-quaternary-contrast-A200-rgb: 255, 255, 255;
  --color-quaternary-contrast-A400: #ffffff;
  --color-quaternary-contrast-A400-rgb: 255, 255, 255;
  --color-quaternary-contrast-A700: #ffffff;
  --color-quaternary-contrast-A700-rgb: 255, 255, 255;
  --color-accent: #3dbfb8;
  --color-accent-50: #e0f8f7;
  --color-accent-50-rgb: 224, 248, 247;
  --color-accent-100: #b3eeea;
  --color-accent-100-rgb: 179, 238, 234;
  --color-accent-200: #80e3dd;
  --color-accent-200-rgb: 128, 227, 221;
  --color-accent-300: #4dd8cf;
  --color-accent-300-rgb: 77, 216, 207;
  --color-accent-400: #26cfc4;
  --color-accent-400-rgb: 38, 207, 196;
  --color-accent-500: #3dbfb8;
  --color-accent-500-rgb: 61, 191, 184;
  --color-accent-600: #00c1b3;
  --color-accent-600-rgb: 0, 193, 179;
  --color-accent-700: #00baab;
  --color-accent-700-rgb: 0, 186, 171;
  --color-accent-800: #00b3a3;
  --color-accent-800-rgb: 0, 179, 163;
  --color-accent-900: #00a694;
  --color-accent-900-rgb: 0, 166, 148;
  --color-accent-A100: #d0fff9;
  --color-accent-A100-rgb: 208, 255, 249;
  --color-accent-A200: #9dfff2;
  --color-accent-A200-rgb: 157, 255, 242;
  --color-accent-A400: #6affec;
  --color-accent-A400-rgb: 106, 255, 236;
  --color-accent-A700: #51ffe9;
  --color-accent-A700-rgb: 81, 255, 233;
  --color-accent-contrast-50: #ffffff;
  --color-accent-contrast-50-rgb: 255, 255, 255;
  --color-accent-contrast-100: #ffffff;
  --color-accent-contrast-100-rgb: 255, 255, 255;
  --color-accent-contrast-200: #ffffff;
  --color-accent-contrast-200-rgb: 255, 255, 255;
  --color-accent-contrast-300: #ffffff;
  --color-accent-contrast-300-rgb: 255, 255, 255;
  --color-accent-contrast-400: #ffffff;
  --color-accent-contrast-400-rgb: 255, 255, 255;
  --color-accent-contrast-500: #ffffff;
  --color-accent-contrast-500-rgb: 255, 255, 255;
  --color-accent-contrast-600: #ffffff;
  --color-accent-contrast-600-rgb: 255, 255, 255;
  --color-accent-contrast-700: #ffffff;
  --color-accent-contrast-700-rgb: 255, 255, 255;
  --color-accent-contrast-800: #ffffff;
  --color-accent-contrast-800-rgb: 255, 255, 255;
  --color-accent-contrast-900: #ffffff;
  --color-accent-contrast-900-rgb: 255, 255, 255;
  --color-accent-contrast-A100: #ffffff;
  --color-accent-contrast-A100-rgb: 255, 255, 255;
  --color-accent-contrast-A200: #ffffff;
  --color-accent-contrast-A200-rgb: 255, 255, 255;
  --color-accent-contrast-A400: #ffffff;
  --color-accent-contrast-A400-rgb: 255, 255, 255;
  --color-accent-contrast-A700: #ffffff;
  --color-accent-contrast-A700-rgb: 255, 255, 255;
  --color-grayscale: #707070;
  --color-grayscale-50: #f2f2f2;
  --color-grayscale-50-rgb: 242, 242, 242;
  --color-grayscale-100: #eeeeee;
  --color-grayscale-100-rgb: 238, 238, 238;
  --color-grayscale-200: #d1d1d1;
  --color-grayscale-200-rgb: 209, 209, 209;
  --color-grayscale-300: #a6a6a6;
  --color-grayscale-300-rgb: 166, 166, 166;
  --color-grayscale-400: #868686;
  --color-grayscale-400-rgb: 134, 134, 134;
  --color-grayscale-500: #707070;
  --color-grayscale-500-rgb: 112, 112, 112;
  --color-grayscale-600: #686868;
  --color-grayscale-600-rgb: 104, 104, 104;
  --color-grayscale-700: #404040;
  --color-grayscale-700-rgb: 64, 64, 64;
  --color-grayscale-800: #333333;
  --color-grayscale-800-rgb: 51, 51, 51;
  --color-grayscale-900: #262626;
  --color-grayscale-900-rgb: 38, 38, 38;
  --color-grayscale-A100: #FFFFFF;
  --color-grayscale-A100-rgb: 255, 255, 255;
  --color-grayscale-A200: #222222;
  --color-grayscale-A200-rgb: 34, 34, 34;
  --color-grayscale-A400: #111111;
  --color-grayscale-A400-rgb: 17, 17, 17;
  --color-grayscale-A700: #000000;
  --color-grayscale-A700-rgb: 0, 0, 0;
  --color-grayscale-contrast-50: #ffffff;
  --color-grayscale-contrast-50-rgb: 255, 255, 255;
  --color-grayscale-contrast-100: #ffffff;
  --color-grayscale-contrast-100-rgb: 255, 255, 255;
  --color-grayscale-contrast-200: #ffffff;
  --color-grayscale-contrast-200-rgb: 255, 255, 255;
  --color-grayscale-contrast-300: #ffffff;
  --color-grayscale-contrast-300-rgb: 255, 255, 255;
  --color-grayscale-contrast-400: #ffffff;
  --color-grayscale-contrast-400-rgb: 255, 255, 255;
  --color-grayscale-contrast-500: #ffffff;
  --color-grayscale-contrast-500-rgb: 255, 255, 255;
  --color-grayscale-contrast-600: #ffffff;
  --color-grayscale-contrast-600-rgb: 255, 255, 255;
  --color-grayscale-contrast-700: #ffffff;
  --color-grayscale-contrast-700-rgb: 255, 255, 255;
  --color-grayscale-contrast-800: #ffffff;
  --color-grayscale-contrast-800-rgb: 255, 255, 255;
  --color-grayscale-contrast-900: #ffffff;
  --color-grayscale-contrast-900-rgb: 255, 255, 255;
  --color-grayscale-contrast-A100: #ffffff;
  --color-grayscale-contrast-A100-rgb: 255, 255, 255;
  --color-grayscale-contrast-A200: #ffffff;
  --color-grayscale-contrast-A200-rgb: 255, 255, 255;
  --color-grayscale-contrast-A400: #ffffff;
  --color-grayscale-contrast-A400-rgb: 255, 255, 255;
  --color-grayscale-contrast-A700: #ffffff;
  --color-grayscale-contrast-A700-rgb: 255, 255, 255;
  --color-cyan: #509da9;
  --color-cyan-50: #eef6f7;
  --color-cyan-50-rgb: 238, 246, 247;
  --color-cyan-100: #cbe3e6;
  --color-cyan-100-rgb: 203, 227, 230;
  --color-cyan-200: #a8d0d6;
  --color-cyan-200-rgb: 168, 208, 214;
  --color-cyan-300: #86bdc6;
  --color-cyan-300-rgb: 134, 189, 198;
  --color-cyan-400: #63aab5;
  --color-cyan-400-rgb: 99, 170, 181;
  --color-cyan-500: #509da9;
  --color-cyan-500-rgb: 80, 157, 169;
  --color-cyan-600: #4a919c;
  --color-cyan-600-rgb: 74, 145, 156;
  --color-cyan-700: #397179;
  --color-cyan-700-rgb: 57, 113, 121;
  --color-cyan-contrast-50: #ffffff;
  --color-cyan-contrast-50-rgb: 255, 255, 255;
  --color-cyan-contrast-100: #ffffff;
  --color-cyan-contrast-100-rgb: 255, 255, 255;
  --color-cyan-contrast-200: #ffffff;
  --color-cyan-contrast-200-rgb: 255, 255, 255;
  --color-cyan-contrast-300: #ffffff;
  --color-cyan-contrast-300-rgb: 255, 255, 255;
  --color-cyan-contrast-400: #ffffff;
  --color-cyan-contrast-400-rgb: 255, 255, 255;
  --color-cyan-contrast-500: #ffffff;
  --color-cyan-contrast-500-rgb: 255, 255, 255;
  --color-cyan-contrast-600: #ffffff;
  --color-cyan-contrast-600-rgb: 255, 255, 255;
  --color-cyan-contrast-700: #ffffff;
  --color-cyan-contrast-700-rgb: 255, 255, 255;
  --color-cyan-contrast-800: #ffffff;
  --color-cyan-contrast-800-rgb: 255, 255, 255;
  --color-cyan-contrast-900: #ffffff;
  --color-cyan-contrast-900-rgb: 255, 255, 255;
  --color-cyan-contrast-A100: #ffffff;
  --color-cyan-contrast-A100-rgb: 255, 255, 255;
  --color-cyan-contrast-A200: #ffffff;
  --color-cyan-contrast-A200-rgb: 255, 255, 255;
  --color-cyan-contrast-A400: #ffffff;
  --color-cyan-contrast-A400-rgb: 255, 255, 255;
  --color-cyan-contrast-A700: #ffffff;
  --color-cyan-contrast-A700-rgb: 255, 255, 255;
  --color-green: #2d836a;
  --color-green-50: #e6f0ed;
  --color-green-50-rgb: 230, 240, 237;
  --color-green-100: #c0dad2;
  --color-green-100-rgb: 192, 218, 210;
  --color-green-200: #96c1b5;
  --color-green-200-rgb: 150, 193, 181;
  --color-green-300: #6ca897;
  --color-green-300-rgb: 108, 168, 151;
  --color-green-400: #4d9680;
  --color-green-400-rgb: 77, 150, 128;
  --color-green-500: #2d836a;
  --color-green-500-rgb: 45, 131, 106;
  --color-green-600: #287b62;
  --color-green-600-rgb: 40, 123, 98;
  --color-green-700: #227057;
  --color-green-700-rgb: 34, 112, 87;
  --color-green-800: #1c664d;
  --color-green-800-rgb: 28, 102, 77;
  --color-green-900: #11533c;
  --color-green-900-rgb: 17, 83, 60;
  --color-green-A100: #8bffd3;
  --color-green-A100-rgb: 139, 255, 211;
  --color-green-A200: #58ffbf;
  --color-green-A200-rgb: 88, 255, 191;
  --color-green-A400: #25ffac;
  --color-green-A400-rgb: 37, 255, 172;
  --color-green-A700: #0cffa2;
  --color-green-A700-rgb: 12, 255, 162;
  --color-green-contrast-50: #ffffff;
  --color-green-contrast-50-rgb: 255, 255, 255;
  --color-green-contrast-100: #ffffff;
  --color-green-contrast-100-rgb: 255, 255, 255;
  --color-green-contrast-200: #ffffff;
  --color-green-contrast-200-rgb: 255, 255, 255;
  --color-green-contrast-300: #ffffff;
  --color-green-contrast-300-rgb: 255, 255, 255;
  --color-green-contrast-400: #ffffff;
  --color-green-contrast-400-rgb: 255, 255, 255;
  --color-green-contrast-500: #ffffff;
  --color-green-contrast-500-rgb: 255, 255, 255;
  --color-green-contrast-600: #ffffff;
  --color-green-contrast-600-rgb: 255, 255, 255;
  --color-green-contrast-700: #ffffff;
  --color-green-contrast-700-rgb: 255, 255, 255;
  --color-green-contrast-800: #ffffff;
  --color-green-contrast-800-rgb: 255, 255, 255;
  --color-green-contrast-900: #ffffff;
  --color-green-contrast-900-rgb: 255, 255, 255;
  --color-green-contrast-A100: #ffffff;
  --color-green-contrast-A100-rgb: 255, 255, 255;
  --color-green-contrast-A200: #ffffff;
  --color-green-contrast-A200-rgb: 255, 255, 255;
  --color-green-contrast-A400: #ffffff;
  --color-green-contrast-A400-rgb: 255, 255, 255;
  --color-green-contrast-A700: #ffffff;
  --color-green-contrast-A700-rgb: 255, 255, 255;
  --color-system-info: #0c6ccc;
  --color-system-info-50: #e2edf9;
  --color-system-info-50-rgb: 226, 237, 249;
  --color-system-info-100: #b6d3f0;
  --color-system-info-100-rgb: 182, 211, 240;
  --color-system-info-200: #86b6e6;
  --color-system-info-200-rgb: 134, 182, 230;
  --color-system-info-300: #5598db;
  --color-system-info-300-rgb: 85, 152, 219;
  --color-system-info-400: #3082d4;
  --color-system-info-400-rgb: 48, 130, 212;
  --color-system-info-500: #0c6ccc;
  --color-system-info-500-rgb: 12, 108, 204;
  --color-system-info-600: #0a64c7;
  --color-system-info-600-rgb: 10, 100, 199;
  --color-system-info-700: #0859c0;
  --color-system-info-700-rgb: 8, 89, 192;
  --color-system-info-800: #064fb9;
  --color-system-info-800-rgb: 6, 79, 185;
  --color-system-info-900: #033dad;
  --color-system-info-900-rgb: 3, 61, 173;
  --color-system-info-A100: #d7e2ff;
  --color-system-info-A100-rgb: 215, 226, 255;
  --color-system-info-A200: #a4bdff;
  --color-system-info-A200-rgb: 164, 189, 255;
  --color-system-info-A400: #7198ff;
  --color-system-info-A400-rgb: 113, 152, 255;
  --color-system-info-A700: #5886ff;
  --color-system-info-A700-rgb: 88, 134, 255;
  --color-system-info-contrast-50: #ffffff;
  --color-system-info-contrast-50-rgb: 255, 255, 255;
  --color-system-info-contrast-100: #ffffff;
  --color-system-info-contrast-100-rgb: 255, 255, 255;
  --color-system-info-contrast-200: #ffffff;
  --color-system-info-contrast-200-rgb: 255, 255, 255;
  --color-system-info-contrast-300: #ffffff;
  --color-system-info-contrast-300-rgb: 255, 255, 255;
  --color-system-info-contrast-400: #ffffff;
  --color-system-info-contrast-400-rgb: 255, 255, 255;
  --color-system-info-contrast-500: #ffffff;
  --color-system-info-contrast-500-rgb: 255, 255, 255;
  --color-system-info-contrast-600: #ffffff;
  --color-system-info-contrast-600-rgb: 255, 255, 255;
  --color-system-info-contrast-700: #ffffff;
  --color-system-info-contrast-700-rgb: 255, 255, 255;
  --color-system-info-contrast-800: #ffffff;
  --color-system-info-contrast-800-rgb: 255, 255, 255;
  --color-system-info-contrast-900: #ffffff;
  --color-system-info-contrast-900-rgb: 255, 255, 255;
  --color-system-info-contrast-A100: #ffffff;
  --color-system-info-contrast-A100-rgb: 255, 255, 255;
  --color-system-info-contrast-A200: #ffffff;
  --color-system-info-contrast-A200-rgb: 255, 255, 255;
  --color-system-info-contrast-A400: #ffffff;
  --color-system-info-contrast-A400-rgb: 255, 255, 255;
  --color-system-info-contrast-A700: #ffffff;
  --color-system-info-contrast-A700-rgb: 255, 255, 255;
  --color-system-error: #ff5251;
  --color-system-error-50: #ffeaea;
  --color-system-error-50-rgb: 255, 234, 234;
  --color-system-error-100: #ffcbcb;
  --color-system-error-100-rgb: 255, 203, 203;
  --color-system-error-200: #ffa9a8;
  --color-system-error-200-rgb: 255, 169, 168;
  --color-system-error-300: #ff8685;
  --color-system-error-300-rgb: 255, 134, 133;
  --color-system-error-400: #ff6c6b;
  --color-system-error-400-rgb: 255, 108, 107;
  --color-system-error-500: #ff5251;
  --color-system-error-500-rgb: 255, 82, 81;
  --color-system-error-600: #ff4b4a;
  --color-system-error-600-rgb: 255, 75, 74;
  --color-system-error-700: #ff4140;
  --color-system-error-700-rgb: 255, 65, 64;
  --color-system-error-800: #ff3837;
  --color-system-error-800-rgb: 255, 56, 55;
  --color-system-error-900: #ff2827;
  --color-system-error-900-rgb: 255, 40, 39;
  --color-system-error-A100: #ffffff;
  --color-system-error-A100-rgb: 255, 255, 255;
  --color-system-error-A200: #ffffff;
  --color-system-error-A200-rgb: 255, 255, 255;
  --color-system-error-A400: #ffffff;
  --color-system-error-A400-rgb: 255, 255, 255;
  --color-system-error-A700: #ffffff;
  --color-system-error-A700-rgb: 255, 255, 255;
  --color-system-error-contrast-50: #ffffff;
  --color-system-error-contrast-50-rgb: 255, 255, 255;
  --color-system-error-contrast-100: #ffffff;
  --color-system-error-contrast-100-rgb: 255, 255, 255;
  --color-system-error-contrast-200: #ffffff;
  --color-system-error-contrast-200-rgb: 255, 255, 255;
  --color-system-error-contrast-300: #ffffff;
  --color-system-error-contrast-300-rgb: 255, 255, 255;
  --color-system-error-contrast-400: #ffffff;
  --color-system-error-contrast-400-rgb: 255, 255, 255;
  --color-system-error-contrast-500: #ffffff;
  --color-system-error-contrast-500-rgb: 255, 255, 255;
  --color-system-error-contrast-600: #ffffff;
  --color-system-error-contrast-600-rgb: 255, 255, 255;
  --color-system-error-contrast-700: #ffffff;
  --color-system-error-contrast-700-rgb: 255, 255, 255;
  --color-system-error-contrast-800: #ffffff;
  --color-system-error-contrast-800-rgb: 255, 255, 255;
  --color-system-error-contrast-900: #ffffff;
  --color-system-error-contrast-900-rgb: 255, 255, 255;
  --color-system-error-contrast-A100: #ffffff;
  --color-system-error-contrast-A100-rgb: 255, 255, 255;
  --color-system-error-contrast-A200: #ffffff;
  --color-system-error-contrast-A200-rgb: 255, 255, 255;
  --color-system-error-contrast-A400: #ffffff;
  --color-system-error-contrast-A400-rgb: 255, 255, 255;
  --color-system-error-contrast-A700: #ffffff;
  --color-system-error-contrast-A700-rgb: 255, 255, 255;
  --color-system-success: #00ad43;
  --color-system-success-50: #e6f2e6;
  --color-system-success-50-rgb: 230, 242, 230;
  --color-system-success-100: #b3e6c7;
  --color-system-success-100-rgb: 179, 230, 199;
  --color-system-success-200: #80d6a1;
  --color-system-success-200-rgb: 128, 214, 161;
  --color-system-success-300: #4dc67b;
  --color-system-success-300-rgb: 77, 198, 123;
  --color-system-success-400: #26b95f;
  --color-system-success-400-rgb: 38, 185, 95;
  --color-system-success-500: #00ad43;
  --color-system-success-500-rgb: 0, 173, 67;
  --color-system-success-600: #00a63d;
  --color-system-success-600-rgb: 0, 166, 61;
  --color-system-success-700: #009c34;
  --color-system-success-700-rgb: 0, 156, 52;
  --color-system-success-800: #00932c;
  --color-system-success-800-rgb: 0, 147, 44;
  --color-system-success-900: #00831e;
  --color-system-success-900-rgb: 0, 131, 30;
  --color-system-success-A100: #b0ffbb;
  --color-system-success-A100-rgb: 176, 255, 187;
  --color-system-success-A200: #7dff90;
  --color-system-success-A200-rgb: 125, 255, 144;
  --color-system-success-A400: #4aff64;
  --color-system-success-A400-rgb: 74, 255, 100;
  --color-system-success-A700: #30ff4e;
  --color-system-success-A700-rgb: 48, 255, 78;
  --color-system-success-contrast-50: #ffffff;
  --color-system-success-contrast-50-rgb: 255, 255, 255;
  --color-system-success-contrast-100: #ffffff;
  --color-system-success-contrast-100-rgb: 255, 255, 255;
  --color-system-success-contrast-200: #ffffff;
  --color-system-success-contrast-200-rgb: 255, 255, 255;
  --color-system-success-contrast-300: #ffffff;
  --color-system-success-contrast-300-rgb: 255, 255, 255;
  --color-system-success-contrast-400: #ffffff;
  --color-system-success-contrast-400-rgb: 255, 255, 255;
  --color-system-success-contrast-500: #ffffff;
  --color-system-success-contrast-500-rgb: 255, 255, 255;
  --color-system-success-contrast-600: #ffffff;
  --color-system-success-contrast-600-rgb: 255, 255, 255;
  --color-system-success-contrast-700: #ffffff;
  --color-system-success-contrast-700-rgb: 255, 255, 255;
  --color-system-success-contrast-800: #ffffff;
  --color-system-success-contrast-800-rgb: 255, 255, 255;
  --color-system-success-contrast-900: #ffffff;
  --color-system-success-contrast-900-rgb: 255, 255, 255;
  --color-system-success-contrast-A100: #ffffff;
  --color-system-success-contrast-A100-rgb: 255, 255, 255;
  --color-system-success-contrast-A200: #ffffff;
  --color-system-success-contrast-A200-rgb: 255, 255, 255;
  --color-system-success-contrast-A400: #ffffff;
  --color-system-success-contrast-A400-rgb: 255, 255, 255;
  --color-system-success-contrast-A700: #ffffff;
  --color-system-success-contrast-A700-rgb: 255, 255, 255;
  --color-system-warning: #ffc107;
  --color-system-warning-50: #fff8e1;
  --color-system-warning-50-rgb: 255, 248, 225;
  --color-system-warning-100: #ffecb5;
  --color-system-warning-100-rgb: 255, 236, 181;
  --color-system-warning-200: #ffe083;
  --color-system-warning-200-rgb: 255, 224, 131;
  --color-system-warning-300: #ffd451;
  --color-system-warning-300-rgb: 255, 212, 81;
  --color-system-warning-400: #ffca2c;
  --color-system-warning-400-rgb: 255, 202, 44;
  --color-system-warning-500: #ffc107;
  --color-system-warning-500-rgb: 255, 193, 7;
  --color-system-warning-600: #ffbb06;
  --color-system-warning-600-rgb: 255, 187, 6;
  --color-system-warning-700: #ffb305;
  --color-system-warning-700-rgb: 255, 179, 5;
  --color-system-warning-800: #ffab04;
  --color-system-warning-800-rgb: 255, 171, 4;
  --color-system-warning-900: #ff9e02;
  --color-system-warning-900-rgb: 255, 158, 2;
  --color-system-warning-A100: #ffffff;
  --color-system-warning-A100-rgb: 255, 255, 255;
  --color-system-warning-A200: #fffaf2;
  --color-system-warning-A200-rgb: 255, 250, 242;
  --color-system-warning-A400: #ffe4bf;
  --color-system-warning-A400-rgb: 255, 228, 191;
  --color-system-warning-A700: #ffd9a6;
  --color-system-warning-A700-rgb: 255, 217, 166;
  --color-system-warning-contrast-50: #000000;
  --color-system-warning-contrast-50-rgb: 0, 0, 0;
  --color-system-warning-contrast-100: #000000;
  --color-system-warning-contrast-100-rgb: 0, 0, 0;
  --color-system-warning-contrast-200: #000000;
  --color-system-warning-contrast-200-rgb: 0, 0, 0;
  --color-system-warning-contrast-300: #000000;
  --color-system-warning-contrast-300-rgb: 0, 0, 0;
  --color-system-warning-contrast-400: #000000;
  --color-system-warning-contrast-400-rgb: 0, 0, 0;
  --color-system-warning-contrast-500: #000000;
  --color-system-warning-contrast-500-rgb: 0, 0, 0;
  --color-system-warning-contrast-600: #000000;
  --color-system-warning-contrast-600-rgb: 0, 0, 0;
  --color-system-warning-contrast-700: #000000;
  --color-system-warning-contrast-700-rgb: 0, 0, 0;
  --color-system-warning-contrast-800: #000000;
  --color-system-warning-contrast-800-rgb: 0, 0, 0;
  --color-system-warning-contrast-900: #000000;
  --color-system-warning-contrast-900-rgb: 0, 0, 0;
  --color-system-warning-contrast-A100: #000000;
  --color-system-warning-contrast-A100-rgb: 0, 0, 0;
  --color-system-warning-contrast-A200: #000000;
  --color-system-warning-contrast-A200-rgb: 0, 0, 0;
  --color-system-warning-contrast-A400: #000000;
  --color-system-warning-contrast-A400-rgb: 0, 0, 0;
  --color-system-warning-contrast-A700: #000000;
  --color-system-warning-contrast-A700-rgb: 0, 0, 0;
  --color-system-inactive: #707070;
  --color-red: #ff0200;
  --color-svg-default: #707070;
  --color-white: #ffffff;
  --color-transparent: transparent;
  --color-text-normal: #333333;
  --color-text-normal-inverted: #FFFFFF;
  --color-text-weak: #707070;
  --color-text-weaker-medium: #868686;
  --color-text-weaker: #a6a6a6;
  --color-text-strong: #000000;
  --color-link-normal: #0c6ccc;
  --color-link-hover: #86b6e6;
  --color-background-primary-weak: var(--color-primary-100);
  --color-background-normal: var(--color-grayscale-A100);
  --color-background-normal-rgb: var(--color-grayscale-A100-rgb);
  --color-background-normal-inverted: #333333;
  --color-background-strong: var(--color-grayscale);
  --color-background-medium: #d1d1d1;
  --color-background-weak: #f2f2f2;
  --color-background-info-weak: #e2edf9;
  --color-border-strongest: #000000;
  --color-border-strong: #333333;
  --color-border-normal: #d1d1d1;
  --color-border-normal-inverted: #FFFFFF;
  --color-border-weak: #f2f2f2;
  --color-border-transparent: transparent;
  --color-icon-primary: var(--color-primary);
  --color-icon-accent: var(--color-accent);
  --color-icon-normal: var(--color-grayscale-500);
  --color-icon-weak: var(--color-grayscale-400);
  --color-icon-weak-hover: var(--color-grayscale-300);
  --color-icon-inverted: var(--color-text-normal-inverted);
  --color-icon-text-normal: var(--color-text-normal);
  --color-icon-text-normal-inverted: var(--color-text-normal-inverted);
  --color-icon-text-weak: var(--color-text-weak);
  --color-form-input-border-normal: #333333;
  --color-form-input-border-hover: #333333;
  --color-form-input-border-focus: #333333;
  --color-form-input-border-disabled: #333333;
  --color-form-input-border-error: #333333;
  --color-form-input-border-success: #333333;
  --color-bui-button-background-primary: #0c6ccc;
  --color-bui-button-background-primary-hover: #86b6e6;
  --color-bui-button-background-secondary: #3dbfb8;
  --color-bui-button-background-secondary-hover: #80e3dd;
  --color-bui-button-background-accent: #3dbfb8;
  --color-bui-button-background-accent-hover: #80e3dd;
  --color-bui-button-background-error: #ff5251;
  --color-bui-button-background-error-hover: #ffa9a8;
  --color-bui-button-background-normal: #333333;
  --color-bui-button-background-normal-hover: #707070;
  --color-bui-button-background-weak: #f2f2f2;
  --color-bui-button-background-weak-hover: #d1d1d1;
  --color-bui-button-background-inverted: #FFFFFF;
  --color-bui-button-background-inverted-hover: #f2f2f2;
  --color-bui-button-background-transparent: transparent;
  --color-bui-button-text-primary: #0c6ccc;
  --color-bui-button-text-primary-hover: #86b6e6;
  --color-bui-button-text-accent: #3dbfb8;
  --color-bui-button-text-accent-hover: #80e3dd;
  --color-bui-button-text-tertiary: #36468f;
  --color-bui-button-text-tertiary-hover: #9ba3c7;
  --color-bui-button-text-link: #0c6ccc;
  --color-bui-button-text-link-hover: #86b6e6;
  --color-bui-button-text-normal: #707070;
  --color-bui-button-text-normal-hover: #707070;
  --color-bui-button-text-weak: #707070;
  --color-bui-button-text-weak-hover: #d1d1d1;
  --color-bui-button-text-inverted: #FFFFFF;
  --color-bui-button-text-inverted-hover: #f2f2f2;
  --color-bui-button-text-status-success: var(--color-system-success);
  --color-bui-button-text-status-success-hover: var(--color-system-success-200);
  --color-bui-button-text-status-error: var(--color-system-error);
  --color-bui-button-text-status-error-hover: var(--color-system-error-200);
  --color-bui-button-icon-weak: var(--color-grayscale-400);
  --color-bui-button-icon-weak-hover: var(--color-grayscale-400);
  --color-bui-button-icon-success: var(--color-system-success-900);
  --color-bui-button-icon-success-hover: var(--color-system-success-900);
  --color-table-head-row-background-normal: #b6d3f0;
  --color-table-body-row-background: #FFFFFF;
  --color-table-body-row-background-selected: #e6f2e6;
}

.theme-system-success {
  --color-primary: #00ad43;
  --color-primary-50: #e6f2e6;
  --color-primary-50-rgb: 230, 242, 230;
  --color-primary-100: #b3e6c7;
  --color-primary-100-rgb: 179, 230, 199;
  --color-primary-200: #80d6a1;
  --color-primary-200-rgb: 128, 214, 161;
  --color-primary-300: #4dc67b;
  --color-primary-300-rgb: 77, 198, 123;
  --color-primary-400: #26b95f;
  --color-primary-400-rgb: 38, 185, 95;
  --color-primary-500: #00ad43;
  --color-primary-500-rgb: 0, 173, 67;
  --color-primary-600: #00a63d;
  --color-primary-600-rgb: 0, 166, 61;
  --color-primary-700: #009c34;
  --color-primary-700-rgb: 0, 156, 52;
  --color-primary-800: #00932c;
  --color-primary-800-rgb: 0, 147, 44;
  --color-primary-900: #00831e;
  --color-primary-900-rgb: 0, 131, 30;
  --color-primary-A100: #b0ffbb;
  --color-primary-A100-rgb: 176, 255, 187;
  --color-primary-A200: #7dff90;
  --color-primary-A200-rgb: 125, 255, 144;
  --color-primary-A400: #4aff64;
  --color-primary-A400-rgb: 74, 255, 100;
  --color-primary-A700: #30ff4e;
  --color-primary-A700-rgb: 48, 255, 78;
  --color-primary-contrast-50: #ffffff;
  --color-primary-contrast-50-rgb: 255, 255, 255;
  --color-primary-contrast-100: #ffffff;
  --color-primary-contrast-100-rgb: 255, 255, 255;
  --color-primary-contrast-200: #ffffff;
  --color-primary-contrast-200-rgb: 255, 255, 255;
  --color-primary-contrast-300: #ffffff;
  --color-primary-contrast-300-rgb: 255, 255, 255;
  --color-primary-contrast-400: #ffffff;
  --color-primary-contrast-400-rgb: 255, 255, 255;
  --color-primary-contrast-500: #ffffff;
  --color-primary-contrast-500-rgb: 255, 255, 255;
  --color-primary-contrast-600: #ffffff;
  --color-primary-contrast-600-rgb: 255, 255, 255;
  --color-primary-contrast-700: #ffffff;
  --color-primary-contrast-700-rgb: 255, 255, 255;
  --color-primary-contrast-800: #ffffff;
  --color-primary-contrast-800-rgb: 255, 255, 255;
  --color-primary-contrast-900: #ffffff;
  --color-primary-contrast-900-rgb: 255, 255, 255;
  --color-primary-contrast-A100: #ffffff;
  --color-primary-contrast-A100-rgb: 255, 255, 255;
  --color-primary-contrast-A200: #ffffff;
  --color-primary-contrast-A200-rgb: 255, 255, 255;
  --color-primary-contrast-A400: #ffffff;
  --color-primary-contrast-A400-rgb: 255, 255, 255;
  --color-primary-contrast-A700: #ffffff;
  --color-primary-contrast-A700-rgb: 255, 255, 255;
  --color-secondary: #3dbfb8;
  --color-secondary-50: #e0f8f7;
  --color-secondary-50-rgb: 224, 248, 247;
  --color-secondary-100: #b3eeea;
  --color-secondary-100-rgb: 179, 238, 234;
  --color-secondary-200: #80e3dd;
  --color-secondary-200-rgb: 128, 227, 221;
  --color-secondary-300: #4dd8cf;
  --color-secondary-300-rgb: 77, 216, 207;
  --color-secondary-400: #26cfc4;
  --color-secondary-400-rgb: 38, 207, 196;
  --color-secondary-500: #3dbfb8;
  --color-secondary-500-rgb: 61, 191, 184;
  --color-secondary-600: #00c1b3;
  --color-secondary-600-rgb: 0, 193, 179;
  --color-secondary-700: #00baab;
  --color-secondary-700-rgb: 0, 186, 171;
  --color-secondary-800: #00b3a3;
  --color-secondary-800-rgb: 0, 179, 163;
  --color-secondary-900: #00a694;
  --color-secondary-900-rgb: 0, 166, 148;
  --color-secondary-A100: #d0fff9;
  --color-secondary-A100-rgb: 208, 255, 249;
  --color-secondary-A200: #9dfff2;
  --color-secondary-A200-rgb: 157, 255, 242;
  --color-secondary-A400: #6affec;
  --color-secondary-A400-rgb: 106, 255, 236;
  --color-secondary-A700: #51ffe9;
  --color-secondary-A700-rgb: 81, 255, 233;
  --color-secondary-contrast-50: #ffffff;
  --color-secondary-contrast-50-rgb: 255, 255, 255;
  --color-secondary-contrast-100: #ffffff;
  --color-secondary-contrast-100-rgb: 255, 255, 255;
  --color-secondary-contrast-200: #ffffff;
  --color-secondary-contrast-200-rgb: 255, 255, 255;
  --color-secondary-contrast-300: #ffffff;
  --color-secondary-contrast-300-rgb: 255, 255, 255;
  --color-secondary-contrast-400: #ffffff;
  --color-secondary-contrast-400-rgb: 255, 255, 255;
  --color-secondary-contrast-500: #ffffff;
  --color-secondary-contrast-500-rgb: 255, 255, 255;
  --color-secondary-contrast-600: #ffffff;
  --color-secondary-contrast-600-rgb: 255, 255, 255;
  --color-secondary-contrast-700: #ffffff;
  --color-secondary-contrast-700-rgb: 255, 255, 255;
  --color-secondary-contrast-800: #ffffff;
  --color-secondary-contrast-800-rgb: 255, 255, 255;
  --color-secondary-contrast-900: #ffffff;
  --color-secondary-contrast-900-rgb: 255, 255, 255;
  --color-secondary-contrast-A100: #ffffff;
  --color-secondary-contrast-A100-rgb: 255, 255, 255;
  --color-secondary-contrast-A200: #ffffff;
  --color-secondary-contrast-A200-rgb: 255, 255, 255;
  --color-secondary-contrast-A400: #ffffff;
  --color-secondary-contrast-A400-rgb: 255, 255, 255;
  --color-secondary-contrast-A700: #ffffff;
  --color-secondary-contrast-A700-rgb: 255, 255, 255;
  --color-tertiary: #36468f;
  --color-tertiary-50: #e7e9f2;
  --color-tertiary-50-rgb: 231, 233, 242;
  --color-tertiary-100: #c3c8dd;
  --color-tertiary-100-rgb: 195, 200, 221;
  --color-tertiary-200: #9ba3c7;
  --color-tertiary-200-rgb: 155, 163, 199;
  --color-tertiary-300: #727eb1;
  --color-tertiary-300-rgb: 114, 126, 177;
  --color-tertiary-400: #5462a0;
  --color-tertiary-400-rgb: 84, 98, 160;
  --color-tertiary-500: #36468f;
  --color-tertiary-500-rgb: 54, 70, 143;
  --color-tertiary-600: #303f87;
  --color-tertiary-600-rgb: 48, 63, 135;
  --color-tertiary-700: #29377c;
  --color-tertiary-700-rgb: 41, 55, 124;
  --color-tertiary-800: #222f72;
  --color-tertiary-800-rgb: 34, 47, 114;
  --color-tertiary-900: #162060;
  --color-tertiary-900-rgb: 22, 32, 96;
  --color-tertiary-A100: #9aa6ff;
  --color-tertiary-A100-rgb: 154, 166, 255;
  --color-tertiary-A200: #6778ff;
  --color-tertiary-A200-rgb: 103, 120, 255;
  --color-tertiary-A400: #344bff;
  --color-tertiary-A400-rgb: 52, 75, 255;
  --color-tertiary-A700: #1a35ff;
  --color-tertiary-A700-rgb: 26, 53, 255;
  --color-tertiary-contrast-50: #ffffff;
  --color-tertiary-contrast-50-rgb: 255, 255, 255;
  --color-tertiary-contrast-100: #ffffff;
  --color-tertiary-contrast-100-rgb: 255, 255, 255;
  --color-tertiary-contrast-200: #ffffff;
  --color-tertiary-contrast-200-rgb: 255, 255, 255;
  --color-tertiary-contrast-300: #ffffff;
  --color-tertiary-contrast-300-rgb: 255, 255, 255;
  --color-tertiary-contrast-400: #ffffff;
  --color-tertiary-contrast-400-rgb: 255, 255, 255;
  --color-tertiary-contrast-500: #ffffff;
  --color-tertiary-contrast-500-rgb: 255, 255, 255;
  --color-tertiary-contrast-600: #ffffff;
  --color-tertiary-contrast-600-rgb: 255, 255, 255;
  --color-tertiary-contrast-700: #ffffff;
  --color-tertiary-contrast-700-rgb: 255, 255, 255;
  --color-tertiary-contrast-800: #ffffff;
  --color-tertiary-contrast-800-rgb: 255, 255, 255;
  --color-tertiary-contrast-900: #ffffff;
  --color-tertiary-contrast-900-rgb: 255, 255, 255;
  --color-tertiary-contrast-A100: #ffffff;
  --color-tertiary-contrast-A100-rgb: 255, 255, 255;
  --color-tertiary-contrast-A200: #ffffff;
  --color-tertiary-contrast-A200-rgb: 255, 255, 255;
  --color-tertiary-contrast-A400: #ffffff;
  --color-tertiary-contrast-A400-rgb: 255, 255, 255;
  --color-tertiary-contrast-A700: #ffffff;
  --color-tertiary-contrast-A700-rgb: 255, 255, 255;
  --color-quaternary: #fea223;
  --color-quaternary-50: #fff4e5;
  --color-quaternary-50-rgb: 255, 244, 229;
  --color-quaternary-100: #ffe3bd;
  --color-quaternary-100-rgb: 255, 227, 189;
  --color-quaternary-200: #ffd191;
  --color-quaternary-200-rgb: 255, 209, 145;
  --color-quaternary-300: #febe65;
  --color-quaternary-300-rgb: 254, 190, 101;
  --color-quaternary-400: #feb044;
  --color-quaternary-400-rgb: 254, 176, 68;
  --color-quaternary-500: #fea223;
  --color-quaternary-500-rgb: 254, 162, 35;
  --color-quaternary-600: #fe9a1f;
  --color-quaternary-600-rgb: 254, 154, 31;
  --color-quaternary-700: #fe901a;
  --color-quaternary-700-rgb: 254, 144, 26;
  --color-quaternary-800: #fe8615;
  --color-quaternary-800-rgb: 254, 134, 21;
  --color-quaternary-900: #fd750c;
  --color-quaternary-900-rgb: 253, 117, 12;
  --color-quaternary-A100: #ffffff;
  --color-quaternary-A100-rgb: 255, 255, 255;
  --color-quaternary-A200: #fff8f4;
  --color-quaternary-A200-rgb: 255, 248, 244;
  --color-quaternary-A400: #ffd9c1;
  --color-quaternary-A400-rgb: 255, 217, 193;
  --color-quaternary-A700: #ffcaa7;
  --color-quaternary-A700-rgb: 255, 202, 167;
  --color-quaternary-contrast-50: #ffffff;
  --color-quaternary-contrast-50-rgb: 255, 255, 255;
  --color-quaternary-contrast-100: #ffffff;
  --color-quaternary-contrast-100-rgb: 255, 255, 255;
  --color-quaternary-contrast-200: #ffffff;
  --color-quaternary-contrast-200-rgb: 255, 255, 255;
  --color-quaternary-contrast-300: #ffffff;
  --color-quaternary-contrast-300-rgb: 255, 255, 255;
  --color-quaternary-contrast-400: #ffffff;
  --color-quaternary-contrast-400-rgb: 255, 255, 255;
  --color-quaternary-contrast-500: #ffffff;
  --color-quaternary-contrast-500-rgb: 255, 255, 255;
  --color-quaternary-contrast-600: #ffffff;
  --color-quaternary-contrast-600-rgb: 255, 255, 255;
  --color-quaternary-contrast-700: #ffffff;
  --color-quaternary-contrast-700-rgb: 255, 255, 255;
  --color-quaternary-contrast-800: #ffffff;
  --color-quaternary-contrast-800-rgb: 255, 255, 255;
  --color-quaternary-contrast-900: #ffffff;
  --color-quaternary-contrast-900-rgb: 255, 255, 255;
  --color-quaternary-contrast-A100: #ffffff;
  --color-quaternary-contrast-A100-rgb: 255, 255, 255;
  --color-quaternary-contrast-A200: #ffffff;
  --color-quaternary-contrast-A200-rgb: 255, 255, 255;
  --color-quaternary-contrast-A400: #ffffff;
  --color-quaternary-contrast-A400-rgb: 255, 255, 255;
  --color-quaternary-contrast-A700: #ffffff;
  --color-quaternary-contrast-A700-rgb: 255, 255, 255;
  --color-accent: #3dbfb8;
  --color-accent-50: #e0f8f7;
  --color-accent-50-rgb: 224, 248, 247;
  --color-accent-100: #b3eeea;
  --color-accent-100-rgb: 179, 238, 234;
  --color-accent-200: #80e3dd;
  --color-accent-200-rgb: 128, 227, 221;
  --color-accent-300: #4dd8cf;
  --color-accent-300-rgb: 77, 216, 207;
  --color-accent-400: #26cfc4;
  --color-accent-400-rgb: 38, 207, 196;
  --color-accent-500: #3dbfb8;
  --color-accent-500-rgb: 61, 191, 184;
  --color-accent-600: #00c1b3;
  --color-accent-600-rgb: 0, 193, 179;
  --color-accent-700: #00baab;
  --color-accent-700-rgb: 0, 186, 171;
  --color-accent-800: #00b3a3;
  --color-accent-800-rgb: 0, 179, 163;
  --color-accent-900: #00a694;
  --color-accent-900-rgb: 0, 166, 148;
  --color-accent-A100: #d0fff9;
  --color-accent-A100-rgb: 208, 255, 249;
  --color-accent-A200: #9dfff2;
  --color-accent-A200-rgb: 157, 255, 242;
  --color-accent-A400: #6affec;
  --color-accent-A400-rgb: 106, 255, 236;
  --color-accent-A700: #51ffe9;
  --color-accent-A700-rgb: 81, 255, 233;
  --color-accent-contrast-50: #ffffff;
  --color-accent-contrast-50-rgb: 255, 255, 255;
  --color-accent-contrast-100: #ffffff;
  --color-accent-contrast-100-rgb: 255, 255, 255;
  --color-accent-contrast-200: #ffffff;
  --color-accent-contrast-200-rgb: 255, 255, 255;
  --color-accent-contrast-300: #ffffff;
  --color-accent-contrast-300-rgb: 255, 255, 255;
  --color-accent-contrast-400: #ffffff;
  --color-accent-contrast-400-rgb: 255, 255, 255;
  --color-accent-contrast-500: #ffffff;
  --color-accent-contrast-500-rgb: 255, 255, 255;
  --color-accent-contrast-600: #ffffff;
  --color-accent-contrast-600-rgb: 255, 255, 255;
  --color-accent-contrast-700: #ffffff;
  --color-accent-contrast-700-rgb: 255, 255, 255;
  --color-accent-contrast-800: #ffffff;
  --color-accent-contrast-800-rgb: 255, 255, 255;
  --color-accent-contrast-900: #ffffff;
  --color-accent-contrast-900-rgb: 255, 255, 255;
  --color-accent-contrast-A100: #ffffff;
  --color-accent-contrast-A100-rgb: 255, 255, 255;
  --color-accent-contrast-A200: #ffffff;
  --color-accent-contrast-A200-rgb: 255, 255, 255;
  --color-accent-contrast-A400: #ffffff;
  --color-accent-contrast-A400-rgb: 255, 255, 255;
  --color-accent-contrast-A700: #ffffff;
  --color-accent-contrast-A700-rgb: 255, 255, 255;
  --color-grayscale: #707070;
  --color-grayscale-50: #f2f2f2;
  --color-grayscale-50-rgb: 242, 242, 242;
  --color-grayscale-100: #eeeeee;
  --color-grayscale-100-rgb: 238, 238, 238;
  --color-grayscale-200: #d1d1d1;
  --color-grayscale-200-rgb: 209, 209, 209;
  --color-grayscale-300: #a6a6a6;
  --color-grayscale-300-rgb: 166, 166, 166;
  --color-grayscale-400: #868686;
  --color-grayscale-400-rgb: 134, 134, 134;
  --color-grayscale-500: #707070;
  --color-grayscale-500-rgb: 112, 112, 112;
  --color-grayscale-600: #686868;
  --color-grayscale-600-rgb: 104, 104, 104;
  --color-grayscale-700: #404040;
  --color-grayscale-700-rgb: 64, 64, 64;
  --color-grayscale-800: #333333;
  --color-grayscale-800-rgb: 51, 51, 51;
  --color-grayscale-900: #262626;
  --color-grayscale-900-rgb: 38, 38, 38;
  --color-grayscale-A100: #FFFFFF;
  --color-grayscale-A100-rgb: 255, 255, 255;
  --color-grayscale-A200: #222222;
  --color-grayscale-A200-rgb: 34, 34, 34;
  --color-grayscale-A400: #111111;
  --color-grayscale-A400-rgb: 17, 17, 17;
  --color-grayscale-A700: #000000;
  --color-grayscale-A700-rgb: 0, 0, 0;
  --color-grayscale-contrast-50: #ffffff;
  --color-grayscale-contrast-50-rgb: 255, 255, 255;
  --color-grayscale-contrast-100: #ffffff;
  --color-grayscale-contrast-100-rgb: 255, 255, 255;
  --color-grayscale-contrast-200: #ffffff;
  --color-grayscale-contrast-200-rgb: 255, 255, 255;
  --color-grayscale-contrast-300: #ffffff;
  --color-grayscale-contrast-300-rgb: 255, 255, 255;
  --color-grayscale-contrast-400: #ffffff;
  --color-grayscale-contrast-400-rgb: 255, 255, 255;
  --color-grayscale-contrast-500: #ffffff;
  --color-grayscale-contrast-500-rgb: 255, 255, 255;
  --color-grayscale-contrast-600: #ffffff;
  --color-grayscale-contrast-600-rgb: 255, 255, 255;
  --color-grayscale-contrast-700: #ffffff;
  --color-grayscale-contrast-700-rgb: 255, 255, 255;
  --color-grayscale-contrast-800: #ffffff;
  --color-grayscale-contrast-800-rgb: 255, 255, 255;
  --color-grayscale-contrast-900: #ffffff;
  --color-grayscale-contrast-900-rgb: 255, 255, 255;
  --color-grayscale-contrast-A100: #ffffff;
  --color-grayscale-contrast-A100-rgb: 255, 255, 255;
  --color-grayscale-contrast-A200: #ffffff;
  --color-grayscale-contrast-A200-rgb: 255, 255, 255;
  --color-grayscale-contrast-A400: #ffffff;
  --color-grayscale-contrast-A400-rgb: 255, 255, 255;
  --color-grayscale-contrast-A700: #ffffff;
  --color-grayscale-contrast-A700-rgb: 255, 255, 255;
  --color-cyan: #509da9;
  --color-cyan-50: #eef6f7;
  --color-cyan-50-rgb: 238, 246, 247;
  --color-cyan-100: #cbe3e6;
  --color-cyan-100-rgb: 203, 227, 230;
  --color-cyan-200: #a8d0d6;
  --color-cyan-200-rgb: 168, 208, 214;
  --color-cyan-300: #86bdc6;
  --color-cyan-300-rgb: 134, 189, 198;
  --color-cyan-400: #63aab5;
  --color-cyan-400-rgb: 99, 170, 181;
  --color-cyan-500: #509da9;
  --color-cyan-500-rgb: 80, 157, 169;
  --color-cyan-600: #4a919c;
  --color-cyan-600-rgb: 74, 145, 156;
  --color-cyan-700: #397179;
  --color-cyan-700-rgb: 57, 113, 121;
  --color-cyan-contrast-50: #ffffff;
  --color-cyan-contrast-50-rgb: 255, 255, 255;
  --color-cyan-contrast-100: #ffffff;
  --color-cyan-contrast-100-rgb: 255, 255, 255;
  --color-cyan-contrast-200: #ffffff;
  --color-cyan-contrast-200-rgb: 255, 255, 255;
  --color-cyan-contrast-300: #ffffff;
  --color-cyan-contrast-300-rgb: 255, 255, 255;
  --color-cyan-contrast-400: #ffffff;
  --color-cyan-contrast-400-rgb: 255, 255, 255;
  --color-cyan-contrast-500: #ffffff;
  --color-cyan-contrast-500-rgb: 255, 255, 255;
  --color-cyan-contrast-600: #ffffff;
  --color-cyan-contrast-600-rgb: 255, 255, 255;
  --color-cyan-contrast-700: #ffffff;
  --color-cyan-contrast-700-rgb: 255, 255, 255;
  --color-cyan-contrast-800: #ffffff;
  --color-cyan-contrast-800-rgb: 255, 255, 255;
  --color-cyan-contrast-900: #ffffff;
  --color-cyan-contrast-900-rgb: 255, 255, 255;
  --color-cyan-contrast-A100: #ffffff;
  --color-cyan-contrast-A100-rgb: 255, 255, 255;
  --color-cyan-contrast-A200: #ffffff;
  --color-cyan-contrast-A200-rgb: 255, 255, 255;
  --color-cyan-contrast-A400: #ffffff;
  --color-cyan-contrast-A400-rgb: 255, 255, 255;
  --color-cyan-contrast-A700: #ffffff;
  --color-cyan-contrast-A700-rgb: 255, 255, 255;
  --color-green: #2d836a;
  --color-green-50: #e6f0ed;
  --color-green-50-rgb: 230, 240, 237;
  --color-green-100: #c0dad2;
  --color-green-100-rgb: 192, 218, 210;
  --color-green-200: #96c1b5;
  --color-green-200-rgb: 150, 193, 181;
  --color-green-300: #6ca897;
  --color-green-300-rgb: 108, 168, 151;
  --color-green-400: #4d9680;
  --color-green-400-rgb: 77, 150, 128;
  --color-green-500: #2d836a;
  --color-green-500-rgb: 45, 131, 106;
  --color-green-600: #287b62;
  --color-green-600-rgb: 40, 123, 98;
  --color-green-700: #227057;
  --color-green-700-rgb: 34, 112, 87;
  --color-green-800: #1c664d;
  --color-green-800-rgb: 28, 102, 77;
  --color-green-900: #11533c;
  --color-green-900-rgb: 17, 83, 60;
  --color-green-A100: #8bffd3;
  --color-green-A100-rgb: 139, 255, 211;
  --color-green-A200: #58ffbf;
  --color-green-A200-rgb: 88, 255, 191;
  --color-green-A400: #25ffac;
  --color-green-A400-rgb: 37, 255, 172;
  --color-green-A700: #0cffa2;
  --color-green-A700-rgb: 12, 255, 162;
  --color-green-contrast-50: #ffffff;
  --color-green-contrast-50-rgb: 255, 255, 255;
  --color-green-contrast-100: #ffffff;
  --color-green-contrast-100-rgb: 255, 255, 255;
  --color-green-contrast-200: #ffffff;
  --color-green-contrast-200-rgb: 255, 255, 255;
  --color-green-contrast-300: #ffffff;
  --color-green-contrast-300-rgb: 255, 255, 255;
  --color-green-contrast-400: #ffffff;
  --color-green-contrast-400-rgb: 255, 255, 255;
  --color-green-contrast-500: #ffffff;
  --color-green-contrast-500-rgb: 255, 255, 255;
  --color-green-contrast-600: #ffffff;
  --color-green-contrast-600-rgb: 255, 255, 255;
  --color-green-contrast-700: #ffffff;
  --color-green-contrast-700-rgb: 255, 255, 255;
  --color-green-contrast-800: #ffffff;
  --color-green-contrast-800-rgb: 255, 255, 255;
  --color-green-contrast-900: #ffffff;
  --color-green-contrast-900-rgb: 255, 255, 255;
  --color-green-contrast-A100: #ffffff;
  --color-green-contrast-A100-rgb: 255, 255, 255;
  --color-green-contrast-A200: #ffffff;
  --color-green-contrast-A200-rgb: 255, 255, 255;
  --color-green-contrast-A400: #ffffff;
  --color-green-contrast-A400-rgb: 255, 255, 255;
  --color-green-contrast-A700: #ffffff;
  --color-green-contrast-A700-rgb: 255, 255, 255;
  --color-system-info: #0c6ccc;
  --color-system-info-50: #e2edf9;
  --color-system-info-50-rgb: 226, 237, 249;
  --color-system-info-100: #b6d3f0;
  --color-system-info-100-rgb: 182, 211, 240;
  --color-system-info-200: #86b6e6;
  --color-system-info-200-rgb: 134, 182, 230;
  --color-system-info-300: #5598db;
  --color-system-info-300-rgb: 85, 152, 219;
  --color-system-info-400: #3082d4;
  --color-system-info-400-rgb: 48, 130, 212;
  --color-system-info-500: #0c6ccc;
  --color-system-info-500-rgb: 12, 108, 204;
  --color-system-info-600: #0a64c7;
  --color-system-info-600-rgb: 10, 100, 199;
  --color-system-info-700: #0859c0;
  --color-system-info-700-rgb: 8, 89, 192;
  --color-system-info-800: #064fb9;
  --color-system-info-800-rgb: 6, 79, 185;
  --color-system-info-900: #033dad;
  --color-system-info-900-rgb: 3, 61, 173;
  --color-system-info-A100: #d7e2ff;
  --color-system-info-A100-rgb: 215, 226, 255;
  --color-system-info-A200: #a4bdff;
  --color-system-info-A200-rgb: 164, 189, 255;
  --color-system-info-A400: #7198ff;
  --color-system-info-A400-rgb: 113, 152, 255;
  --color-system-info-A700: #5886ff;
  --color-system-info-A700-rgb: 88, 134, 255;
  --color-system-info-contrast-50: #ffffff;
  --color-system-info-contrast-50-rgb: 255, 255, 255;
  --color-system-info-contrast-100: #ffffff;
  --color-system-info-contrast-100-rgb: 255, 255, 255;
  --color-system-info-contrast-200: #ffffff;
  --color-system-info-contrast-200-rgb: 255, 255, 255;
  --color-system-info-contrast-300: #ffffff;
  --color-system-info-contrast-300-rgb: 255, 255, 255;
  --color-system-info-contrast-400: #ffffff;
  --color-system-info-contrast-400-rgb: 255, 255, 255;
  --color-system-info-contrast-500: #ffffff;
  --color-system-info-contrast-500-rgb: 255, 255, 255;
  --color-system-info-contrast-600: #ffffff;
  --color-system-info-contrast-600-rgb: 255, 255, 255;
  --color-system-info-contrast-700: #ffffff;
  --color-system-info-contrast-700-rgb: 255, 255, 255;
  --color-system-info-contrast-800: #ffffff;
  --color-system-info-contrast-800-rgb: 255, 255, 255;
  --color-system-info-contrast-900: #ffffff;
  --color-system-info-contrast-900-rgb: 255, 255, 255;
  --color-system-info-contrast-A100: #ffffff;
  --color-system-info-contrast-A100-rgb: 255, 255, 255;
  --color-system-info-contrast-A200: #ffffff;
  --color-system-info-contrast-A200-rgb: 255, 255, 255;
  --color-system-info-contrast-A400: #ffffff;
  --color-system-info-contrast-A400-rgb: 255, 255, 255;
  --color-system-info-contrast-A700: #ffffff;
  --color-system-info-contrast-A700-rgb: 255, 255, 255;
  --color-system-error: #ff5251;
  --color-system-error-50: #ffeaea;
  --color-system-error-50-rgb: 255, 234, 234;
  --color-system-error-100: #ffcbcb;
  --color-system-error-100-rgb: 255, 203, 203;
  --color-system-error-200: #ffa9a8;
  --color-system-error-200-rgb: 255, 169, 168;
  --color-system-error-300: #ff8685;
  --color-system-error-300-rgb: 255, 134, 133;
  --color-system-error-400: #ff6c6b;
  --color-system-error-400-rgb: 255, 108, 107;
  --color-system-error-500: #ff5251;
  --color-system-error-500-rgb: 255, 82, 81;
  --color-system-error-600: #ff4b4a;
  --color-system-error-600-rgb: 255, 75, 74;
  --color-system-error-700: #ff4140;
  --color-system-error-700-rgb: 255, 65, 64;
  --color-system-error-800: #ff3837;
  --color-system-error-800-rgb: 255, 56, 55;
  --color-system-error-900: #ff2827;
  --color-system-error-900-rgb: 255, 40, 39;
  --color-system-error-A100: #ffffff;
  --color-system-error-A100-rgb: 255, 255, 255;
  --color-system-error-A200: #ffffff;
  --color-system-error-A200-rgb: 255, 255, 255;
  --color-system-error-A400: #ffffff;
  --color-system-error-A400-rgb: 255, 255, 255;
  --color-system-error-A700: #ffffff;
  --color-system-error-A700-rgb: 255, 255, 255;
  --color-system-error-contrast-50: #ffffff;
  --color-system-error-contrast-50-rgb: 255, 255, 255;
  --color-system-error-contrast-100: #ffffff;
  --color-system-error-contrast-100-rgb: 255, 255, 255;
  --color-system-error-contrast-200: #ffffff;
  --color-system-error-contrast-200-rgb: 255, 255, 255;
  --color-system-error-contrast-300: #ffffff;
  --color-system-error-contrast-300-rgb: 255, 255, 255;
  --color-system-error-contrast-400: #ffffff;
  --color-system-error-contrast-400-rgb: 255, 255, 255;
  --color-system-error-contrast-500: #ffffff;
  --color-system-error-contrast-500-rgb: 255, 255, 255;
  --color-system-error-contrast-600: #ffffff;
  --color-system-error-contrast-600-rgb: 255, 255, 255;
  --color-system-error-contrast-700: #ffffff;
  --color-system-error-contrast-700-rgb: 255, 255, 255;
  --color-system-error-contrast-800: #ffffff;
  --color-system-error-contrast-800-rgb: 255, 255, 255;
  --color-system-error-contrast-900: #ffffff;
  --color-system-error-contrast-900-rgb: 255, 255, 255;
  --color-system-error-contrast-A100: #ffffff;
  --color-system-error-contrast-A100-rgb: 255, 255, 255;
  --color-system-error-contrast-A200: #ffffff;
  --color-system-error-contrast-A200-rgb: 255, 255, 255;
  --color-system-error-contrast-A400: #ffffff;
  --color-system-error-contrast-A400-rgb: 255, 255, 255;
  --color-system-error-contrast-A700: #ffffff;
  --color-system-error-contrast-A700-rgb: 255, 255, 255;
  --color-system-success: #00ad43;
  --color-system-success-50: #e6f2e6;
  --color-system-success-50-rgb: 230, 242, 230;
  --color-system-success-100: #b3e6c7;
  --color-system-success-100-rgb: 179, 230, 199;
  --color-system-success-200: #80d6a1;
  --color-system-success-200-rgb: 128, 214, 161;
  --color-system-success-300: #4dc67b;
  --color-system-success-300-rgb: 77, 198, 123;
  --color-system-success-400: #26b95f;
  --color-system-success-400-rgb: 38, 185, 95;
  --color-system-success-500: #00ad43;
  --color-system-success-500-rgb: 0, 173, 67;
  --color-system-success-600: #00a63d;
  --color-system-success-600-rgb: 0, 166, 61;
  --color-system-success-700: #009c34;
  --color-system-success-700-rgb: 0, 156, 52;
  --color-system-success-800: #00932c;
  --color-system-success-800-rgb: 0, 147, 44;
  --color-system-success-900: #00831e;
  --color-system-success-900-rgb: 0, 131, 30;
  --color-system-success-A100: #b0ffbb;
  --color-system-success-A100-rgb: 176, 255, 187;
  --color-system-success-A200: #7dff90;
  --color-system-success-A200-rgb: 125, 255, 144;
  --color-system-success-A400: #4aff64;
  --color-system-success-A400-rgb: 74, 255, 100;
  --color-system-success-A700: #30ff4e;
  --color-system-success-A700-rgb: 48, 255, 78;
  --color-system-success-contrast-50: #ffffff;
  --color-system-success-contrast-50-rgb: 255, 255, 255;
  --color-system-success-contrast-100: #ffffff;
  --color-system-success-contrast-100-rgb: 255, 255, 255;
  --color-system-success-contrast-200: #ffffff;
  --color-system-success-contrast-200-rgb: 255, 255, 255;
  --color-system-success-contrast-300: #ffffff;
  --color-system-success-contrast-300-rgb: 255, 255, 255;
  --color-system-success-contrast-400: #ffffff;
  --color-system-success-contrast-400-rgb: 255, 255, 255;
  --color-system-success-contrast-500: #ffffff;
  --color-system-success-contrast-500-rgb: 255, 255, 255;
  --color-system-success-contrast-600: #ffffff;
  --color-system-success-contrast-600-rgb: 255, 255, 255;
  --color-system-success-contrast-700: #ffffff;
  --color-system-success-contrast-700-rgb: 255, 255, 255;
  --color-system-success-contrast-800: #ffffff;
  --color-system-success-contrast-800-rgb: 255, 255, 255;
  --color-system-success-contrast-900: #ffffff;
  --color-system-success-contrast-900-rgb: 255, 255, 255;
  --color-system-success-contrast-A100: #ffffff;
  --color-system-success-contrast-A100-rgb: 255, 255, 255;
  --color-system-success-contrast-A200: #ffffff;
  --color-system-success-contrast-A200-rgb: 255, 255, 255;
  --color-system-success-contrast-A400: #ffffff;
  --color-system-success-contrast-A400-rgb: 255, 255, 255;
  --color-system-success-contrast-A700: #ffffff;
  --color-system-success-contrast-A700-rgb: 255, 255, 255;
  --color-system-warning: #ffc107;
  --color-system-warning-50: #fff8e1;
  --color-system-warning-50-rgb: 255, 248, 225;
  --color-system-warning-100: #ffecb5;
  --color-system-warning-100-rgb: 255, 236, 181;
  --color-system-warning-200: #ffe083;
  --color-system-warning-200-rgb: 255, 224, 131;
  --color-system-warning-300: #ffd451;
  --color-system-warning-300-rgb: 255, 212, 81;
  --color-system-warning-400: #ffca2c;
  --color-system-warning-400-rgb: 255, 202, 44;
  --color-system-warning-500: #ffc107;
  --color-system-warning-500-rgb: 255, 193, 7;
  --color-system-warning-600: #ffbb06;
  --color-system-warning-600-rgb: 255, 187, 6;
  --color-system-warning-700: #ffb305;
  --color-system-warning-700-rgb: 255, 179, 5;
  --color-system-warning-800: #ffab04;
  --color-system-warning-800-rgb: 255, 171, 4;
  --color-system-warning-900: #ff9e02;
  --color-system-warning-900-rgb: 255, 158, 2;
  --color-system-warning-A100: #ffffff;
  --color-system-warning-A100-rgb: 255, 255, 255;
  --color-system-warning-A200: #fffaf2;
  --color-system-warning-A200-rgb: 255, 250, 242;
  --color-system-warning-A400: #ffe4bf;
  --color-system-warning-A400-rgb: 255, 228, 191;
  --color-system-warning-A700: #ffd9a6;
  --color-system-warning-A700-rgb: 255, 217, 166;
  --color-system-warning-contrast-50: #000000;
  --color-system-warning-contrast-50-rgb: 0, 0, 0;
  --color-system-warning-contrast-100: #000000;
  --color-system-warning-contrast-100-rgb: 0, 0, 0;
  --color-system-warning-contrast-200: #000000;
  --color-system-warning-contrast-200-rgb: 0, 0, 0;
  --color-system-warning-contrast-300: #000000;
  --color-system-warning-contrast-300-rgb: 0, 0, 0;
  --color-system-warning-contrast-400: #000000;
  --color-system-warning-contrast-400-rgb: 0, 0, 0;
  --color-system-warning-contrast-500: #000000;
  --color-system-warning-contrast-500-rgb: 0, 0, 0;
  --color-system-warning-contrast-600: #000000;
  --color-system-warning-contrast-600-rgb: 0, 0, 0;
  --color-system-warning-contrast-700: #000000;
  --color-system-warning-contrast-700-rgb: 0, 0, 0;
  --color-system-warning-contrast-800: #000000;
  --color-system-warning-contrast-800-rgb: 0, 0, 0;
  --color-system-warning-contrast-900: #000000;
  --color-system-warning-contrast-900-rgb: 0, 0, 0;
  --color-system-warning-contrast-A100: #000000;
  --color-system-warning-contrast-A100-rgb: 0, 0, 0;
  --color-system-warning-contrast-A200: #000000;
  --color-system-warning-contrast-A200-rgb: 0, 0, 0;
  --color-system-warning-contrast-A400: #000000;
  --color-system-warning-contrast-A400-rgb: 0, 0, 0;
  --color-system-warning-contrast-A700: #000000;
  --color-system-warning-contrast-A700-rgb: 0, 0, 0;
  --color-system-inactive: #707070;
  --color-red: #ff0200;
  --color-svg-default: #707070;
  --color-white: #ffffff;
  --color-transparent: transparent;
  --color-text-normal: #333333;
  --color-text-normal-inverted: #FFFFFF;
  --color-text-weak: #707070;
  --color-text-weaker-medium: #868686;
  --color-text-weaker: #a6a6a6;
  --color-text-strong: #000000;
  --color-link-normal: #0c6ccc;
  --color-link-hover: #86b6e6;
  --color-background-primary-weak: var(--color-primary-100);
  --color-background-normal: var(--color-grayscale-A100);
  --color-background-normal-rgb: var(--color-grayscale-A100-rgb);
  --color-background-normal-inverted: #333333;
  --color-background-strong: var(--color-grayscale);
  --color-background-medium: #d1d1d1;
  --color-background-weak: #f2f2f2;
  --color-background-info-weak: #e2edf9;
  --color-border-strongest: #000000;
  --color-border-strong: #333333;
  --color-border-normal: #d1d1d1;
  --color-border-normal-inverted: #FFFFFF;
  --color-border-weak: #f2f2f2;
  --color-border-transparent: transparent;
  --color-icon-primary: var(--color-primary);
  --color-icon-accent: var(--color-accent);
  --color-icon-normal: var(--color-grayscale-500);
  --color-icon-weak: var(--color-grayscale-400);
  --color-icon-weak-hover: var(--color-grayscale-300);
  --color-icon-inverted: var(--color-text-normal-inverted);
  --color-icon-text-normal: var(--color-text-normal);
  --color-icon-text-normal-inverted: var(--color-text-normal-inverted);
  --color-icon-text-weak: var(--color-text-weak);
  --color-form-input-border-normal: #333333;
  --color-form-input-border-hover: #333333;
  --color-form-input-border-focus: #333333;
  --color-form-input-border-disabled: #333333;
  --color-form-input-border-error: #333333;
  --color-form-input-border-success: #333333;
  --color-bui-button-background-primary: #00ad43;
  --color-bui-button-background-primary-hover: #80d6a1;
  --color-bui-button-background-secondary: #3dbfb8;
  --color-bui-button-background-secondary-hover: #80e3dd;
  --color-bui-button-background-accent: #3dbfb8;
  --color-bui-button-background-accent-hover: #80e3dd;
  --color-bui-button-background-error: #ff5251;
  --color-bui-button-background-error-hover: #ffa9a8;
  --color-bui-button-background-normal: #333333;
  --color-bui-button-background-normal-hover: #707070;
  --color-bui-button-background-weak: #f2f2f2;
  --color-bui-button-background-weak-hover: #d1d1d1;
  --color-bui-button-background-inverted: #FFFFFF;
  --color-bui-button-background-inverted-hover: #f2f2f2;
  --color-bui-button-background-transparent: transparent;
  --color-bui-button-text-primary: #00ad43;
  --color-bui-button-text-primary-hover: #80d6a1;
  --color-bui-button-text-accent: #3dbfb8;
  --color-bui-button-text-accent-hover: #80e3dd;
  --color-bui-button-text-tertiary: #36468f;
  --color-bui-button-text-tertiary-hover: #9ba3c7;
  --color-bui-button-text-link: #0c6ccc;
  --color-bui-button-text-link-hover: #86b6e6;
  --color-bui-button-text-normal: #707070;
  --color-bui-button-text-normal-hover: #707070;
  --color-bui-button-text-weak: #707070;
  --color-bui-button-text-weak-hover: #d1d1d1;
  --color-bui-button-text-inverted: #FFFFFF;
  --color-bui-button-text-inverted-hover: #f2f2f2;
  --color-bui-button-text-status-success: var(--color-system-success);
  --color-bui-button-text-status-success-hover: var(--color-system-success-200);
  --color-bui-button-text-status-error: var(--color-system-error);
  --color-bui-button-text-status-error-hover: var(--color-system-error-200);
  --color-bui-button-icon-weak: var(--color-grayscale-400);
  --color-bui-button-icon-weak-hover: var(--color-grayscale-400);
  --color-bui-button-icon-success: var(--color-system-success-900);
  --color-bui-button-icon-success-hover: var(--color-system-success-900);
  --color-table-head-row-background-normal: #b3e6c7;
  --color-table-body-row-background: #FFFFFF;
  --color-table-body-row-background-selected: #e6f2e6;
}

.theme-system-warning {
  --color-primary: #ffc107;
  --color-primary-50: #fff8e1;
  --color-primary-50-rgb: 255, 248, 225;
  --color-primary-100: #ffecb5;
  --color-primary-100-rgb: 255, 236, 181;
  --color-primary-200: #ffe083;
  --color-primary-200-rgb: 255, 224, 131;
  --color-primary-300: #ffd451;
  --color-primary-300-rgb: 255, 212, 81;
  --color-primary-400: #ffca2c;
  --color-primary-400-rgb: 255, 202, 44;
  --color-primary-500: #ffc107;
  --color-primary-500-rgb: 255, 193, 7;
  --color-primary-600: #ffbb06;
  --color-primary-600-rgb: 255, 187, 6;
  --color-primary-700: #ffb305;
  --color-primary-700-rgb: 255, 179, 5;
  --color-primary-800: #ffab04;
  --color-primary-800-rgb: 255, 171, 4;
  --color-primary-900: #ff9e02;
  --color-primary-900-rgb: 255, 158, 2;
  --color-primary-A100: #ffffff;
  --color-primary-A100-rgb: 255, 255, 255;
  --color-primary-A200: #fffaf2;
  --color-primary-A200-rgb: 255, 250, 242;
  --color-primary-A400: #ffe4bf;
  --color-primary-A400-rgb: 255, 228, 191;
  --color-primary-A700: #ffd9a6;
  --color-primary-A700-rgb: 255, 217, 166;
  --color-primary-contrast-50: #000000;
  --color-primary-contrast-50-rgb: 0, 0, 0;
  --color-primary-contrast-100: #000000;
  --color-primary-contrast-100-rgb: 0, 0, 0;
  --color-primary-contrast-200: #000000;
  --color-primary-contrast-200-rgb: 0, 0, 0;
  --color-primary-contrast-300: #000000;
  --color-primary-contrast-300-rgb: 0, 0, 0;
  --color-primary-contrast-400: #000000;
  --color-primary-contrast-400-rgb: 0, 0, 0;
  --color-primary-contrast-500: #000000;
  --color-primary-contrast-500-rgb: 0, 0, 0;
  --color-primary-contrast-600: #000000;
  --color-primary-contrast-600-rgb: 0, 0, 0;
  --color-primary-contrast-700: #000000;
  --color-primary-contrast-700-rgb: 0, 0, 0;
  --color-primary-contrast-800: #000000;
  --color-primary-contrast-800-rgb: 0, 0, 0;
  --color-primary-contrast-900: #000000;
  --color-primary-contrast-900-rgb: 0, 0, 0;
  --color-primary-contrast-A100: #000000;
  --color-primary-contrast-A100-rgb: 0, 0, 0;
  --color-primary-contrast-A200: #000000;
  --color-primary-contrast-A200-rgb: 0, 0, 0;
  --color-primary-contrast-A400: #000000;
  --color-primary-contrast-A400-rgb: 0, 0, 0;
  --color-primary-contrast-A700: #000000;
  --color-primary-contrast-A700-rgb: 0, 0, 0;
  --color-secondary: #3dbfb8;
  --color-secondary-50: #e0f8f7;
  --color-secondary-50-rgb: 224, 248, 247;
  --color-secondary-100: #b3eeea;
  --color-secondary-100-rgb: 179, 238, 234;
  --color-secondary-200: #80e3dd;
  --color-secondary-200-rgb: 128, 227, 221;
  --color-secondary-300: #4dd8cf;
  --color-secondary-300-rgb: 77, 216, 207;
  --color-secondary-400: #26cfc4;
  --color-secondary-400-rgb: 38, 207, 196;
  --color-secondary-500: #3dbfb8;
  --color-secondary-500-rgb: 61, 191, 184;
  --color-secondary-600: #00c1b3;
  --color-secondary-600-rgb: 0, 193, 179;
  --color-secondary-700: #00baab;
  --color-secondary-700-rgb: 0, 186, 171;
  --color-secondary-800: #00b3a3;
  --color-secondary-800-rgb: 0, 179, 163;
  --color-secondary-900: #00a694;
  --color-secondary-900-rgb: 0, 166, 148;
  --color-secondary-A100: #d0fff9;
  --color-secondary-A100-rgb: 208, 255, 249;
  --color-secondary-A200: #9dfff2;
  --color-secondary-A200-rgb: 157, 255, 242;
  --color-secondary-A400: #6affec;
  --color-secondary-A400-rgb: 106, 255, 236;
  --color-secondary-A700: #51ffe9;
  --color-secondary-A700-rgb: 81, 255, 233;
  --color-secondary-contrast-50: #ffffff;
  --color-secondary-contrast-50-rgb: 255, 255, 255;
  --color-secondary-contrast-100: #ffffff;
  --color-secondary-contrast-100-rgb: 255, 255, 255;
  --color-secondary-contrast-200: #ffffff;
  --color-secondary-contrast-200-rgb: 255, 255, 255;
  --color-secondary-contrast-300: #ffffff;
  --color-secondary-contrast-300-rgb: 255, 255, 255;
  --color-secondary-contrast-400: #ffffff;
  --color-secondary-contrast-400-rgb: 255, 255, 255;
  --color-secondary-contrast-500: #ffffff;
  --color-secondary-contrast-500-rgb: 255, 255, 255;
  --color-secondary-contrast-600: #ffffff;
  --color-secondary-contrast-600-rgb: 255, 255, 255;
  --color-secondary-contrast-700: #ffffff;
  --color-secondary-contrast-700-rgb: 255, 255, 255;
  --color-secondary-contrast-800: #ffffff;
  --color-secondary-contrast-800-rgb: 255, 255, 255;
  --color-secondary-contrast-900: #ffffff;
  --color-secondary-contrast-900-rgb: 255, 255, 255;
  --color-secondary-contrast-A100: #ffffff;
  --color-secondary-contrast-A100-rgb: 255, 255, 255;
  --color-secondary-contrast-A200: #ffffff;
  --color-secondary-contrast-A200-rgb: 255, 255, 255;
  --color-secondary-contrast-A400: #ffffff;
  --color-secondary-contrast-A400-rgb: 255, 255, 255;
  --color-secondary-contrast-A700: #ffffff;
  --color-secondary-contrast-A700-rgb: 255, 255, 255;
  --color-tertiary: #36468f;
  --color-tertiary-50: #e7e9f2;
  --color-tertiary-50-rgb: 231, 233, 242;
  --color-tertiary-100: #c3c8dd;
  --color-tertiary-100-rgb: 195, 200, 221;
  --color-tertiary-200: #9ba3c7;
  --color-tertiary-200-rgb: 155, 163, 199;
  --color-tertiary-300: #727eb1;
  --color-tertiary-300-rgb: 114, 126, 177;
  --color-tertiary-400: #5462a0;
  --color-tertiary-400-rgb: 84, 98, 160;
  --color-tertiary-500: #36468f;
  --color-tertiary-500-rgb: 54, 70, 143;
  --color-tertiary-600: #303f87;
  --color-tertiary-600-rgb: 48, 63, 135;
  --color-tertiary-700: #29377c;
  --color-tertiary-700-rgb: 41, 55, 124;
  --color-tertiary-800: #222f72;
  --color-tertiary-800-rgb: 34, 47, 114;
  --color-tertiary-900: #162060;
  --color-tertiary-900-rgb: 22, 32, 96;
  --color-tertiary-A100: #9aa6ff;
  --color-tertiary-A100-rgb: 154, 166, 255;
  --color-tertiary-A200: #6778ff;
  --color-tertiary-A200-rgb: 103, 120, 255;
  --color-tertiary-A400: #344bff;
  --color-tertiary-A400-rgb: 52, 75, 255;
  --color-tertiary-A700: #1a35ff;
  --color-tertiary-A700-rgb: 26, 53, 255;
  --color-tertiary-contrast-50: #ffffff;
  --color-tertiary-contrast-50-rgb: 255, 255, 255;
  --color-tertiary-contrast-100: #ffffff;
  --color-tertiary-contrast-100-rgb: 255, 255, 255;
  --color-tertiary-contrast-200: #ffffff;
  --color-tertiary-contrast-200-rgb: 255, 255, 255;
  --color-tertiary-contrast-300: #ffffff;
  --color-tertiary-contrast-300-rgb: 255, 255, 255;
  --color-tertiary-contrast-400: #ffffff;
  --color-tertiary-contrast-400-rgb: 255, 255, 255;
  --color-tertiary-contrast-500: #ffffff;
  --color-tertiary-contrast-500-rgb: 255, 255, 255;
  --color-tertiary-contrast-600: #ffffff;
  --color-tertiary-contrast-600-rgb: 255, 255, 255;
  --color-tertiary-contrast-700: #ffffff;
  --color-tertiary-contrast-700-rgb: 255, 255, 255;
  --color-tertiary-contrast-800: #ffffff;
  --color-tertiary-contrast-800-rgb: 255, 255, 255;
  --color-tertiary-contrast-900: #ffffff;
  --color-tertiary-contrast-900-rgb: 255, 255, 255;
  --color-tertiary-contrast-A100: #ffffff;
  --color-tertiary-contrast-A100-rgb: 255, 255, 255;
  --color-tertiary-contrast-A200: #ffffff;
  --color-tertiary-contrast-A200-rgb: 255, 255, 255;
  --color-tertiary-contrast-A400: #ffffff;
  --color-tertiary-contrast-A400-rgb: 255, 255, 255;
  --color-tertiary-contrast-A700: #ffffff;
  --color-tertiary-contrast-A700-rgb: 255, 255, 255;
  --color-quaternary: #fea223;
  --color-quaternary-50: #fff4e5;
  --color-quaternary-50-rgb: 255, 244, 229;
  --color-quaternary-100: #ffe3bd;
  --color-quaternary-100-rgb: 255, 227, 189;
  --color-quaternary-200: #ffd191;
  --color-quaternary-200-rgb: 255, 209, 145;
  --color-quaternary-300: #febe65;
  --color-quaternary-300-rgb: 254, 190, 101;
  --color-quaternary-400: #feb044;
  --color-quaternary-400-rgb: 254, 176, 68;
  --color-quaternary-500: #fea223;
  --color-quaternary-500-rgb: 254, 162, 35;
  --color-quaternary-600: #fe9a1f;
  --color-quaternary-600-rgb: 254, 154, 31;
  --color-quaternary-700: #fe901a;
  --color-quaternary-700-rgb: 254, 144, 26;
  --color-quaternary-800: #fe8615;
  --color-quaternary-800-rgb: 254, 134, 21;
  --color-quaternary-900: #fd750c;
  --color-quaternary-900-rgb: 253, 117, 12;
  --color-quaternary-A100: #ffffff;
  --color-quaternary-A100-rgb: 255, 255, 255;
  --color-quaternary-A200: #fff8f4;
  --color-quaternary-A200-rgb: 255, 248, 244;
  --color-quaternary-A400: #ffd9c1;
  --color-quaternary-A400-rgb: 255, 217, 193;
  --color-quaternary-A700: #ffcaa7;
  --color-quaternary-A700-rgb: 255, 202, 167;
  --color-quaternary-contrast-50: #ffffff;
  --color-quaternary-contrast-50-rgb: 255, 255, 255;
  --color-quaternary-contrast-100: #ffffff;
  --color-quaternary-contrast-100-rgb: 255, 255, 255;
  --color-quaternary-contrast-200: #ffffff;
  --color-quaternary-contrast-200-rgb: 255, 255, 255;
  --color-quaternary-contrast-300: #ffffff;
  --color-quaternary-contrast-300-rgb: 255, 255, 255;
  --color-quaternary-contrast-400: #ffffff;
  --color-quaternary-contrast-400-rgb: 255, 255, 255;
  --color-quaternary-contrast-500: #ffffff;
  --color-quaternary-contrast-500-rgb: 255, 255, 255;
  --color-quaternary-contrast-600: #ffffff;
  --color-quaternary-contrast-600-rgb: 255, 255, 255;
  --color-quaternary-contrast-700: #ffffff;
  --color-quaternary-contrast-700-rgb: 255, 255, 255;
  --color-quaternary-contrast-800: #ffffff;
  --color-quaternary-contrast-800-rgb: 255, 255, 255;
  --color-quaternary-contrast-900: #ffffff;
  --color-quaternary-contrast-900-rgb: 255, 255, 255;
  --color-quaternary-contrast-A100: #ffffff;
  --color-quaternary-contrast-A100-rgb: 255, 255, 255;
  --color-quaternary-contrast-A200: #ffffff;
  --color-quaternary-contrast-A200-rgb: 255, 255, 255;
  --color-quaternary-contrast-A400: #ffffff;
  --color-quaternary-contrast-A400-rgb: 255, 255, 255;
  --color-quaternary-contrast-A700: #ffffff;
  --color-quaternary-contrast-A700-rgb: 255, 255, 255;
  --color-accent: #3dbfb8;
  --color-accent-50: #e0f8f7;
  --color-accent-50-rgb: 224, 248, 247;
  --color-accent-100: #b3eeea;
  --color-accent-100-rgb: 179, 238, 234;
  --color-accent-200: #80e3dd;
  --color-accent-200-rgb: 128, 227, 221;
  --color-accent-300: #4dd8cf;
  --color-accent-300-rgb: 77, 216, 207;
  --color-accent-400: #26cfc4;
  --color-accent-400-rgb: 38, 207, 196;
  --color-accent-500: #3dbfb8;
  --color-accent-500-rgb: 61, 191, 184;
  --color-accent-600: #00c1b3;
  --color-accent-600-rgb: 0, 193, 179;
  --color-accent-700: #00baab;
  --color-accent-700-rgb: 0, 186, 171;
  --color-accent-800: #00b3a3;
  --color-accent-800-rgb: 0, 179, 163;
  --color-accent-900: #00a694;
  --color-accent-900-rgb: 0, 166, 148;
  --color-accent-A100: #d0fff9;
  --color-accent-A100-rgb: 208, 255, 249;
  --color-accent-A200: #9dfff2;
  --color-accent-A200-rgb: 157, 255, 242;
  --color-accent-A400: #6affec;
  --color-accent-A400-rgb: 106, 255, 236;
  --color-accent-A700: #51ffe9;
  --color-accent-A700-rgb: 81, 255, 233;
  --color-accent-contrast-50: #ffffff;
  --color-accent-contrast-50-rgb: 255, 255, 255;
  --color-accent-contrast-100: #ffffff;
  --color-accent-contrast-100-rgb: 255, 255, 255;
  --color-accent-contrast-200: #ffffff;
  --color-accent-contrast-200-rgb: 255, 255, 255;
  --color-accent-contrast-300: #ffffff;
  --color-accent-contrast-300-rgb: 255, 255, 255;
  --color-accent-contrast-400: #ffffff;
  --color-accent-contrast-400-rgb: 255, 255, 255;
  --color-accent-contrast-500: #ffffff;
  --color-accent-contrast-500-rgb: 255, 255, 255;
  --color-accent-contrast-600: #ffffff;
  --color-accent-contrast-600-rgb: 255, 255, 255;
  --color-accent-contrast-700: #ffffff;
  --color-accent-contrast-700-rgb: 255, 255, 255;
  --color-accent-contrast-800: #ffffff;
  --color-accent-contrast-800-rgb: 255, 255, 255;
  --color-accent-contrast-900: #ffffff;
  --color-accent-contrast-900-rgb: 255, 255, 255;
  --color-accent-contrast-A100: #ffffff;
  --color-accent-contrast-A100-rgb: 255, 255, 255;
  --color-accent-contrast-A200: #ffffff;
  --color-accent-contrast-A200-rgb: 255, 255, 255;
  --color-accent-contrast-A400: #ffffff;
  --color-accent-contrast-A400-rgb: 255, 255, 255;
  --color-accent-contrast-A700: #ffffff;
  --color-accent-contrast-A700-rgb: 255, 255, 255;
  --color-grayscale: #707070;
  --color-grayscale-50: #f2f2f2;
  --color-grayscale-50-rgb: 242, 242, 242;
  --color-grayscale-100: #eeeeee;
  --color-grayscale-100-rgb: 238, 238, 238;
  --color-grayscale-200: #d1d1d1;
  --color-grayscale-200-rgb: 209, 209, 209;
  --color-grayscale-300: #a6a6a6;
  --color-grayscale-300-rgb: 166, 166, 166;
  --color-grayscale-400: #868686;
  --color-grayscale-400-rgb: 134, 134, 134;
  --color-grayscale-500: #707070;
  --color-grayscale-500-rgb: 112, 112, 112;
  --color-grayscale-600: #686868;
  --color-grayscale-600-rgb: 104, 104, 104;
  --color-grayscale-700: #404040;
  --color-grayscale-700-rgb: 64, 64, 64;
  --color-grayscale-800: #333333;
  --color-grayscale-800-rgb: 51, 51, 51;
  --color-grayscale-900: #262626;
  --color-grayscale-900-rgb: 38, 38, 38;
  --color-grayscale-A100: #FFFFFF;
  --color-grayscale-A100-rgb: 255, 255, 255;
  --color-grayscale-A200: #222222;
  --color-grayscale-A200-rgb: 34, 34, 34;
  --color-grayscale-A400: #111111;
  --color-grayscale-A400-rgb: 17, 17, 17;
  --color-grayscale-A700: #000000;
  --color-grayscale-A700-rgb: 0, 0, 0;
  --color-grayscale-contrast-50: #ffffff;
  --color-grayscale-contrast-50-rgb: 255, 255, 255;
  --color-grayscale-contrast-100: #ffffff;
  --color-grayscale-contrast-100-rgb: 255, 255, 255;
  --color-grayscale-contrast-200: #ffffff;
  --color-grayscale-contrast-200-rgb: 255, 255, 255;
  --color-grayscale-contrast-300: #ffffff;
  --color-grayscale-contrast-300-rgb: 255, 255, 255;
  --color-grayscale-contrast-400: #ffffff;
  --color-grayscale-contrast-400-rgb: 255, 255, 255;
  --color-grayscale-contrast-500: #ffffff;
  --color-grayscale-contrast-500-rgb: 255, 255, 255;
  --color-grayscale-contrast-600: #ffffff;
  --color-grayscale-contrast-600-rgb: 255, 255, 255;
  --color-grayscale-contrast-700: #ffffff;
  --color-grayscale-contrast-700-rgb: 255, 255, 255;
  --color-grayscale-contrast-800: #ffffff;
  --color-grayscale-contrast-800-rgb: 255, 255, 255;
  --color-grayscale-contrast-900: #ffffff;
  --color-grayscale-contrast-900-rgb: 255, 255, 255;
  --color-grayscale-contrast-A100: #ffffff;
  --color-grayscale-contrast-A100-rgb: 255, 255, 255;
  --color-grayscale-contrast-A200: #ffffff;
  --color-grayscale-contrast-A200-rgb: 255, 255, 255;
  --color-grayscale-contrast-A400: #ffffff;
  --color-grayscale-contrast-A400-rgb: 255, 255, 255;
  --color-grayscale-contrast-A700: #ffffff;
  --color-grayscale-contrast-A700-rgb: 255, 255, 255;
  --color-cyan: #509da9;
  --color-cyan-50: #eef6f7;
  --color-cyan-50-rgb: 238, 246, 247;
  --color-cyan-100: #cbe3e6;
  --color-cyan-100-rgb: 203, 227, 230;
  --color-cyan-200: #a8d0d6;
  --color-cyan-200-rgb: 168, 208, 214;
  --color-cyan-300: #86bdc6;
  --color-cyan-300-rgb: 134, 189, 198;
  --color-cyan-400: #63aab5;
  --color-cyan-400-rgb: 99, 170, 181;
  --color-cyan-500: #509da9;
  --color-cyan-500-rgb: 80, 157, 169;
  --color-cyan-600: #4a919c;
  --color-cyan-600-rgb: 74, 145, 156;
  --color-cyan-700: #397179;
  --color-cyan-700-rgb: 57, 113, 121;
  --color-cyan-contrast-50: #ffffff;
  --color-cyan-contrast-50-rgb: 255, 255, 255;
  --color-cyan-contrast-100: #ffffff;
  --color-cyan-contrast-100-rgb: 255, 255, 255;
  --color-cyan-contrast-200: #ffffff;
  --color-cyan-contrast-200-rgb: 255, 255, 255;
  --color-cyan-contrast-300: #ffffff;
  --color-cyan-contrast-300-rgb: 255, 255, 255;
  --color-cyan-contrast-400: #ffffff;
  --color-cyan-contrast-400-rgb: 255, 255, 255;
  --color-cyan-contrast-500: #ffffff;
  --color-cyan-contrast-500-rgb: 255, 255, 255;
  --color-cyan-contrast-600: #ffffff;
  --color-cyan-contrast-600-rgb: 255, 255, 255;
  --color-cyan-contrast-700: #ffffff;
  --color-cyan-contrast-700-rgb: 255, 255, 255;
  --color-cyan-contrast-800: #ffffff;
  --color-cyan-contrast-800-rgb: 255, 255, 255;
  --color-cyan-contrast-900: #ffffff;
  --color-cyan-contrast-900-rgb: 255, 255, 255;
  --color-cyan-contrast-A100: #ffffff;
  --color-cyan-contrast-A100-rgb: 255, 255, 255;
  --color-cyan-contrast-A200: #ffffff;
  --color-cyan-contrast-A200-rgb: 255, 255, 255;
  --color-cyan-contrast-A400: #ffffff;
  --color-cyan-contrast-A400-rgb: 255, 255, 255;
  --color-cyan-contrast-A700: #ffffff;
  --color-cyan-contrast-A700-rgb: 255, 255, 255;
  --color-green: #2d836a;
  --color-green-50: #e6f0ed;
  --color-green-50-rgb: 230, 240, 237;
  --color-green-100: #c0dad2;
  --color-green-100-rgb: 192, 218, 210;
  --color-green-200: #96c1b5;
  --color-green-200-rgb: 150, 193, 181;
  --color-green-300: #6ca897;
  --color-green-300-rgb: 108, 168, 151;
  --color-green-400: #4d9680;
  --color-green-400-rgb: 77, 150, 128;
  --color-green-500: #2d836a;
  --color-green-500-rgb: 45, 131, 106;
  --color-green-600: #287b62;
  --color-green-600-rgb: 40, 123, 98;
  --color-green-700: #227057;
  --color-green-700-rgb: 34, 112, 87;
  --color-green-800: #1c664d;
  --color-green-800-rgb: 28, 102, 77;
  --color-green-900: #11533c;
  --color-green-900-rgb: 17, 83, 60;
  --color-green-A100: #8bffd3;
  --color-green-A100-rgb: 139, 255, 211;
  --color-green-A200: #58ffbf;
  --color-green-A200-rgb: 88, 255, 191;
  --color-green-A400: #25ffac;
  --color-green-A400-rgb: 37, 255, 172;
  --color-green-A700: #0cffa2;
  --color-green-A700-rgb: 12, 255, 162;
  --color-green-contrast-50: #ffffff;
  --color-green-contrast-50-rgb: 255, 255, 255;
  --color-green-contrast-100: #ffffff;
  --color-green-contrast-100-rgb: 255, 255, 255;
  --color-green-contrast-200: #ffffff;
  --color-green-contrast-200-rgb: 255, 255, 255;
  --color-green-contrast-300: #ffffff;
  --color-green-contrast-300-rgb: 255, 255, 255;
  --color-green-contrast-400: #ffffff;
  --color-green-contrast-400-rgb: 255, 255, 255;
  --color-green-contrast-500: #ffffff;
  --color-green-contrast-500-rgb: 255, 255, 255;
  --color-green-contrast-600: #ffffff;
  --color-green-contrast-600-rgb: 255, 255, 255;
  --color-green-contrast-700: #ffffff;
  --color-green-contrast-700-rgb: 255, 255, 255;
  --color-green-contrast-800: #ffffff;
  --color-green-contrast-800-rgb: 255, 255, 255;
  --color-green-contrast-900: #ffffff;
  --color-green-contrast-900-rgb: 255, 255, 255;
  --color-green-contrast-A100: #ffffff;
  --color-green-contrast-A100-rgb: 255, 255, 255;
  --color-green-contrast-A200: #ffffff;
  --color-green-contrast-A200-rgb: 255, 255, 255;
  --color-green-contrast-A400: #ffffff;
  --color-green-contrast-A400-rgb: 255, 255, 255;
  --color-green-contrast-A700: #ffffff;
  --color-green-contrast-A700-rgb: 255, 255, 255;
  --color-system-info: #0c6ccc;
  --color-system-info-50: #e2edf9;
  --color-system-info-50-rgb: 226, 237, 249;
  --color-system-info-100: #b6d3f0;
  --color-system-info-100-rgb: 182, 211, 240;
  --color-system-info-200: #86b6e6;
  --color-system-info-200-rgb: 134, 182, 230;
  --color-system-info-300: #5598db;
  --color-system-info-300-rgb: 85, 152, 219;
  --color-system-info-400: #3082d4;
  --color-system-info-400-rgb: 48, 130, 212;
  --color-system-info-500: #0c6ccc;
  --color-system-info-500-rgb: 12, 108, 204;
  --color-system-info-600: #0a64c7;
  --color-system-info-600-rgb: 10, 100, 199;
  --color-system-info-700: #0859c0;
  --color-system-info-700-rgb: 8, 89, 192;
  --color-system-info-800: #064fb9;
  --color-system-info-800-rgb: 6, 79, 185;
  --color-system-info-900: #033dad;
  --color-system-info-900-rgb: 3, 61, 173;
  --color-system-info-A100: #d7e2ff;
  --color-system-info-A100-rgb: 215, 226, 255;
  --color-system-info-A200: #a4bdff;
  --color-system-info-A200-rgb: 164, 189, 255;
  --color-system-info-A400: #7198ff;
  --color-system-info-A400-rgb: 113, 152, 255;
  --color-system-info-A700: #5886ff;
  --color-system-info-A700-rgb: 88, 134, 255;
  --color-system-info-contrast-50: #ffffff;
  --color-system-info-contrast-50-rgb: 255, 255, 255;
  --color-system-info-contrast-100: #ffffff;
  --color-system-info-contrast-100-rgb: 255, 255, 255;
  --color-system-info-contrast-200: #ffffff;
  --color-system-info-contrast-200-rgb: 255, 255, 255;
  --color-system-info-contrast-300: #ffffff;
  --color-system-info-contrast-300-rgb: 255, 255, 255;
  --color-system-info-contrast-400: #ffffff;
  --color-system-info-contrast-400-rgb: 255, 255, 255;
  --color-system-info-contrast-500: #ffffff;
  --color-system-info-contrast-500-rgb: 255, 255, 255;
  --color-system-info-contrast-600: #ffffff;
  --color-system-info-contrast-600-rgb: 255, 255, 255;
  --color-system-info-contrast-700: #ffffff;
  --color-system-info-contrast-700-rgb: 255, 255, 255;
  --color-system-info-contrast-800: #ffffff;
  --color-system-info-contrast-800-rgb: 255, 255, 255;
  --color-system-info-contrast-900: #ffffff;
  --color-system-info-contrast-900-rgb: 255, 255, 255;
  --color-system-info-contrast-A100: #ffffff;
  --color-system-info-contrast-A100-rgb: 255, 255, 255;
  --color-system-info-contrast-A200: #ffffff;
  --color-system-info-contrast-A200-rgb: 255, 255, 255;
  --color-system-info-contrast-A400: #ffffff;
  --color-system-info-contrast-A400-rgb: 255, 255, 255;
  --color-system-info-contrast-A700: #ffffff;
  --color-system-info-contrast-A700-rgb: 255, 255, 255;
  --color-system-error: #ff5251;
  --color-system-error-50: #ffeaea;
  --color-system-error-50-rgb: 255, 234, 234;
  --color-system-error-100: #ffcbcb;
  --color-system-error-100-rgb: 255, 203, 203;
  --color-system-error-200: #ffa9a8;
  --color-system-error-200-rgb: 255, 169, 168;
  --color-system-error-300: #ff8685;
  --color-system-error-300-rgb: 255, 134, 133;
  --color-system-error-400: #ff6c6b;
  --color-system-error-400-rgb: 255, 108, 107;
  --color-system-error-500: #ff5251;
  --color-system-error-500-rgb: 255, 82, 81;
  --color-system-error-600: #ff4b4a;
  --color-system-error-600-rgb: 255, 75, 74;
  --color-system-error-700: #ff4140;
  --color-system-error-700-rgb: 255, 65, 64;
  --color-system-error-800: #ff3837;
  --color-system-error-800-rgb: 255, 56, 55;
  --color-system-error-900: #ff2827;
  --color-system-error-900-rgb: 255, 40, 39;
  --color-system-error-A100: #ffffff;
  --color-system-error-A100-rgb: 255, 255, 255;
  --color-system-error-A200: #ffffff;
  --color-system-error-A200-rgb: 255, 255, 255;
  --color-system-error-A400: #ffffff;
  --color-system-error-A400-rgb: 255, 255, 255;
  --color-system-error-A700: #ffffff;
  --color-system-error-A700-rgb: 255, 255, 255;
  --color-system-error-contrast-50: #ffffff;
  --color-system-error-contrast-50-rgb: 255, 255, 255;
  --color-system-error-contrast-100: #ffffff;
  --color-system-error-contrast-100-rgb: 255, 255, 255;
  --color-system-error-contrast-200: #ffffff;
  --color-system-error-contrast-200-rgb: 255, 255, 255;
  --color-system-error-contrast-300: #ffffff;
  --color-system-error-contrast-300-rgb: 255, 255, 255;
  --color-system-error-contrast-400: #ffffff;
  --color-system-error-contrast-400-rgb: 255, 255, 255;
  --color-system-error-contrast-500: #ffffff;
  --color-system-error-contrast-500-rgb: 255, 255, 255;
  --color-system-error-contrast-600: #ffffff;
  --color-system-error-contrast-600-rgb: 255, 255, 255;
  --color-system-error-contrast-700: #ffffff;
  --color-system-error-contrast-700-rgb: 255, 255, 255;
  --color-system-error-contrast-800: #ffffff;
  --color-system-error-contrast-800-rgb: 255, 255, 255;
  --color-system-error-contrast-900: #ffffff;
  --color-system-error-contrast-900-rgb: 255, 255, 255;
  --color-system-error-contrast-A100: #ffffff;
  --color-system-error-contrast-A100-rgb: 255, 255, 255;
  --color-system-error-contrast-A200: #ffffff;
  --color-system-error-contrast-A200-rgb: 255, 255, 255;
  --color-system-error-contrast-A400: #ffffff;
  --color-system-error-contrast-A400-rgb: 255, 255, 255;
  --color-system-error-contrast-A700: #ffffff;
  --color-system-error-contrast-A700-rgb: 255, 255, 255;
  --color-system-success: #00ad43;
  --color-system-success-50: #e6f2e6;
  --color-system-success-50-rgb: 230, 242, 230;
  --color-system-success-100: #b3e6c7;
  --color-system-success-100-rgb: 179, 230, 199;
  --color-system-success-200: #80d6a1;
  --color-system-success-200-rgb: 128, 214, 161;
  --color-system-success-300: #4dc67b;
  --color-system-success-300-rgb: 77, 198, 123;
  --color-system-success-400: #26b95f;
  --color-system-success-400-rgb: 38, 185, 95;
  --color-system-success-500: #00ad43;
  --color-system-success-500-rgb: 0, 173, 67;
  --color-system-success-600: #00a63d;
  --color-system-success-600-rgb: 0, 166, 61;
  --color-system-success-700: #009c34;
  --color-system-success-700-rgb: 0, 156, 52;
  --color-system-success-800: #00932c;
  --color-system-success-800-rgb: 0, 147, 44;
  --color-system-success-900: #00831e;
  --color-system-success-900-rgb: 0, 131, 30;
  --color-system-success-A100: #b0ffbb;
  --color-system-success-A100-rgb: 176, 255, 187;
  --color-system-success-A200: #7dff90;
  --color-system-success-A200-rgb: 125, 255, 144;
  --color-system-success-A400: #4aff64;
  --color-system-success-A400-rgb: 74, 255, 100;
  --color-system-success-A700: #30ff4e;
  --color-system-success-A700-rgb: 48, 255, 78;
  --color-system-success-contrast-50: #ffffff;
  --color-system-success-contrast-50-rgb: 255, 255, 255;
  --color-system-success-contrast-100: #ffffff;
  --color-system-success-contrast-100-rgb: 255, 255, 255;
  --color-system-success-contrast-200: #ffffff;
  --color-system-success-contrast-200-rgb: 255, 255, 255;
  --color-system-success-contrast-300: #ffffff;
  --color-system-success-contrast-300-rgb: 255, 255, 255;
  --color-system-success-contrast-400: #ffffff;
  --color-system-success-contrast-400-rgb: 255, 255, 255;
  --color-system-success-contrast-500: #ffffff;
  --color-system-success-contrast-500-rgb: 255, 255, 255;
  --color-system-success-contrast-600: #ffffff;
  --color-system-success-contrast-600-rgb: 255, 255, 255;
  --color-system-success-contrast-700: #ffffff;
  --color-system-success-contrast-700-rgb: 255, 255, 255;
  --color-system-success-contrast-800: #ffffff;
  --color-system-success-contrast-800-rgb: 255, 255, 255;
  --color-system-success-contrast-900: #ffffff;
  --color-system-success-contrast-900-rgb: 255, 255, 255;
  --color-system-success-contrast-A100: #ffffff;
  --color-system-success-contrast-A100-rgb: 255, 255, 255;
  --color-system-success-contrast-A200: #ffffff;
  --color-system-success-contrast-A200-rgb: 255, 255, 255;
  --color-system-success-contrast-A400: #ffffff;
  --color-system-success-contrast-A400-rgb: 255, 255, 255;
  --color-system-success-contrast-A700: #ffffff;
  --color-system-success-contrast-A700-rgb: 255, 255, 255;
  --color-system-warning: #ffc107;
  --color-system-warning-50: #fff8e1;
  --color-system-warning-50-rgb: 255, 248, 225;
  --color-system-warning-100: #ffecb5;
  --color-system-warning-100-rgb: 255, 236, 181;
  --color-system-warning-200: #ffe083;
  --color-system-warning-200-rgb: 255, 224, 131;
  --color-system-warning-300: #ffd451;
  --color-system-warning-300-rgb: 255, 212, 81;
  --color-system-warning-400: #ffca2c;
  --color-system-warning-400-rgb: 255, 202, 44;
  --color-system-warning-500: #ffc107;
  --color-system-warning-500-rgb: 255, 193, 7;
  --color-system-warning-600: #ffbb06;
  --color-system-warning-600-rgb: 255, 187, 6;
  --color-system-warning-700: #ffb305;
  --color-system-warning-700-rgb: 255, 179, 5;
  --color-system-warning-800: #ffab04;
  --color-system-warning-800-rgb: 255, 171, 4;
  --color-system-warning-900: #ff9e02;
  --color-system-warning-900-rgb: 255, 158, 2;
  --color-system-warning-A100: #ffffff;
  --color-system-warning-A100-rgb: 255, 255, 255;
  --color-system-warning-A200: #fffaf2;
  --color-system-warning-A200-rgb: 255, 250, 242;
  --color-system-warning-A400: #ffe4bf;
  --color-system-warning-A400-rgb: 255, 228, 191;
  --color-system-warning-A700: #ffd9a6;
  --color-system-warning-A700-rgb: 255, 217, 166;
  --color-system-warning-contrast-50: #000000;
  --color-system-warning-contrast-50-rgb: 0, 0, 0;
  --color-system-warning-contrast-100: #000000;
  --color-system-warning-contrast-100-rgb: 0, 0, 0;
  --color-system-warning-contrast-200: #000000;
  --color-system-warning-contrast-200-rgb: 0, 0, 0;
  --color-system-warning-contrast-300: #000000;
  --color-system-warning-contrast-300-rgb: 0, 0, 0;
  --color-system-warning-contrast-400: #000000;
  --color-system-warning-contrast-400-rgb: 0, 0, 0;
  --color-system-warning-contrast-500: #000000;
  --color-system-warning-contrast-500-rgb: 0, 0, 0;
  --color-system-warning-contrast-600: #000000;
  --color-system-warning-contrast-600-rgb: 0, 0, 0;
  --color-system-warning-contrast-700: #000000;
  --color-system-warning-contrast-700-rgb: 0, 0, 0;
  --color-system-warning-contrast-800: #000000;
  --color-system-warning-contrast-800-rgb: 0, 0, 0;
  --color-system-warning-contrast-900: #000000;
  --color-system-warning-contrast-900-rgb: 0, 0, 0;
  --color-system-warning-contrast-A100: #000000;
  --color-system-warning-contrast-A100-rgb: 0, 0, 0;
  --color-system-warning-contrast-A200: #000000;
  --color-system-warning-contrast-A200-rgb: 0, 0, 0;
  --color-system-warning-contrast-A400: #000000;
  --color-system-warning-contrast-A400-rgb: 0, 0, 0;
  --color-system-warning-contrast-A700: #000000;
  --color-system-warning-contrast-A700-rgb: 0, 0, 0;
  --color-system-inactive: #707070;
  --color-red: #ff0200;
  --color-svg-default: #707070;
  --color-white: #ffffff;
  --color-transparent: transparent;
  --color-text-normal: #333333;
  --color-text-normal-inverted: #FFFFFF;
  --color-text-weak: #707070;
  --color-text-weaker-medium: #868686;
  --color-text-weaker: #a6a6a6;
  --color-text-strong: #000000;
  --color-link-normal: #0c6ccc;
  --color-link-hover: #86b6e6;
  --color-background-primary-weak: var(--color-primary-100);
  --color-background-normal: var(--color-grayscale-A100);
  --color-background-normal-rgb: var(--color-grayscale-A100-rgb);
  --color-background-normal-inverted: #333333;
  --color-background-strong: var(--color-grayscale);
  --color-background-medium: #d1d1d1;
  --color-background-weak: #f2f2f2;
  --color-background-info-weak: #e2edf9;
  --color-border-strongest: #000000;
  --color-border-strong: #333333;
  --color-border-normal: #d1d1d1;
  --color-border-normal-inverted: #FFFFFF;
  --color-border-weak: #f2f2f2;
  --color-border-transparent: transparent;
  --color-icon-primary: var(--color-primary);
  --color-icon-accent: var(--color-accent);
  --color-icon-normal: var(--color-grayscale-500);
  --color-icon-weak: var(--color-grayscale-400);
  --color-icon-weak-hover: var(--color-grayscale-300);
  --color-icon-inverted: var(--color-text-normal-inverted);
  --color-icon-text-normal: var(--color-text-normal);
  --color-icon-text-normal-inverted: var(--color-text-normal-inverted);
  --color-icon-text-weak: var(--color-text-weak);
  --color-form-input-border-normal: #333333;
  --color-form-input-border-hover: #333333;
  --color-form-input-border-focus: #333333;
  --color-form-input-border-disabled: #333333;
  --color-form-input-border-error: #333333;
  --color-form-input-border-success: #333333;
  --color-bui-button-background-primary: #ffc107;
  --color-bui-button-background-primary-hover: #ffe083;
  --color-bui-button-background-secondary: #3dbfb8;
  --color-bui-button-background-secondary-hover: #80e3dd;
  --color-bui-button-background-accent: #3dbfb8;
  --color-bui-button-background-accent-hover: #80e3dd;
  --color-bui-button-background-error: #ff5251;
  --color-bui-button-background-error-hover: #ffa9a8;
  --color-bui-button-background-normal: #333333;
  --color-bui-button-background-normal-hover: #707070;
  --color-bui-button-background-weak: #f2f2f2;
  --color-bui-button-background-weak-hover: #d1d1d1;
  --color-bui-button-background-inverted: #FFFFFF;
  --color-bui-button-background-inverted-hover: #f2f2f2;
  --color-bui-button-background-transparent: transparent;
  --color-bui-button-text-primary: #ffc107;
  --color-bui-button-text-primary-hover: #ffe083;
  --color-bui-button-text-accent: #3dbfb8;
  --color-bui-button-text-accent-hover: #80e3dd;
  --color-bui-button-text-tertiary: #36468f;
  --color-bui-button-text-tertiary-hover: #9ba3c7;
  --color-bui-button-text-link: #0c6ccc;
  --color-bui-button-text-link-hover: #86b6e6;
  --color-bui-button-text-normal: #707070;
  --color-bui-button-text-normal-hover: #707070;
  --color-bui-button-text-weak: #707070;
  --color-bui-button-text-weak-hover: #d1d1d1;
  --color-bui-button-text-inverted: #FFFFFF;
  --color-bui-button-text-inverted-hover: #f2f2f2;
  --color-bui-button-text-status-success: var(--color-system-success);
  --color-bui-button-text-status-success-hover: var(--color-system-success-200);
  --color-bui-button-text-status-error: var(--color-system-error);
  --color-bui-button-text-status-error-hover: var(--color-system-error-200);
  --color-bui-button-icon-weak: var(--color-grayscale-400);
  --color-bui-button-icon-weak-hover: var(--color-grayscale-400);
  --color-bui-button-icon-success: var(--color-system-success-900);
  --color-bui-button-icon-success-hover: var(--color-system-success-900);
  --color-table-head-row-background-normal: #ffecb5;
  --color-table-body-row-background: #FFFFFF;
  --color-table-body-row-background-selected: #e6f2e6;
}