@use "settings/variables/layout" as *;

.u-height-full {
  height: 100%;
}

.u-width-full {
  width: 100%;
}

.u-hidden {
  display: none;
}

.u-resizable-y {
  overflow-y: auto;
  resize: vertical;
}

.u-flex {
  display: flex;
}

.u-flex-row {
  flex-direction: row;
}

.u-flex-column {
  flex-direction: column;
}

.u-flex-center {
  align-items: center;
  justify-content: center;
}

.u-flex-align-items-center {
  align-items: center;
}

.u-flex-justify-flex-end {
  justify-content: flex-end;
}

.u-flex-justify-center {
  justify-content: center;
}

.u-flex-justify-space-between {
  justify-content: space-between;
}

.u-flex-justify-space-evenly {
  justify-content: space-evenly;
}

.u-flex-grow-0 {
  flex-grow: 0;
}

.u-flex-grow-1 {
  flex-grow: 1;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-flex-nowrap {
  flex-wrap: nowrap;
}

@each $mq-name, $mq-val in $mq {
  @media #{$mq-val} {
    .u-flex\@#{$mq-name} {
      display: flex;
    }

    .u-flex-row\@#{$mq-name} {
      flex-direction: row;
    }

    .u-flex-column\@#{$mq-name} {
      flex-direction: column;
    }

    .u-flex-justify-flex-end\@#{$mq-name} {
      justify-content: flex-end;
    }

    .u-flex-center\@#{$mq-name} {
      align-items: center;
      justify-content: center;
    }

    .u-flex-align-items-center\@#{$mq-name} {
      align-items: center;
    }

    .u-flex-justify-center\@#{$mq-name} {
      justify-content: center;
    }

    .u-flex-justify-space-between\@#{$mq-name} {
      justify-content: space-between;
    }

    .u-flex-justify-space-evenly\@#{$mq-name} {
      justify-content: space-evenly;
    }

    .u-flex-grow-0\@#{$mq-name} {
      flex-grow: 0;
    }

    .u-flex-grow-1\@#{$mq-name} {
      flex-grow: 1;
    }

    .u-flex-wrap\@#{$mq-name} {
      flex-wrap: wrap;
    }

    .u-flex-nowrap\@#{$mq-name} {
      flex-wrap: nowrap;
    }
  }
}

.u-scrollable {
  overflow: auto;

  .is-touch-device & {
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
  }
}

.u-scrollable-y {
  overflow-y: auto;

  .is-touch-device & {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }
}

.u-scrollable-x {
  overflow-x: auto;

  .is-touch-device & {
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;

    &:not(.u-scrollable-y) {
      overflow-y: hidden;
    }
  }
}

.u-margin-none {
  margin: 0;
}

.u-grid {
  display: grid;
}

.u-grid-2col-gap {
  --u-grid-2col-gap: var(--grid-gap-default, 20px);
  grid-gap: var(--u-grid-2col-gap);
  grid-template-columns: 1fr 1fr;
}

@each $space, $value in $spaces {
  [class*="u-space-#{$space}"] {
    --u-space-#{$space}: var(--space-#{$space});
  }
  .u-space-#{$space}-top-margin {
    --u-space-top-margin-#{$space}: var(--u-space-#{$space});
    margin-top: var(--u-space-top-margin-#{$space});
  }
  .u-space-#{$space}-bottom-margin {
    --u-space-bottom-margin-#{$space}: var(--u-space-#{$space});
    margin-bottom: var(--u-space-bottom-margin-#{$space});
  }
  .u-space-#{$space}-top-padding {
    --u-space-top-padding-#{$space}: var(--u-space-#{$space});
    padding-top: var(--u-space-top-padding-#{$space});
  }
  .u-space-#{$space}-bottom-padding {
    --u-space-bottom-padding-#{$space}: var(--u-space-#{$space});
    padding-bottom: var(--u-space-bottom-padding-#{$space});
  }
  .u-space-#{$space}-left-margin {
    --u-space-left-margin-#{$space}: var(--u-space-#{$space});
    margin-left: var(--u-space-left-margin-#{$space});
  }
  .u-space-#{$space}-right-margin {
    --u-space-right-margin-#{$space}: var(--u-space-#{$space});
    margin-right: var(--u-space-right-margin-#{$space});
  }
  .u-space-#{$space}-left-padding {
    --u-space-left-padding-#{$space}: var(--u-space-#{$space});
    padding-left: var(--u-space-left-padding-#{$space});
  }
  .u-space-#{$space}-right-padding {
    --u-space-right-padding-#{$space}: var(--u-space-#{$space});
    padding-right: var(--u-space-right-padding-#{$space});
  }
  .u-space-#{$space}-y-margin {
    --u-space-bottom-margin-#{$space}: var(--u-space-#{$space});
    --u-space-top-margin-#{$space}: var(--u-space-#{$space});
    margin-bottom: var(--u-space-bottom-margin-#{$space});
    margin-top: var(--u-space-top-margin-#{$space});
  }
  .u-space-#{$space}-y-padding {
    --u-space-bottom-padding-#{$space}: var(--u-space-#{$space});
    --u-space-top-padding-#{$space}: var(--u-space-#{$space});
    padding-bottom: var(--u-space-bottom-padding-#{$space});
    padding-top: var(--u-space-top-padding-#{$space});
  }
  .u-space-#{$space}-x-margin {
    --u-space-left-margin-#{$space}: var(--u-space-#{$space});
    --u-space-right-margin-#{$space}: var(--u-space-#{$space});
    margin-left: var(--u-space-left-margin-#{$space});
    margin-right: var(--u-space-right-margin-#{$space});
  }
  .u-space-#{$space}-x-padding {
    --u-space-left-padding-#{$space}: var(--u-space-#{$space});
    --u-space-right-padding-#{$space}: var(--u-space-#{$space});
    padding-left: var(--u-space-left-padding-#{$space});
    padding-right: var(--u-space-right-padding-#{$space});
  }
}
