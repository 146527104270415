.material-light-theme .mat-datepicker-toggle,
.material-light-theme .mat-datepicker-content .mat-calendar-next-button,
.material-light-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: var(--color-icon-weak);
}

/*  Fixing material datepicker icon 2px of empty space at the bottom */

.material-light-theme {
  .mat-datepicker-toggle {
    .mat-icon-button {
      bottom: 6px;
    }
  }
}

.material-light-theme .mat-datepicker-popup {
  .mat-datepicker-content {
    border-radius: 10px;

    .mat-calendar-header {
      padding: {
        inline: 20px;
        top: 20px;
      }

      .mat-calendar-controls {
        border: {
          color: var(--color-border-weak);
          style: solid;
          width: 1px;
        }
        justify-content: space-between;
        margin: 0;

        .mat-calendar-spacer {
          display: none;
        }

        .mat-calendar-period-button {
          color: var(--color-accent);
          order: 5;

          .mat-calendar-arrow {
            fill: var(--color-accent);
          }
        }

        .mat-calendar-previous-button {
          order: 1;
        }

        .mat-calendar-next-button {
          order: 10;
        }
      }
    }

    .mat-calendar-content {
      padding: 20px;

      .mat-calendar-body-today {
        font-weight: var(--font-weight-bold);
      }

      .mat-calendar-body-selected {
        color: var(--color-accent-contrast-500);
      }
    }
  }
}

.mat-datepicker-content .mat-stroked-button {
  border: none;
}

.mat-datepicker-content .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay, .mat-stroked-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
  opacity: 1;
}
