.mat-button-wrapper {
  --mat-button-font-size: var(--font-size-base, 16px);
  --mat-button-font-weight: var(--font-weight-bold, 700);

  font: {
    size: var(--mat-button-font-size);
    weight: var(--mat-button-font-weight);
  }
}

.mat-fab .mat-button-wrapper {
  padding: 0 !important;
}

.mat-fab .mat-icon.mat-icon-inline {
  height: 42px;
  width: 42px;
}

.dx-row .mat-menu-trigger {
  color: var(--color-text-weak);
}

.c-login-content__form {
  .u-form-element__row {
    .c-bui-button-label {
      padding: 1.5rem 1rem !important;
      background-image: var(--gradient-1);
      background-size: 200% auto;
      background-position: left -2px center;
      transition: background-position .3s;

      &:focus,
      &:active {
        border: 1px solid #306080;
      }

      &:hover {
        background-position: right center;
      }
    }
  }
}

.c-reports-card__actions-list {
  .c-reports-card__actions-list-item {
    &:nth-of-type(1) {
      .mat-icon {
        height: 19px !important;
        font-size: unset !important;
      }
    }
  }
}

.c-favorites {

  .mat-icon.c-bui-button-icon--icon-size-medium {
    height: 26px !important;
    width: 26px !important;
    font-size: 26px !important;
  }
}
