.dx-data-row > .column-disabled {
  background-color: #d3d3d3;
}

.dx-datagrid .dx-row > td {
  padding: 0 10px;
}

.dx-datagrid-borders .dx-datagrid-headers .dx-datagrid-table {
  height: 100%;
}

.dx-datagrid-headers .dx-datagrid-table {
  height: 100%;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable {
  background-color: white;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active {
  width: 17px;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll:not(.dx-state-hover) {
  display: inline-flex;
  height: 12px;
  padding-right: 15px;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active {
  height: 14px;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll, .dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover, .dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover .dx-scrollable-scroll {
  height: 14px;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable .dx-scrollable-scroll.dx-state-invisible .dx-scrollable-scroll-content {
  transition: none;
}

.dx-scrollbar-horizontal {
  background-color: white;
  display: block;
  height: 7px;
  margin-top: 5px;
}

.dx-scrollbar-vertical {
  background-color: white;
}

.dx-scrollable-container {
  float: none;
  overflow-x: auto;
  touch-action: pan-y pan-x;
}

.dx-scrollbar-horizontal .dx-scrollbar-hoverable .dx-state-hover .dx-scrollable-scroll {
  height: 17px;
}

.dx-scrollbar-vertical .dx-scrollbar-hoverable .dx-state-hover .dx-scrollable-scroll {
  width: 17px;
}

.dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed {
  transform: none !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell:not(.dx-command-select) {
  padding: 0 !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor {
  border-radius: 0;
}

.dx-datagrid-filter-row .dx-menu {
  top: 2px !important;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input {
  border-radius: 0px !important;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder::before,
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input {
  padding-left: 23px;
}

.dx-datagrid.dx-datagrid-borders > .dx-datagrid-pager {
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  padding: 5px 0;
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table .dx-datagrid-filter-row {
  background-color: var(--color-white);
  height: 26px !important;
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-header-row {
  height: 32px !important;

  td:hover {
    background: #486B7E;
  }
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-header-row > td:not(.report-actions):not(.dx-command-select):not(.dx-command-edit) {
  font-size: 14px;
  font-weight: var(--font-weight-normal);
  text-align: left !important;
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-header-row .dx-command-select {
  background-color: var(--color-primary-300);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  text-align: center !important;
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-content-fixed .dx-datagrid-table.dx-datagrid-table-fixed .dx-row > td.dx-pointer-events-none + td {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.dx-datagrid-borders > .dx-datagrid-headers {
  border-top: none !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row:last-child > td {
  border-bottom-color: var(--color-border-weak);
  border-right: none;
}

.dx-datagrid-headers.dx-datagrid-nowrap {
  padding-right: 0 !important;
}

.dx-datagrid-headers + .dx-datagrid-rowsview {
  border-top: none !important;
}

.dx-datagrid.dx-filter-menu .dx-menu-item-content .dx-icon {
  font-size: 13px;
}

.dx-datagrid-focus-overlay {
  border: none;
}

.dx-datagrid-borders > .dx-datagrid-headers {
  border-left: 0;
  border-radius: 0;
}

.dx-datagrid-headers .dx-texteditor-input {
  min-height: 26px !important;
  padding-block: 0;
}

.dx-datebox-calendar .dx-dropdowneditor-icon::before {
  color: var(--color-grayscale-300);
  margin-top: -8px;
}

.dx-datagrid.dx-filter-menu .dx-menu-item-has-icon .dx-icon {
  color: var(--color-grayscale-300);
}

.dx-datagrid-checkbox-size .dx-checkbox-icon {
  border: 1px solid var(--color-text-weak);
  border-radius: 3px;
}

.dx-datagrid-rowsview .dx-row {
  height: 30px !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td:first-of-type .dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
  background-color: var(--color-system-success);
  border: 1px solid var(--color-system-success);
  color: var(--color-white);
}

.dx-datagrid-content .dx-datagrid-table .dx-data-row .dx-command-edit-with-icons .dx-link-delete,
.dx-datagrid-content .dx-datagrid-table .dx-data-row .dx-command-edit-with-icons .dx-link-edit {
  color: var(--color-text-weaker-medium) !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-data-row .dx-command-edit-with-icons .dx-link-delete:hover,
.dx-datagrid-content .dx-datagrid-table .dx-data-row .dx-command-edit-with-icons .dx-link-edit:hover {
  color: var(--color-text-weak) !important;
  text-decoration: none !important;
}

.dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
  padding: 0 0 0 5px;
}

.dx-datebox-calendar .dx-dropdowneditor-icon {
  font-size: 16px;
}

.dx-pager {
  --dx-pager-field-border-color: var(--color-text-weak);

  padding-top: 6px !important;
  padding-bottom: 8px !important;

  .dx-texteditor.dx-state-active.dx-editor-filled,
  .dx-texteditor.dx-state-active.dx-editor-outlined,
  .dx-texteditor.dx-state-active.dx-editor-underlined,
  .dx-texteditor.dx-state-focused.dx-editor-filled,
  .dx-texteditor.dx-state-focused.dx-editor-outlined,
  .dx-texteditor.dx-state-focused.dx-editor-underlined {
    border-color: var(--dx-pager-field-border-color);
  }

  .dx-texteditor {
    transition: {
      delay: var(--transition-delay);
      duration: var(--transition-duration);
      property: var(--transition-property);
      timing-function: var(--transition-timing-function);
    }

    &:hover {
      border-color: var(--dx-pager-field-border-color);
    }
  }

  .dx-dropdowneditor-icon::before {
    color: #868686;
  }
}

.dx-pager.dx-light-mode .dx-page-sizes {
  align-items: center;
  color: var(--color-text-weak);
  display: flex;
  flex-direction: row-reverse;
  font-size: var(--font-size-xxxxxs);
  gap: var(--space-xs);
  position: relative;
}

.dx-pager .dx-page-sizes .dx-texteditor {
  width: 72px !important;
}

.dx-pager .dx-page-sizes .dx-texteditor-input {
  padding: 0;
  min-height: 22px;
  text-align: center;
}

.dx-pager .dx-pages {
  display: flex;
  align-items: center;
  margin-left: 0;

  .c-bui-button-icon {
    height: 30px !important;
    aspect-ratio: 1/1 !important;
  }
}

.dx-pager .dx-pages .dx-page-indexes {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-xs);
}

.dx-pager .dx-light-pages .dx-info-text,
.dx-pager .dx-light-pages .dx-pages-count {
  display: none;
}

.dx-pager .dx-page-index {
  width: 23px !important;
}

.dx-pager .dx-pages .dx-info {
  margin-inline: 10px;
  color: var(--color-text-weak);
  font-size: var(--font-size-xxxxxs);
  opacity: 1;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background-color: var(--color-background-medium);
    top: -5px;
    right: -10px;
    width: 1px;
    height: 20px;
    display: inline-block;
  }
}

.dx-pager .dx-page-index {
  width: 20px;
  height: 20px;

}

.dx-pager .dx-pages .dx-next-button::before,
.dx-pager .dx-pages .dx-prev-button::before {
  color: var(--color-text-weak);
  font-size: var(--font-size-xxxs) !important;
  height: 22px;
  line-height: 22px;
}

.dx-pager .dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,
.dx-pager .dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
  background-color: transparent;
}

.dx-pager .dx-editor-filled.dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,
.dx-pager .dx-editor-filled.dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
  background-color: transparent;
}

.dx-pager .dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,
.dx-pager .dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon {
  background-color: transparent;
}

.dx-dropdowneditor-icon::before {
  color: var(--color-grayscale-500);
}

.dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
  background-color: var(--color-system-success);
  border: 1px solid var(--color-system-success);
}

.dx-checkbox-checked .dx-checkbox-icon::before {
  color: var(--color-white);
}

.dx-pager input:not(:disabled) {
  color: var(--color-text-weak) !important;
  font-size: var(--font-size-xxxxxs);
  padding-top: 0;
}

.dx-pager .dx-page-index .dx-texteditor-container {
  height: 23px;
}

.dx-pager .dx-page-index .dx-texteditor-container .dx-texteditor-input {
  padding: 0;
  min-height: 0;
}

.dx-pager .dx-page-index .dx-texteditor-container input:not(:disabled) {
  text-align: center;
}

.dx-pager .dx-pages .beyond-table__left-arrow-icon .c-bui-button-icon__content {
  height: 20px;
  width: 20px;
}

.dx-pager .dx-pages #rightArrowBtn .c-bui-button-icon__content {
  height: 20px;
  width: 20px;
}

@media only screen and (max-width: 425px) {
  .dx-datagrid.dx-datagrid-borders > .dx-datagrid-pager {
    flex-direction: column;
  }

  .dx-pager .dx-pages {
    justify-content: flex-end;
  }

  .dx-pager.dx-light-mode .dx-page-sizes::after {
    display: none;
  }

  .dx-dropdowneditor.dx-dropdowneditor-field-clickable {
    margin-right: 0;
  }
}

.dx-editor-cell.dx-focused {
  border-radius: 0;

  input {
    background-color: #fff;
    border: 1px solid var(--color-table-head-row-background-normal);
    border-radius: 0;
  }
}


/*  TABLE COLUMN WIDTH  */


.dx-datagrid-table .dx-data-row td:not(.dx-command-select.dx-col-fixed) {
  min-width: fit-content !important;
  max-width: 750px !important;
}

.dx-datagrid-table .dx-col-fixed {
  --dx-datagrid-table-fixed-cell-background-color: #F2F2F2;
  background-color: var(--dx-datagrid-table-fixed-cell-background-color);
}

.dx-datagrid-table-fixed:not(:has(.dx-header-row)) .dx-col-fixed {
  background-color: #fff;
}

.dx-datagrid-table-fixed:has(.dx-header-row) .dx-col-fixed {
  background-color: var(--dx-datagrid-table-head-background-color);
}

.dx-datagrid-table .dx-freespace-row td {
  display: none !important;
}

.dx-datagrid-table .dx-command-select {
  padding-left: 0 !important;
  padding-right: 0 !important;

}

.dx-datagrid-table {

  --c-reports-table-actions-icon-width: 18px;
  --c-reports-table-actions-icon-height: 22px;

  .report-actions {

    padding-left: 0 !important;
    padding-right: 0 !important;

    .c-bui-button-icon--icon-size-default {
      width: var(--c-reports-table-actions-icon-width) !important;
      height: var(--c-reports-table-actions-icon-height) !important;
    }
  }
}

.dx-datagrid-table .dx-row td.report-actions {
  text-align: center !important;
}

.dx-datagrid-headers,
.dx-datagrid-content {
  padding-right: 0 !important;
}

.dx-datagrid-rowsview .dx-selection.dx-row > td:has(.dx-checkbox) {
  --dx-datagrid-table-celected-cell-border-color: var(--color-white);

  border-bottom: 1px solid var(--dx-datagrid-table-celected-cell-border-color);
  border-top: 1px solid var(--dx-datagrid-table-celected-cell-border-color);
  border-left: 1px solid var(--dx-datagrid-table-celected-cell-border-color);
  border-right: 1px solid var(--dx-datagrid-table-celected-cell-border-color);
}

.dx-datagrid-table .dx-data-row td:not(.dx-command-select.dx-col-fixed) {
  min-width: fit-content !important;
}

.dx-datagrid .dx-column-indicators {
  float: right !important;

  .dx-header-filter {
    color: var(--color-white);
    font-size: 10px;
  }
}

.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-both > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content {
  padding-bottom: 0;
}
