@use "settings/variables/layout";

.material-light-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar,
.material-dark-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(var(--color-primary-500-rgb), 0.54) !important;
}

.material-light-theme .mat-slide-toggle.mat-primary:not(.mat-checked),
.material-dark-theme .mat-slide-toggle.mat-primary:not(.mat-checked) {
  .mat-slide-toggle-bar {
    background-color: rgba(0, 0, 0, 0.18);
  }

  .mat-slide-toggle-thumb {
    background-color: var(--color-background-strong);
  }
}

@media (min-width: 381px) and (layout.$mq-below-narrow) {
  .mat-slide-toggle-content {
    font-size: 14px;
  }
}

@media (max-width: 380px) {
  .mat-slide-toggle-content {
    font-size: 12px;
    max-width: 200px;
  }
}
