html {
  --base-typography-font-family: var(--font-family-base);
  --base-typography-font-size: var(--font-size-base);
  --base-typography-font-weight-bold: var(--font-weight-bold);
  --base-typography-font-weight-normal: var(--font-weight-normal);
  --base-typography-h1-font-size: var(--font-size-xxxl);
  --base-typography-h2-font-size: var(--font-size-xxl);
  --base-typography-h3-font-size: var(--font-size-xl);
  --base-typography-h4-font-size: var(--font-size-base);
  --base-typography-line-height: var(--line-height-base);
  font-variant-ligatures: none !important;
  font: {
    family: var(--base-typography-font-family);
    size: var(--base-typography-font-size);
    weight: var(--base-typography-font-weight-normal);
  }
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: var(--base-typography-line-height);
  text-rendering: optimizeLegibility;
}

html.global-font-family-poppins {
  --font-family-base: var(--font-family-poppins);
}
html.global-font-family-roboto {
  --font-family-base: var(--font-family-roboto);
}
html.global-font-weight-500 {
  --font-weight-bold: 500;
}
html.global-font-weight-700 {
  --font-weight-bold: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--base-typography-font-weight-bold);
}

h1 {
  font-size: var(--base-typography-h1-font-size);
}

h2 {
  font-size: var(--base-typography-h2-font-size);
}

h3 {
  font-size: var(--base-typography-h3-font-size);
}

h4 {
  font-size: var(--base-typography-h4-font-size);
}

a {
  text-decoration: var(--base-typography-link-text-decoration);

  &:not(:hover) {
    --base-typography-link-text-decoration: var(--text-decoration-none);
  }

  &:hover {
    --base-typography-link-text-decoration: var(--text-decoration-underline);
  }
}

address {
  font-style: normal;
}

abbr[title] {
  text-decoration: none;
}

[data-text-prefix] {
  display: flex;
}

[data-text-prefix]::before {
  content: attr(data-text-prefix);
  margin-right: 0.25em;
}
